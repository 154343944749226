import { Global } from "@emotion/react"
import SatoshiBold from "assets/fonts/satoshi-web/Satoshi-Bold.woff"
import SatoshiMedium from "assets/fonts/satoshi-web/Satoshi-Medium.woff"
import SatoshiRegular from "assets/fonts/satoshi-web/Satoshi-Regular.woff"
import SatoshiVariable from "assets/fonts/satoshi-web/Satoshi-Variable.woff"
import SatoshiVariable2 from "assets/fonts/satoshi-web/Satoshi-Variable.woff2"

export const Fonts = () => (
  <Global
    styles={`
		@font-face {
			font-family: 'Satoshi Heading';
			font-style: normal;
			font-weight: 600;
			font-display: swap;
			src: url(${SatoshiBold}) format('woff');
		}
		@font-face {
			font-family: 'Satoshi Medium';
			font-style: normal;
			font-weight: 500;
			font-display: swap;
			src: url(${SatoshiMedium}) format('woff');
		}
		@font-face {
			font-family: 'Satoshi Body';
			font-style: normal;
			font-weight: 400;
			font-display: swap;
			src: url(${SatoshiRegular}) format('woff');
		}
		@font-face {
			font-family: 'Satoshi Variable';
			src: url(${SatoshiVariable2}) format('woff2'),
					 url(${SatoshiVariable}) format('woff');
			font-weight: 300 900;
			font-display: swap;
			font-style: normal;
		}  
		`}
  />
)
