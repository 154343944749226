import { Accordion } from "@chakra-ui/react"
import { HelpAccordionItem } from "./HelpAccordionItem"

export const HelpAccordionItems = () => {
  return (
    <Accordion allowMultiple>
      <HelpAccordionItem
        helpAccordionQuestion="faqs.conductRating.question"
        helpAccordionAnswer="faqs.conductRating.answer"
      />
      <HelpAccordionItem
        helpAccordionQuestion="faqs.ratingStructure.question"
        helpAccordionAnswer="faqs.ratingStructure.answer"
      />
      <HelpAccordionItem
        helpAccordionQuestion="faqs.ratingPeriod.question"
        helpAccordionAnswer="faqs.ratingPeriod.answer"
      />
      <HelpAccordionItem
        helpAccordionQuestion="faqs.ratingProfile.question"
        helpAccordionAnswer="faqs.ratingProfile.answer"
      />
      <HelpAccordionItem
        helpAccordionQuestion="faqs.datapointControl.question"
        helpAccordionAnswer="faqs.datapointControl.answer"
      />
      <HelpAccordionItem
        helpAccordionQuestion="faqs.datapointCorrection.question"
        helpAccordionAnswer="faqs.datapointCorrection.answer"
      />
      <HelpAccordionItem
        helpAccordionQuestion="faqs.nullNoValue.question"
        helpAccordionAnswer="faqs.nullNoValue.answer"
      />
      <HelpAccordionItem
        helpAccordionQuestion="faqs.criticalErrorSources.question"
        helpAccordionAnswer="faqs.criticalErrorSources.answer"
      />
      <HelpAccordionItem
        helpAccordionQuestion="faqs.otherErrorSources.question"
        helpAccordionAnswer="faqs.otherErrorSources.answer"
      />
      <HelpAccordionItem
        helpAccordionQuestion="faqs.indicatorResult.question"
        helpAccordionAnswer="faqs.indicatorResult.answer"
      />
      <HelpAccordionItem
        helpAccordionQuestion="faqs.companyBeingRated.question"
        helpAccordionAnswer="faqs.companyBeingRated.answer"
      />
    </Accordion>
  )
}
