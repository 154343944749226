import { Button, Select, useToast } from "@chakra-ui/react"
import {
  useActivateRatingProfileRevisionMutation,
  useCreateRatingProfileRevisionMutation,
  useDeleteRatingProfileRevisionMutation,
  useRatingProfileQuery,
} from "lib/generated/graphql"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useRevisionEditing } from "../RatingEditingContext"

export const Revisions: React.FC = () => {
  const { ratingProfileId, "*": subpath } = useParams()

  const [query, refetchRatingProfile] = useRatingProfileQuery({
    variables: { id: Number(ratingProfileId) },
    requestPolicy: "network-only",
  })

  const { isSaving, refetch } = useRevisionEditing()

  const isSavingRef = useRef(isSaving)

  const navigate = useNavigate()

  const [createRevisionResult, createRevision] = useCreateRatingProfileRevisionMutation()
  const [, activateRevision] = useActivateRatingProfileRevisionMutation()
  const [deleteRevisionResult, deleteRevision] = useDeleteRatingProfileRevisionMutation()

  const toast = useToast()

  const [revision, setRevision] = useState<string | undefined>()
  const [isActivating, setIsActivating] = useState(false)

  useEffect(() => {
    isSavingRef.current = isSaving
  }, [isSaving])

  useEffect(() => {
    if (!revision) {
      if (query.fetching === false && !query.error) {
        setRevision(query.data?.ratingProfile?.revisions[0].revision)
      }
    }
  }, [revision, query])

  useEffect(() => {
    if (revision) {
      const url = subpath?.length ? `${revision}/${subpath?.split("/").slice(1).join("/")}` : revision
      navigate(url, { replace: true })
    }
  }, [revision, navigate, subpath])

  if (!ratingProfileId) return null

  const handleCreateRevision = async () => {
    const result = await createRevision({ ratingProfileId: Number(ratingProfileId) })
    setRevision(result.data?.createRatingProfileRevision.revision)

    if (result.error) {
      toast({
        title: "Feil ved opprettelse av revisjon",
        description: result.error.toString(),
        status: "error",
        position: "top",
      })
    } else if (result.data) {
      toast({
        title: "Revisjon opprettet",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      })
    }
  }
  const handleActivateRevision = async () => {
    if (isActivating) return

    setIsActivating(true)

    // If we are saving, wait for it to finish before activating
    if (isSaving) {
      const proceed = await new Promise((resolve) => {
        const timeout = setTimeout(() => {
          clearInterval(interval)
          resolve(false)
        }, 5000)

        const interval = setInterval(() => {
          if (!isSavingRef.current) {
            clearInterval(interval)
            clearTimeout(timeout)
            resolve(true)
          }
        }, 100)
      })

      if (!proceed) {
        toast({
          title: "Noe gikk galt, vennligst prøv igjen",
          status: "warning",
          duration: 2000,
          isClosable: true,
          position: "top",
        })
        setIsActivating(false)
        return
      }
    }

    await activateRevision({ ratingProfileId: Number(ratingProfileId), revision })
    refetchRatingProfile()
    toast({
      title: "Revisjon aktivert",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    })
    refetch()
    setIsActivating(false)
  }

  const handleDeleteRevision = async () => {
    const previousRevision = revisions[1].revision // [1] because they are in reverse chronological order

    const deletion = await deleteRevision({ ratingProfileId: Number(ratingProfileId), revision })

    if (deletion.error) {
      toast({
        title: "Kunne ikke slette revisjon",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      })
    } else {
      refetchRatingProfile()
      setRevision(previousRevision)

      toast({
        title: "Revisjon slettet",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      })
    }
  }

  if (!revision) return null
  if (!query.data?.ratingProfile) return null

  const {
    ratingProfile: { revisions, activeRevisionId },
  } = query.data

  return (
    <>
      <div style={{ display: "flex", flexFlow: "row nowrap", gap: "16px", alignItems: "center" }}>
        <span style={{ fontWeight: 500 }}>Revisjoner</span>
        <Select size="md" width="auto" bg="white" value={revision} onChange={(e) => setRevision(e.target.value)}>
          {revisions.map((rev) => (
            <option value={rev.revision} key={rev.revision}>
              {new Date(rev.revision).toLocaleString()}
              {rev.revision === activeRevisionId && "  -  AKTIV"}
              {activeRevisionId && activeRevisionId > rev.revision && "  -  UTGÅTT"}
              {activeRevisionId && rev.revision > activeRevisionId && "  -  KLADD"}
            </option>
          ))}
        </Select>
        <Button
          variant="critical"
          isDisabled={activeRevisionId >= revision || revisions.length === 1 || isSaving}
          isLoading={deleteRevisionResult.fetching}
          onClick={handleDeleteRevision}
        >
          Slett
        </Button>
        <Button
          colorScheme={"green"}
          isDisabled={activeRevisionId >= revision}
          isLoading={isActivating}
          onClick={handleActivateRevision}
        >
          Aktiver
        </Button>
        <Button
          variant="outline"
          isDisabled={revisions.filter(({ revision: rev }) => rev > (activeRevisionId || "")).length >= 1}
          isLoading={createRevisionResult.fetching}
          onClick={handleCreateRevision}
        >
          Ny
        </Button>
      </div>
    </>
  )
}
