import type { BRREGUnit, BrregListResult } from "@esgt/types"
import axios from "axios"

// DOCUMENTATION:
// https://data.brreg.no/enhetsregisteret/api/docs/index.html

export async function brregSearch(q: string): Promise<BRREGUnit[]> {
  // We could maybe just include norwegian-registered companies, not sure if that is correct
  // For now, the invalid companies are filtered out after fetch
  // `https://data.brreg.no/enhetsregisteret/api/enheter?konkurs=false&forretningsadresse.landkode=no&navn=${q}`

  const params = new URLSearchParams()
  const digitsStr = q.replace(/[^0-9]/gi, "")
  if (digitsStr.length === 9) {
    params.set("organisasjonsnummer", digitsStr)
  } else {
    params.set("navn", q)
  }
  const result = await axios.get(
    `https://data.brreg.no/enhetsregisteret/api/enheter?konkurs=false&${params.toString()}`,
  )
  const data = result.data as BrregListResult
  return data?._embedded?.enheter ? data._embedded.enheter.filter((e) => !!e.naeringskode1) : []
}

export async function lookupBrregOrg(orgNr: string): Promise<BRREGUnit> {
  const response = await axios.get(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgNr}`)

  const { _links, ...data } = response.data

  return data
}
