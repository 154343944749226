import { defineStyleConfig } from "@chakra-ui/react"

export const Link = defineStyleConfig({
  variants: {
    underlined: {
      textDecoration: "underline",
    },
    blueUnderlined: {
      color: "blue.500",
      textDecoration: "underline",
    },
    blue: {
      color: "blue.500",
    },
  },
})
