import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import { Button } from "@chakra-ui/react"
import { IconModal } from "components/Icons"
import React from "react"
import { HelpAccordionItems } from "./HelpAccordionItems"

export const HelpDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  return (
    <>
      <Button leftIcon={<IconModal />} ref={btnRef} variant="ghost" px="0" fontWeight="400" onClick={onOpen}>
        Trenger du veiledning?
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton top={4} />
          <DrawerHeader bg="gray.50" fontSize="2xl">
            Veiledning
          </DrawerHeader>

          <DrawerBody p="0">
            <HelpAccordionItems />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
