import { Navigate } from "react-router-dom"
import { useRevisionEditing } from "../RatingEditingContext"

export const RevisionRedirector = () => {
  const { method } = useRevisionEditing()

  if (!method) {
    return null
  }

  return <Navigate to={`dimension/${method.config.kpiDimensions[0].slug}`} replace={true} />
}
