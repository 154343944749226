import { Button, useDisclosure } from "@chakra-ui/react"
import { Can } from "components/Can"
import { UpgradeRatingToFullModal } from "components/UpgradeRatingToFullModal"
import { useRating } from "lib/providers/RatingProvider"

export const UpgradeRatingToFullButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { ratingState, ratingId, isFree } = useRating()

  return (
    <>
      {ratingState && isFree && (
        <Can permission="rating:update" entityId={ratingId}>
          <Button onClick={onOpen} colorScheme="blue" width="max-content" fontWeight={500}>
            Oppgrader til full versjon
          </Button>
        </Can>
      )}
      <UpgradeRatingToFullModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}
