import { SearchIcon } from "@chakra-ui/icons"
import { Heading, Input, InputGroup, InputLeftElement, Text } from "@chakra-ui/react"
import { brregSearch } from "@esgt/brreg"
import Downshift from "downshift"
import { useEffect, useState } from "react"
import styled from "styled-components"

const itemToString = (something: any) => something?.navn

interface CompanyPickerProps {
  onSelect: (brregUnitInfo: any) => any
}

export const CompanyPicker: React.FC<CompanyPickerProps> = ({ onSelect }) => {
  const [searchString, setSearchString] = useState("")

  const [searchResults, setSearchResults] = useState<Array<Record<string, any>>>([])

  useEffect(() => {
    let unmounted = false

    if (searchString.length > 1) {
      const performSearch = async () => {
        const result = await brregSearch(searchString)
        if (!unmounted) {
          setSearchResults(result)
        }
      }
      performSearch()
    } else {
      setSearchResults([])
    }

    return () => {
      unmounted = true
    }
  }, [searchString])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Downshift
        onChange={(selection) => {
          setSearchResults([])
          if (selection) {
            onSelect?.(selection)
          } else {
          }
        }}
        itemToString={itemToString}
        inputValue={searchString}
        onInputValueChange={(s) => {
          setSearchString(s || "")
          if (!s) setSearchResults([])
        }}
      >
        {({ getInputProps, getMenuProps, getItemProps, highlightedIndex }) => (
          <div>
            <Heading as="h2" size="sm" mb="3">
              Søk etter virksomhet
            </Heading>
            <div style={{ position: "relative" }}>
              <InputGroup background="white">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="search"
                  placeholder="Søk etter virksomhetsnavn eller org. nr."
                  spellCheck={false}
                  autoFocus={true}
                  {...getInputProps()}
                />
              </InputGroup>
            </div>
            <div style={{ position: "relative" }}>
              {searchResults.length ? (
                <SearchResults {...getMenuProps({})}>
                  {searchResults.map((result: any, i: any) => (
                    <SearchResultItem
                      key={i}
                      searchResult={result}
                      isActive={highlightedIndex === i}
                      {...getItemProps({
                        item: result,
                        index: i,
                      })}
                    />
                  ))}
                </SearchResults>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    </div>
  )
}
const SearchResults = styled.div`
	display: flex;
	position: absolute;
	background-color: rgba(240, 240, 240, 0.98);
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	flex-flow: column nowrap;
	max-height: 350px;
	overflow: scroll;
`
const SearchResultItem = styled(
  ({
    searchResult,
    className,
    isActive: _,
    ...rest
  }: {
    searchResult: Record<string, any>
    isActive: boolean
    className?: string
  }) => {
    return (
      <div className={className} {...rest}>
        <Text fontSize="sm">{searchResult.navn}</Text>
        <Text fontSize="xs">{searchResult.organisasjonsnummer}</Text>
      </div>
    )
  },
)`
	${(p) => (p.isActive ? "background-color: rgba(144, 205, 244, 0.3);" : "")}
	color: ${(p) => (p.isActive ? "black" : "#333")};
	font-weight: ${(p) => (p.isActive ? "500" : "normal")};
	cursor: pointer;
	padding: 15px 20px;
`
