import { Box, Button, Flex, Heading, Image, Link, Text } from "@chakra-ui/react"
import frontpageImgSmall from "assets/report-frontpage-small.jpg"
import { useHelpPopover } from "components/HelpPopover"
import { Logo } from "components/Logo"
import { useReportDownload } from "lib/hooks/useReportDownload/useReportDownload"
import { RatingAccount } from "lib/providers"
import { useRatingAccount } from "lib/providers/RatingAccount"
import { useRating } from "lib/providers/RatingProvider"
import { FreeVersionWatermark } from "pages/Report/components"
import { MdDownload } from "react-icons/md"

export const Results: React.FC = () => {
  const { ratingId, ratingState, isFree } = useRating()
  const downloadReport = useReportDownload(ratingId)
  const { setHelpPopoverState } = useHelpPopover()

  return (
    ratingState.phase === "COMPLETED" && (
      <RatingAccount.Provider ratingId={ratingId}>
        <Box maxW="1200px" m="auto" mt="16">
          <Flex gap="8">
            <Box flex="0 0 450px" border="1px solid" borderColor="neutral.100">
              <FrontPageSmall />
            </Box>
            <Box>
              <Heading as="h1" size="lg" mb="4">
                Din bærekraftsrapport
              </Heading>
              <Text mb="4">
                Målingen uttrykker deres virksomhets bærekraft på et forhåndsdefinert indikatorsett, hvor resultatene
                gis en referanse mot måltall der det eksisterer. Resultat presenteres oppsummert i en sol og per
                indikator.
              </Text>
              {isFree && (
                <Text>
                  Ønsker du en fullverdig rapport med kontroll utført av en objektiv tredjepart?{" "}
                  <Link onClick={() => setHelpPopoverState("upgradeForm")} textDecoration="underline">
                    Bestill kontroll her
                  </Link>
                </Text>
              )}
              <Flex mt="8" gap="4">
                {isFree && (
                  <Button onClick={() => setHelpPopoverState("upgradeForm")} colorScheme="blue">
                    Be om tredjepartskontroll
                  </Button>
                )}
                <Button
                  leftIcon={<MdDownload style={{ marginTop: "2px" }} />}
                  onClick={() => downloadReport()}
                  variant="outline"
                >
                  Last ned rapport
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </RatingAccount.Provider>
    )
  )
}

export const FrontPageSmall: React.FC = () => {
  const { ratingState, isFree } = useRating()
  const { accountTitle } = useRatingAccount()
  const brreg = ratingState?.brreg

  return (
    <Box bg="white" p="16px 32px">
      {isFree && (
        <Heading mb="4px" fontWeight={400} fontSize={"70%"} textAlign={"center"}>
          GRATIS -{" "}
          <Text as="span" fontWeight={700} fontSize="inherit">
            UFULLSTENDIG, IKKE VERIFISERT
          </Text>
        </Heading>
      )}
      <Heading fontWeight={500} mb="12px" fontSize={"150%"} textAlign={"center"}>
        BÆREKRAFTRAPPORT
      </Heading>
      <Box textAlign={"center"} fontSize="60%" mb="16px" fontWeight="400">
        PRODUSERT AV {accountTitle} - {new Date(ratingState.completedAt).getFullYear()}
      </Box>
      <Box position={"relative"}>
        <FreeVersionWatermark size="180%" />
        <Image alt="" width="384px" height="384px" src={frontpageImgSmall} />
        <Box position="absolute" top="0" left="6" w="30%">
          <Logo />
        </Box>
      </Box>
      <Box mt="12px" mb="2px" textAlign={"center"} fontSize="90%" fontWeight="300">
        {brreg?.navn}
      </Box>
      <Box textAlign={"center"} fontSize="60%" mb="4px" fontWeight="300">
        {brreg?.organisasjonsnummer.substring(0, 3)} {brreg?.organisasjonsnummer.substring(3, 6)}{" "}
        {brreg?.organisasjonsnummer.substring(6, 9)}
      </Box>
      <Box textAlign={"center"} fontSize="60%" fontWeight="300">
        {ratingState?.period?.toPrettyString()}
      </Box>
    </Box>
  )
}
