import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Flex, Link, Text } from "@chakra-ui/react"
import { ProcessingStatus, RatingFileCategory, UploadScopeEnum } from "@esgt/types"
import ContentAccordion from "components/ContentAccordion"
import { ContentAccordionLeft } from "components/ContentAccordionLeft"
import { ContentAccordionRight } from "components/ContentAccordionRight"
import { FoundationHeader } from "components/FoundationHeader"
import FoundationMainContent from "components/FoundationMainContent"
import { RatingUploader } from "components/RatingUploader"
import { useRating } from "lib/providers/RatingProvider"

export const SAFT = () => {
  const { ratingState, submissionProgress } = useRating()

  const saftFile = ratingState?.uploads.find((upload) => upload.ratingFileType === "saft")
  const processedSaftFile = ratingState?.uploads.find((upload) => upload.ratingFileType === "saft" && upload.processed)
  const readOnly =
    ratingState?.phase !== "SUBMISSION" ||
    (saftFile && !processedSaftFile) ||
    ratingState?.dataFilesProcessingStatus?.saft?.status === ProcessingStatus.Processing ||
    (ratingState.reopenedScopes && !ratingState?.reopenedScopes?.includes("finances"))

  return (
    <FoundationMainContent>
      <FoundationHeader
        title="SAF-T"
        helpText="SAF-T er standardformat for utveksling av regnskapsdata. ESG Trackr benytter dataen til å vurdere økonomiske forhold og governance."
      />
      <ContentAccordion
        headingText={`Last opp SAF-T xml-fil, med regnskapstall for perioden ${ratingState?.period?.toPrettyString()}`}
        completed={submissionProgress?.regnskap?.saft}
        readOnly={readOnly}
      >
        <ContentAccordionLeft>
          <Text mb="4">
            Hvis virksomheten skal måles for et helt år, skal SAF-T-filen som lastes opp inneholde sluttposteringene
            etter årsoppgjøret.
          </Text>
          <RatingUploader
            uploadScope={UploadScopeEnum.SAFT}
            ratingFileType={RatingFileCategory.saft}
            accept={{
              "application/xml": [],
              "text/xml": [],
            }}
            readOnly={readOnly}
            maxFiles={1}
          />
        </ContentAccordionLeft>
        <ContentAccordionRight>
          <Text>
            Er du usikker på hvordan du skal finne frem til og få lastet opp regnskapet i SAF-T? Finn ditt
            regnskapsprogram nedenfor og følg instruksjonene, eller ta kontakt med din regnskapsfører.
          </Text>
          <Flex gap="64px" mt="4">
            <Flex flexFlow="column nowrap" gap="8px" color="blue.500">
              <Link
                href="https://hjelp.tripletex.no/hc/no/articles/4406369731729-Regnskapseksport-i-SAF-T-format"
                isExternal
              >
                Tripletex instruksjoner <ExternalLinkIcon mb="4px" mx="2px" />
              </Link>
              <Link href="https://support.poweroffice.com/hc/no/articles/210160253-Utfør-eksport" isExternal>
                PowerOffice GO instruksjoner <ExternalLinkIcon mb="4px" mx="2px" />
              </Link>
              <Link
                href="https://help.visma.net/no_no/eaccounting/content/online-help/settings-import-export-saf-t.htm"
                isExternal
              >
                Visma.net instruksjoner <ExternalLinkIcon mb="4px" mx="2px" />
              </Link>
              <Link
                href="https://support.unimicro.no/kundestotte/regnskap/andre-tema/eksport-av-regnskapsdata/saf-t-eksport-i-uni-okonomi-v3"
                isExternal
              >
                Unimicro - Uni Økonomi V3 instruksjoner <ExternalLinkIcon mb="4px" mx="2px" />
              </Link>
            </Flex>
          </Flex>
        </ContentAccordionRight>
      </ContentAccordion>
    </FoundationMainContent>
  )
}
