import { extendTheme } from "@chakra-ui/react"
import { Heading } from "./Heading"
import { Link } from "./Link"
import { Table } from "./Table"
import { Text } from "./Text"
import { colors } from "./colors"

export const theme = extendTheme({
  colors,
  fonts: {
    heading: "Satoshi Heading, sans-serif",
    body: "Satoshi Variable, sans-serif",
  },
  styles: {
    global: {
      body: {
        bg: "neutral.50",
        color: "gray.900",
      },
    },
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.75rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  components: {
    Button: {
      baseStyle: ({ colorScheme }) => ({
        fontWeight: 500,
        borderRadius: "base",
        _disabled: {
          opacity: colorScheme === "green" ? 1 : 0.4,
        },
      }),
      sizes: {
        sm: {
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "md",
          lineHeight: "1.5rem",
          padding: "8px 16px",
          backgroundColor: "white",
        },
        lg: {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "1rem",
          lineHeight: "1.5rem",
          padding: "22px 32px",
          backgroundColor: "white",
        },
      },
      variants: {
        ghost: {
          minW: "32px",
          minWidth: "32px",
          _hover: { background: "none", color: "gray.500" },
          _active: { background: "none", color: "gray.500" },
        },
        outline: {
          borderColor: "neutral.700",
          _hover: {
            background: "neutral.100",
            textDecoration: "none",
          },
          _disabled: {
            _hover: {
              color: "inherit",
            },
          },
        },
        critical: {
          color: "critical.500",
          borderColor: "critical.500",
          borderWidth: "1px",
          _hover: {
            background: "critical.100",
          },
        },
        link: {
          textDecoration: "underline",
        },
        solid: ({ colorScheme }) => {
          if (colorScheme === "green") {
            const disabled = {
              bg: "green.200",
              color: "green.600",
            }

            return {
              _hover: {
                _disabled: disabled,
              },
              _disabled: disabled,
            }
          }
          return undefined
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderRadius: "base",
          },
        },
      },
    },
    Text,
    Link,
    Heading,
    Table,
  },
})
