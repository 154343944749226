import { Heading } from "@chakra-ui/react"
import { MethodI18nFragment } from "components/I18nText"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { useRevisionEditing } from "../RatingEditingContext"
import { ConfigLine } from "./ConfigLine"

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
	gap: 36px;
`

export const Dimension: React.FC = () => {
  const { dimensionId } = useParams()
  const { ratingProfile, method } = useRevisionEditing()

  if (!ratingProfile || !method) return null

  const dimension = method.config.kpiDimensions.find((dim) => dim.slug === dimensionId)

  return (
    <Container>
      <Heading size="md" as="h2">
        <MethodI18nFragment text={dimension.name} />
      </Heading>

      <ConfigLine
        itemType="dimensions"
        itemId={dimension.id}
        title={
          <Heading size="sm" as="h3">
            Dimensjon
          </Heading>
        }
      />
    </Container>
  )
}
