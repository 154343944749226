export enum PendingActionBy {
  NONE = 1,
  CUSTOMER = 2,
  R8ME = 3,
}

export enum UserRatingRole {
  /** Eks: Sum CEO, MBH CEO */
  Owner = "OWNER",

  /** Eks: Regnskapsfører, Rating manager, ... */
  Rater = "RATER",

  /** Eks: SMB CEO, Byggherre (eier), ... */
  ContactPerson = "CONTACT_PERSON",

  /** Eks: SMB CFO (bidragsyter) ... */
  Contributor = "CONTRIBUTOR",
}

export enum BusinessArea {
  SMBS = "smbs",
  BUILDINGS = "buildings",
  CITIES = "cities",
}

export function isBusinessArea(probablyBusinessArea: string): probablyBusinessArea is BusinessArea {
  return Object.values(BusinessArea).includes(probablyBusinessArea as BusinessArea)
}

export const BusinessAreaNames = {
  [BusinessArea.SMBS]: "SMBer",
  [BusinessArea.BUILDINGS]: "Bygninger",
  [BusinessArea.CITIES]: "Byer",
}

export type MethodVersionId = "v3" | "v4"

export enum UserRole {
  Admin = "ADMIN",
  AccountOwner = "ACCOUNT_OWNER",
  AccountAdmin = "ACCOUNT_ADMIN",
  AccountMember = "ACCOUNT_MEMBER",
  ContactPerson = "CONTACT_PERSON",
}

export enum UserAccountRole {
  Admin = "ADMIN",
  AccountOwner = "ACCOUNT_OWNER",
  AccountAdmin = "ACCOUNT_ADMIN",
  AccountMember = "ACCOUNT_MEMBER",
}
