import { Box } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"

interface LinkItem {
  label: string
  to: string
}

interface SideNavigationProps {
  links: LinkItem[]
}

export const SideNavigation: React.FC<SideNavigationProps> = ({ links }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {links.map((link) => (
        <NavLink key={link.to} to={link.to}>
          {({ isActive }) => (
            <Box
              bg={isActive && "gray.50"}
              _hover={{ bg: "gray.50" }}
              pl={8}
              pr={4}
              lineHeight="20px"
              py={3}
              borderRight="3px solid transparent"
              borderColor={isActive && "gray.700"}
            >
              {link.label}
            </Box>
          )}
        </NavLink>
      ))}
    </Box>
  )
}
