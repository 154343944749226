import { ContentBox } from "components/ContentBox"
import { SiteTitle } from "components/SiteTitle"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { useRating } from "lib/providers/RatingProvider"
import { Navigate, Route, Routes } from "react-router-dom"
import styled from "styled-components"
import { CategoryKPIs } from "./CategoryKPIs"
import { Navigation } from "./Navigation"

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-flow: row nowrap;
	margin: 0 0 36px 0;
	gap: 36px;
	overflow: hidden;
`

export const KPI = () => {
  const { ratingId, ratingProfile, ratingProfileConfig, ratingState, method } = useRating()
  useBreadcrumb("Indikatorer", `/ratings/${ratingId}/indicators`)

  if (!ratingProfile || !method) return null

  const firstNonDisabledDimension = method.config.kpiDimensions.find(
    (dimension) => ratingProfileConfig?.dimensions?.[dimension.slug]?.enabled !== false,
  )

  if (!firstNonDisabledDimension) return null

  const firstNonDisabledCategory = firstNonDisabledDimension.categories.find(
    (cat) => ratingProfileConfig?.categories?.[cat.id]?.enabled !== false,
  )

  if (!firstNonDisabledCategory) return null

  return (
    <Container>
      <SiteTitle title={`Indikatorer - ${ratingState.brreg.navn}`} />
      <Navigation />
      <ContentBox w="100%">
        <Routes>
          <Route path="category/:dimensionId/:categoryId/*" element={<CategoryKPIs />} />
          <Route
            index
            element={
              <Navigate
                to={`category/${firstNonDisabledDimension.slug}/${firstNonDisabledCategory.id}`}
                replace={true}
              />
            }
          />
        </Routes>
      </ContentBox>
    </Container>
  )
}
