import { defineStyleConfig } from "@chakra-ui/react"

const sharedStylesThTd = {
  padding: "1rem 0.5rem",
  fontFamily: "Satoshi Variable, sans-serif",
  textTransform: "none",
  fontSize: "1rem",
  "&:first-of-type": {
    borderRadius: "4px 0 0 0",
    paddingLeft: "16px",
  },
  "&:last-of-type": {
    borderRadius: "0 4px 0 0",
    paddingRight: "16px",
  },
}

export const Table = defineStyleConfig({
  variants: {
    roundedTop: {
      table: {},
      thead: {
        background: "gray.700",
        color: "white",
        tr: {
          borderBottom: 0,
        },
      },

      tbody: {
        background: "white",
      },
      tr: {
        borderBottom: "1px solid",
        borderColor: "neutral.100",
      },
      th: {
        ...sharedStylesThTd,
        fontWeight: 500,
      },
      td: { ...sharedStylesThTd },
    },
    standard: {
      tr: {
        borderBottom: "1px solid",
        borderColor: "neutral.100",
      },
      th: {
        fontSize: "sm",
        paddingX: "2px",
        textTransform: "none",
      },
      td: {
        paddingX: "2px",
      },
    },
    striped: {
      th: {
        fontSize: "sm",
        textTransform: "none",
      },
    },
  },
  defaultProps: {
    variant: "standard",
  },
})
