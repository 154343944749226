import { format } from "date-fns"
import { nb } from "date-fns/locale"

type Options = Parameters<typeof format>[2]

export const formatDate = (date: number | Date, formatStr: string, options?: Options) => {
  return format(date, formatStr, {
    locale: nb,
    ...options,
  })
}
