import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Box, Flex, Icon, Link, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { missingPeriodsInA07Uploads } from "@esgt/helpers"
import { ProcessingStatus, RatingFileCategory, RatingType, UploadScopeEnum } from "@esgt/types"
import ContentAccordion from "components/ContentAccordion"
import { ContentAccordionLeft } from "components/ContentAccordionLeft"
import { ContentAccordionRight } from "components/ContentAccordionRight"
import { ErrorBanner } from "components/ErrorBanner"
import { FoundationHeader } from "components/FoundationHeader"
import FoundationMainContent from "components/FoundationMainContent"
import { RatingUploader } from "components/RatingUploader"
import { useUpdateRatingAnswerMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { useMemo, useState } from "react"
import { MdCheckCircle, MdRefresh } from "react-icons/md"

export const A07: React.FC = () => {
  const { ratingId, ratingState, submissionProgress } = useRating()
  const [saveStatus, save] = useUpdateRatingAnswerMutation()
  const scope = UploadScopeEnum.A07
  const defaultValues = ratingState?.answerValues[scope]
  const [primaryAnswerBuffer, setPrimaryAnswerBuffer] = useState<string | undefined>()

  const onChange = (value: string) => {
    setPrimaryAnswerBuffer(value)
    save({ ratingId, scope, values: { primaryAnswer: value } })
  }

  const readOnly =
    ratingState?.phase !== "SUBMISSION" ||
    ratingState?.dataFilesProcessingStatus?.a07?.status === ProcessingStatus.Processing ||
    (ratingState?.reopenedScopes && !ratingState?.reopenedScopes?.includes("altinn"))

  const primaryAnswer = useMemo(() => {
    return primaryAnswerBuffer || defaultValues?.primaryAnswer
  }, [primaryAnswerBuffer, defaultValues])

  const isExpanded = useMemo(() => {
    return primaryAnswer === "true"
  }, [primaryAnswer])

  const { missingPeriodsString, noMissingPeriods } = missingPeriodsInA07Uploads(ratingState) || {}

  const noUploadedPeriods = ratingState?.period?.noPeriods - noMissingPeriods

  return (
    <FoundationMainContent>
      {ratingState.ratingType === RatingType.Free &&
        ratingState?.dataFilesProcessingStatus?.a07?.status === ProcessingStatus.Failed && (
          <ErrorBanner msg="Det skjedde en feil ved prosessering av filene. Vennligst prøv på nytt, kontakt oss dersom det vedvarer" />
        )}
      <FoundationHeader
        title="Avstemmingsinformasjon (A07)"
        helpText="Avstemmingsrapporten A07 oppsummerer opplysningene du har sendt inn i a-meldingen, og inneholder opplysninger om: arbeidsgiveravgift, forskuddstrekk, inntektsopplysninger, samt opplysninger per inntektsmottaker om arbeidsforhold, inntekter etc."
      />
      <ContentAccordion
        headingText="Her skal A07-rapporter for perioden"
        completed={submissionProgress?.altinn?.A07}
        readOnly={readOnly}
      >
        <ContentAccordionLeft>
          <Box as="form">
            <Text mb={2}>Har virksomheten ansatte?</Text>
            <RadioGroup onChange={onChange} isDisabled={saveStatus.fetching || readOnly} value={primaryAnswer}>
              <Stack direction="row" gap="16px">
                <Radio value="true" bg="white">
                  Ja
                </Radio>
                <Radio value="false" bg="white">
                  Nei
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
          {isExpanded && (
            <>
              <Box mt="4">
                {noUploadedPeriods && noMissingPeriods ? (
                  <>
                    <Text fontWeight={500} mb={0} color="blue.500">
                      Vi har mottatt filer for {noUploadedPeriods} av {ratingState?.period?.noPeriods} måneder
                    </Text>
                    <Text mb={6}>Mangler {missingPeriodsString}</Text>
                  </>
                ) : null}
                {noMissingPeriods === 0 &&
                  (ratingState.ratingType !== RatingType.Free ||
                    ratingState?.dataFilesProcessingStatus?.a07?.status === ProcessingStatus.Completed) && (
                    <Flex alignItems="center" gap="8px" mb={6}>
                      <Icon boxSize="20px" as={MdCheckCircle} color="green.500" cursor="pointer" />
                      <Text fontWeight={500} color="green.500">
                        Takk for filene
                      </Text>
                    </Flex>
                  )}

                {ratingState.ratingType === RatingType.Free &&
                  ratingState?.dataFilesProcessingStatus?.a07?.status === ProcessingStatus.Processing && (
                    <Flex alignItems="center" gap="8px" mb={6}>
                      <Icon boxSize="20px" as={MdRefresh} color="blue.500" cursor="pointer" />
                      <Text fontWeight={500} color="blue.500">
                        Filene prosesseres
                      </Text>
                    </Flex>
                  )}
                <RatingUploader
                  uploadScope={scope}
                  ratingFileType={RatingFileCategory.a07}
                  readOnly={readOnly}
                  accept={{
                    "application/xml": [],
                    "text/xml": [],
                  }}
                />
              </Box>
            </>
          )}
        </ContentAccordionLeft>
        <ContentAccordionRight>
          <Text mb="4">
            ESG Trackr benytter avstemmingsrapportene blant annet til å måle likestilling, kjønnsbalanse, medianlønn,
            gjennomsnittlig lønn og laveste lønn.
          </Text>
          {isExpanded && (
            <>
              <Box>
                <Text mb="4">
                  Her skal A07-rapporter for perioden, én A07-rapport for hver måned, lastes opp i XML-format.
                </Text>
                <Text>
                  <strong>Det er viktig at det hukes av for spesifisering per person.</strong>
                </Text>
              </Box>
              <Box>
                <Text my="4">
                  Følg instruksene vi har laget for å laste opp riktige skjemaer med riktig informasjon for
                  målingsperioden. Klikk på linken under for instruksjoner.
                </Text>
                <Link href="https://www.esgtrackr.no/altinn-a06-07" isExternal variant="blue">
                  Instruksjoner for nedlasting av A06/07 skjema <ExternalLinkIcon mb="4px" mx="2px" />
                </Link>
              </Box>
            </>
          )}
        </ContentAccordionRight>
      </ContentAccordion>
    </FoundationMainContent>
  )
}
