import { Box, Heading, Link, Text } from "@chakra-ui/react"
import { IconGenericError, IconNoAccess, IconNotFound, IconSearchLg } from "components/Icons"
import { Link as RouterLink } from "react-router-dom"

type FeedbackType = "search" | "noAccess" | "genericError" | "notFound"

interface FeedbackProps {
  type?: FeedbackType
  heading?: string
  text?: string
  linkBack?: boolean
  reload?: boolean
}

const illustrations: Record<FeedbackType, React.FC> = {
  search: IconSearchLg,
  genericError: IconGenericError,
  noAccess: IconNoAccess,
  notFound: IconNotFound,
}

export const Feedback: React.FC<FeedbackProps> = ({ type, heading, text, linkBack, reload }) => {
  const Illustration = illustrations[type || "search"]

  return (
    <Box width="full" height="full" display="flex" justifyContent="center" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
        <Illustration />
        {heading && (
          <Heading as="h3" color="gray.400" fontStyle="medium" fontSize="2xl" fontWeight="medium" mt={3}>
            {heading}
          </Heading>
        )}
        {text && <Text>{text}</Text>}
        {(linkBack || reload) && (
          <Text fontSize="xl" color="gray.400">
            {linkBack && (
              <Link as={RouterLink} to="/" variant="blue">
                Tilbake til sikkerhet
              </Link>
            )}
            {linkBack && reload && <span> eller </span>}
            {reload && (
              <Link onClick={() => window.location.reload()} variant="blue">
                Last inn siden på nytt
              </Link>
            )}
          </Text>
        )}
      </Box>
    </Box>
  )
}
