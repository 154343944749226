import { Button, useDisclosure } from "@chakra-ui/react"
import { AbortRatingModal } from "components/AbortRatingModal"
import { Can } from "components/Can"
import { isRatingEditable } from "lib/helpers/rating"
import { useRating } from "lib/providers/RatingProvider"

export const CancelRatingButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { ratingState, ratingId } = useRating()

  if (ratingState && isRatingEditable(ratingState)) {
    return (
      <Can permission="rating:update" entityId={ratingId}>
        <Button onClick={onOpen} variant="critical" width="max-content" fontWeight={500}>
          Kansellér måling
        </Button>
        <AbortRatingModal isOpen={isOpen} onOk={onClose} onCancel={onClose} />
      </Can>
    )
  }
}
