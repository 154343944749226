import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Box, Flex, Link, Text } from "@chakra-ui/react"
import { RatingFileCategory, UploadScopeEnum } from "@esgt/types"
import ContentAccordion from "components/ContentAccordion"
import { ContentAccordionLeft } from "components/ContentAccordionLeft"
import { ContentAccordionRight } from "components/ContentAccordionRight"
import { FoundationHeader } from "components/FoundationHeader"
import FoundationMainContent from "components/FoundationMainContent"
import InfoModalLink from "components/ModalLink"
import { RatingUploader } from "components/RatingUploader"
import { useRating } from "lib/providers/RatingProvider"

export const BalanceSheet = () => {
  const { ratingState, submissionProgress } = useRating()
  const readOnly =
    ratingState?.phase !== "SUBMISSION" ||
    (ratingState?.reopenedScopes && !ratingState?.reopenedScopes?.includes("finances"))

  return (
    <FoundationMainContent>
      <FoundationHeader
        title="Saldobalanse"
        helpText="Saldobalansen er en forenklet oversikt over regnskapet som viser deg alle kontoer med aktivitet på – altså alle kontoer hvor det er bokført noe i en gitt periode."
      />
      <ContentAccordion
        headingText={`Last opp saldobalansen i .xls (excel) format for perioden ${ratingState?.period?.toPrettyString()}`}
        completed={submissionProgress?.regnskap?.saldobalanse}
        readOnly={readOnly}
      >
        <ContentAccordionLeft>
          <Text as="i">
            Om virksomheten ikke måles fra 01.01., må virksomheten i tillegg laste opp saldobalanse som viser inngående
            balanse (IB).
          </Text>

          <Box mt="4">
            <RatingUploader
              uploadScope={UploadScopeEnum.Saldobalanse}
              ratingFileType={RatingFileCategory.generic}
              readOnly={readOnly}
              maxFiles={1}
            />
          </Box>
        </ContentAccordionLeft>
        <ContentAccordionRight>
          <Box>
            <Text mb="4">
              ESG Trackr sammenstiller saldobalansen med annen data. Dette brukes for eksempel for å se om virksomheten
              har bokført tilleggsposteringer ved årsoppgjør, eller foretatt disponering av periodens resultat.
            </Text>

            <Text mb="4">
              Er du usikker på hvordan du skal finne frem til, og få lastet opp saldobalansen i .xls (excel) format?
              Finn ditt regnskapsprogram nedenfor og følg instruksjonene, eller ta kontakt med din regnskapsfører.
            </Text>
          </Box>

          <Flex gap="64px">
            <Flex flexFlow="column nowrap" gap="8px" color="blue.500">
              <InfoModalLink
                linkText="Tripletex instruksjoner"
                header="Saldobalanse - Tripletex"
                body="Regnskap > Saldobalanse > Sett riktig periode > Last ned som .xls (menyen til høyere)"
              />
              <InfoModalLink
                linkText="PowerOffice GO instruksjoner"
                header="Saldobalanse - PowerOffice GO"
                body="Meny > Rapporter > Regnskap: Saldobalanse"
              />
              <InfoModalLink
                linkText="Visma.net instruksjoner"
                header="Saldobalanse - Visma.net"
                body="Rapporter > Velg rapporten du vil skrive ut > Sett periode (dersom mulig)"
              />
              <Link href="https://support.unimicro.no/kundestotte/regnskap/rapportering/saldo/sum" isExternal>
                Unimicro - Uni Økonomi V3 instruksjoner <ExternalLinkIcon mb="4px" mx="2px" />
              </Link>
            </Flex>
          </Flex>
        </ContentAccordionRight>
      </ContentAccordion>
    </FoundationMainContent>
  )
}
