import { Flex, Heading, Link, Text } from "@chakra-ui/react"
import HeadingTooltip from "components/HeadingTooltip"
import { useFoundationNav } from "lib/hooks/useFoundationNav"
import { Link as RouterLink } from "react-router-dom"
import { useLocation } from "react-router-dom"

interface FoundationHeaderProps {
  title: string | React.ReactNode
  helpText?: string
}

export const FoundationHeader = ({ title, helpText }: FoundationHeaderProps) => {
  const location = useLocation()
  const navFlat = useFoundationNav().flatMap((section) => section.items)
  const currentIndex = navFlat.findIndex((item) => item.to === location.pathname)

  return (
    <Flex alignItems="end">
      <Flex gap="2" alignItems="center" mt="4" flex="1">
        <Heading as="h1" size="lg">
          {title}
        </Heading>
        {helpText && <HeadingTooltip helpText={helpText} />}
      </Flex>
      <Flex gap={2}>
        <FoundationHeaderLink to={navFlat[currentIndex - 1]?.to} text="Forrige" />
        <Text>|</Text>
        <FoundationHeaderLink to={navFlat[currentIndex + 1]?.to} text="Neste" />
      </Flex>
    </Flex>
  )
}

interface FoundationHeaderLinkProps {
  to?: string
  text: string
}

const FoundationHeaderLink = ({ to, text }: FoundationHeaderLinkProps) =>
  to ? (
    <Link as={RouterLink} to={to} variant="blue" fontWeight={500}>
      {text}
    </Link>
  ) : (
    <Text color="neutral.300">{text}</Text>
  )
