import { doesUserHavePermission } from "@esgt/auth"
import { Feedback } from "components/Feedback"
import { UnauthorizedReason } from "lib/helpers/unauthorizedFeedback"
import { routes } from "lib/routes"
import { createContext, useContext } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { useCurrentUser } from "./CurrentUser"

export interface AuthContextType {
  token: string | null
  signin: (token: string) => Promise<void>
  signout: () => Promise<void>
}

const AuthContext = createContext<AuthContextType>(null)

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()

  const signin = async (token: string) => {
    localStorage.setItem("r8me-token", token)
  }

  const signout = async () => {
    localStorage.removeItem("r8me-token")
    navigate(routes.login())
  }

  const token = localStorage.getItem("r8me-token")

  const value = { signin, signout, token }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}

type RequireAuthProps = {
  admin?: boolean
  children?: React.ReactNode
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ admin = false, children }) => {
  const { fetching, user, account, error } = useCurrentUser()

  if (error) {
    throw new Error(error.message)
  }

  if (fetching && !user) {
    return null
  }

  if (error) {
    const toParams = new URLSearchParams()
    toParams.set("reason", UnauthorizedReason.NOT_LOGGED_IN)

    return <Navigate to={`${routes.login()}?${toParams.toString()}`} replace />
  }

  if (!user) {
    const toParams = new URLSearchParams()
    toParams.set("nextUrl", window.location.pathname + window.location.search)

    return <Navigate to={`${routes.login()}?${toParams.toString()}`} replace />
  }

  if (admin && !doesUserHavePermission(user, "account:rating:create", account?.id)) {
    return <Feedback type="noAccess" heading="Beklager, men du har ikke tilgang til denne siden" linkBack />
  }

  return <>{children}</>
}
