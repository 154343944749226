import { defineStyleConfig } from "@chakra-ui/react"

export const Text = defineStyleConfig({
  baseStyle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem", // md
    lineHeight: "1.5rem", // md
  },
  variants: {
    formula: {
      fontFamily: "Anonymous Pro",
      fontSize: "0.8125rem",
      lineHeight: "1.625rem",
    },
  },
  sizes: {
    xl: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
    },
    lg: {
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
    },
    md: {
      //default
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    sm: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    },
    xs: {
      fontSize: "0.75rem",
      lineHeight: "1rem",
    },
  },
})
