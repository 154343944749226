import { Button, Flex, Icon, Stack } from "@chakra-ui/react"
import { ContentBox } from "components/ContentBox"
import { DownloadButton } from "components/DownloadButton"
import { config } from "lib/helpers/config"
import { useAuth } from "lib/providers/AuthProvider"
import { useRating } from "lib/providers/RatingProvider"
import { MdDownload, MdOutlineNoteAlt } from "react-icons/md"
import { useLocation, useNavigate } from "react-router-dom"
import { SideNavigation } from "./SideNavigation"

interface Props {
  requestAdditionalInfoDisabled: boolean
}

export const Navigation: React.FC<Props> = ({ requestAdditionalInfoDisabled }) => {
  const { ratingId, ratingState } = useRating()
  const { token } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const requestAdditionalInfo = () => {
    navigate(`${location.pathname}/request-additional-info`)
  }

  return (
    <Stack width="400px" gap={8}>
      <ContentBox p={0} py={8}>
        <SideNavigation
          links={[
            {
              label: "Dokumentasjon",
              to: "documentation",
            },
            {
              label: "Avvik og omklassifiseringer",
              to: "anomalies",
            },
            {
              label: "Spørsmål - svar og grunnlag",
              to: "questions",
            },
          ]}
        />
      </ContentBox>
      <Flex flexFlow="column nowrap" gap="16px">
        <DownloadButton
          downloadUrl={`${config.API_URL}/download-rating-uploads?ratingId=${ratingId}&authToken=${token}`}
          downloadName={`datagrunnlag - ${ratingState?.orgNr || "Måling"}.zip`}
          alignItems="center"
          gap="8px"
          width="100%"
          justifyContent={"flex-start"}
          isDisabled={!ratingState || ratingState.uploads.length === 0}
          variant="outline"
          bg="white"
          leftIcon={<Icon as={MdDownload} boxSize="18px" />}
        >
          Last ned alt datagrunnlag
        </DownloadButton>
        <Button
          justifyContent="flex-start"
          alignItems="center"
          gap="8px"
          onClick={requestAdditionalInfo}
          isDisabled={requestAdditionalInfoDisabled}
          variant="outline"
          bg="white"
          leftIcon={<Icon as={MdOutlineNoteAlt} boxSize="18px" />}
        >
          Be om tilleggsinformasjon
        </Button>
      </Flex>
    </Stack>
  )
}
