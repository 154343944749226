import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Box, Button, HStack, Link, Tooltip } from "@chakra-ui/react"
import { Tabs } from "components/Tabs"
import { ContentNavBar } from "components/common/ContentNavBar"
import { config } from "lib/helpers/config"
import { useReportDownload } from "lib/hooks/useReportDownload/useReportDownload"
import { useAuth } from "lib/providers/AuthProvider"
import { useRating } from "lib/providers/RatingProvider"
import { useMemo } from "react"
import { NavLink, useMatch } from "react-router-dom"
import styled from "styled-components"

const CompletedLabel = styled.span`
	margin-top: 12px;
	font-size: 13px;
	color: var(--chakra-colors-gray-700);
`

export const Navigation: React.FC = () => {
  const { ratingId, ratingState, isReadyForCompletion } = useRating()
  const { token } = useAuth()
  const match = useMatch("/ratings/:ratingId/:page/*")
  const page = match?.params.page || "data"

  const downloadReport = useReportDownload(ratingId)

  const canComplete = useMemo(() => {
    return isReadyForCompletion && ratingState.phase !== "COMPLETED"
  }, [isReadyForCompletion, ratingState.phase])

  return (
    <ContentNavBar style={{ marginBottom: 24 }}>
      <Box display="flex" alignItems="flex-end">
        <Tabs
          value={page}
          tabs={[
            {
              label: "Datagrunnlag",
              to: "data",
            },
            {
              label: "Indikatorer",
              to: "indicators",
            },
            {
              label: "Kommentar",
              to: "comment",
            },
          ]}
        />
      </Box>

      <HStack>
        {ratingState.phase === "COMPLETED" && (
          <>
            <CompletedLabel>Måling ferdigstilt: {ratingState.completedAt.toLocaleString()}</CompletedLabel>
            <Button
              onClick={() => downloadReport()}
              size={"lg"}
              variant="outline"
              bg="white"
              rightIcon={<ExternalLinkIcon marginLeft={2} />}
            >
              Last ned rapport
            </Button>
          </>
        )}
        {ratingState.phase !== "COMPLETED" && (
          <Button
            as={Link}
            target="_blank"
            rel="noreferrer"
            href={`${config.API_URL}/report-preview?ratingId=${ratingId.replace(/\./g, "-")}&authToken=${token}`}
            variant="outline"
            size="lg"
            bg="white"
            rightIcon={<ExternalLinkIcon marginLeft={2} />}
          >
            Forhåndsvis rapport
          </Button>
        )}
        <Tooltip
          hasArrow
          isDisabled={!!ratingState.ratingComment}
          label={
            canComplete
              ? "Kommentar må skrives før målingen kan fullføres"
              : "Målingen kan ikke fullføres før alle KPIer er godkjent"
          }
        >
          <Button
            size="lg"
            colorScheme="green"
            as={NavLink}
            onClick={(e) => (canComplete ? undefined : e.preventDefault())}
            to={ratingState.ratingComment ? "comment/deliver" : "comment"}
            isDisabled={!canComplete}
          >
            Fullfør måling
          </Button>
        </Tooltip>
      </HStack>
    </ContentNavBar>
  )
}
