import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { useUpgradeRatingToFullMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"

interface UpgradeRatingToFullModalProps {
  onClose: () => void
  isOpen: boolean
}

export const UpgradeRatingToFullModal: React.FC<UpgradeRatingToFullModalProps> = ({ isOpen, onClose }) => {
  const { ratingId, upgradedToFull, isReady } = useRating()
  const [upgradeRatingToFullStatus, upgradeRatingToFull] = useUpgradeRatingToFullMutation()

  const save = () => {
    upgradeRatingToFull({ ratingId })
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="lg">
      <ModalOverlay />
      {isReady ? (
        <>
          {upgradedToFull ? (
            <ModalContent>
              <ModalHeader>Måling oppgradert</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text mb="8px">Målingen er blitt oppgradert</Text>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={onClose} isLoading={upgradeRatingToFullStatus.fetching}>
                  Lukk
                </Button>
              </ModalFooter>
            </ModalContent>
          ) : (
            <ModalContent as="form">
              <ModalHeader>Oppgrader måling</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text mb={3} fontSize={16}>
                  Er du sikker på at du vil oppgradere denne målingen til full versjon?
                </Text>
              </ModalBody>
              <ModalFooter gap="4">
                {!upgradeRatingToFullStatus.fetching && (
                  <Button variant="outline" onClick={onClose} isLoading={upgradeRatingToFullStatus.fetching}>
                    Avbryt
                  </Button>
                )}
                <Button colorScheme="green" onClick={save} isLoading={upgradeRatingToFullStatus.fetching}>
                  Ja, oppgrader
                </Button>
              </ModalFooter>
            </ModalContent>
          )}
        </>
      ) : (
        <ModalContent bg="transparent" display="flex" justifyContent="center" alignItems="center">
          <Spinner size="xl" color="green.500" />
        </ModalContent>
      )}
    </Modal>
  )
}
