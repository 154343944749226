import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react"
import { IconArrowRight } from "components/Icons"
import { useAddAdditionalQuestionMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"

interface FormValues {
  text: string
  helpText?: string
  textInput: boolean
  uploads: boolean
}

export const AdditionalQuestionsForm: React.FC = () => {
  const { ratingId } = useRating()
  const navigate = useNavigate()
  const {
    formState: { isValid },
    watch,
    register,
    getValues,
    handleSubmit,
  } = useForm<FormValues>({ mode: "onChange" })

  const [addQuestionStatus, addQuestion] = useAddAdditionalQuestionMutation()

  const onSubmit = async (values: FormValues) => {
    await addQuestion({ ...values, ratingId })

    navigate("../../questions")
  }

  const close = () => navigate("../..")

  const allowSubmit = useMemo(() => {
    const values = getValues()
    return isValid && (values.textInput || values.uploads)
  }, [watch(), isValid])

  return (
    <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>
        Opprett tilleggsspørsmål{" "}
        <Button display="block" variant="link" as={Link} to=".." fontSize={16} color="blue.500">
          <IconArrowRight transform="rotate(180deg)" mr={0.5} /> Tilbake
        </Button>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack flex={1} gap="8px">
          <FormControl>
            <FormLabel>Spørsmål tekst</FormLabel>
            <Input placeholder="Spørsmålstekst" {...register("text", { required: true })} autoFocus={true} />
          </FormControl>
          <FormControl>
            <FormLabel>Tilleggstekst</FormLabel>
            <Textarea placeholder="Her kan du skrive bra hjelpetekst" {...register("helpText")} />
          </FormControl>
          <HStack mt={2} gap="16px" alignSelf="flex-start">
            <Stack>
              <Text fontWeight="bold">Åpne opp for:</Text>
              <Checkbox isChecked isDisabled>
                Tilleggsspørsmål
              </Checkbox>
            </Stack>
            <Stack>
              <Text fontWeight="bold">Krever:</Text>
              <HStack>
                <Checkbox {...register("textInput")}>Tekstsvar</Checkbox>
                <Checkbox {...register("uploads")}>Filopplasting</Checkbox>
              </HStack>
            </Stack>
          </HStack>
        </VStack>
        <VStack alignItems={"flex-start"} gap="16px" />
      </ModalBody>
      <ModalFooter gap={2}>
        {!addQuestionStatus.fetching && (
          <Button variant="outline" onClick={close} isLoading={addQuestionStatus.fetching}>
            Avbryt
          </Button>
        )}
        <Button colorScheme="green" type="submit" isDisabled={!allowSubmit} isLoading={addQuestionStatus.fetching}>
          Opprett tilleggsspørsmål
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
