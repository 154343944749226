import { decodeJwt } from "jose"
import { config } from "./helpers/config"

interface GetLoginTokenProps {
  token: string
  otp: string
}

const baseUrl = `${config.API_URL}/auth`

export const getLoginToken = async ({ token, otp }: GetLoginTokenProps) => {
  const res = await fetch(`${baseUrl}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token, otp }),
  })

  if (!res.ok) {
    throw new Error(await res.text())
  }

  return (await res.json()) as { token: string }
}

interface RequestOtpProps {
  email: string
  nextUrl?: string
  rememberMe?: boolean
}

interface TokenResponse {
  token: string
}

export const requestOtp = async ({ email, nextUrl, rememberMe }: RequestOtpProps) => {
  const res = await fetch(`${baseUrl}/generate-otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email.toLowerCase().trim(), nextUrl, rememberMe }),
  })

  if (!res.ok) {
    throw new Error(await res.text())
  }

  const { token }: TokenResponse = await res.json()

  return token
}

interface SignupProps {
  name: string
  companyName: string
  email: string
  phone: string
  termsAccepted: boolean
}

interface SignupResponse {
  emailSent: boolean
}

export const signup = async ({ name, companyName, email, phone, termsAccepted }: SignupProps) => {
  const res = await fetch(`${baseUrl}/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, companyName, email: email.toLowerCase().trim(), phone, termsAccepted }),
  })

  if (!res.ok) {
    throw new Error(await res.text())
  }

  const response: SignupResponse = await res.json()

  return response
}

interface SwitchAccountProps {
  token: string
  accountId: number
}

export const switchAccount = async ({ token, accountId }: SwitchAccountProps) => {
  const res = await fetch(`${baseUrl}/switch-account`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token, accountId }),
  })

  if (!res.ok) {
    throw new Error(await res.text())
  }

  const { token: newToken }: TokenResponse = await res.json()

  return newToken
}

interface RefreshTokenProps {
  token: string
}

export const refreshToken = async ({ token }: RefreshTokenProps): Promise<string | undefined> => {
  const { iat, exp } = decodeJwt(token)

  const totalLifeTimeInHours = (exp - iat) / 60 / 60
  const lifeTimeLeftInHours = (exp - Date.now() / 1000) / 60 / 60
  const shouldRefreshToken = totalLifeTimeInHours > 24 && lifeTimeLeftInHours < 24 * 2

  if (shouldRefreshToken) {
    const res = await fetch(`${baseUrl}/refresh-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })

    if (!res.ok) {
      throw new Error(await res.text())
    }

    const { token: newToken }: TokenResponse = await res.json()
    return newToken
  }
  return
}
