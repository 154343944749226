import { useSearchParams } from "react-router-dom"

export const PreviewWatermark = () => {
  const [params] = useSearchParams()
  const is = params.get("isPreview") === "1"

  return is ? (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 999,
      }}
    >
      <span
        style={{
          fontSize: "40pt",
          fontWeight: "bold",
          color: "grey",
          transform: "rotate(-30deg)",
          userSelect: "none",
        }}
      >
        FORHÅNDSVISNING
      </span>
    </div>
  ) : null
}
