import { Can } from "components/Can"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { Navigate, Route, Routes } from "react-router-dom"
import { New } from "./New"
import { Overview } from "./Overview"
import { RatingProfile } from "./RatingProfile"

export const RatingProfiles: React.FC = () => {
  useBreadcrumb("Målingsprofiler", "/admin/rating-profiles")

  return (
    <Can permission="system:write" entityId="*" noAccessComponent={<Navigate to="/" replace={true} />}>
      <Routes>
        <Route index element={<Overview />} />
        <Route path="new" element={<New />} />
        <Route path=":ratingProfileId/*" element={<RatingProfile />} />
      </Routes>
    </Can>
  )
}
