import { Flex, Icon, Text } from "@chakra-ui/react"
import { PiWarningLight } from "react-icons/pi"

interface ErrorBannerProps {
  msg: string
}

export const ErrorBanner = ({ msg }: ErrorBannerProps) => {
  return (
    <Flex position="absolute" top={0} left={0} right={0} justifyContent="space-around">
      <Flex alignItems="center" gap={2} py={2} px={3} borderRadius="0 0 4px 4px" bg="critical.500" color="white">
        <Icon boxSize="20px" as={PiWarningLight} />
        <Text>{msg}</Text>
      </Flex>
    </Flex>
  )
}
