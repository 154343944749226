import { Navigate, Route, Routes } from "react-router-dom"
import { A07 } from "./A07"

export const AltinnRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="avstemmingsinformasjon" replace={true} />} />
      <Route path="avstemmingsinformasjon" element={<A07 />} />
    </Routes>
  )
}
