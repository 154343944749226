import { numericFormatter } from "react-number-format"

export const formatValue = (answer?: string) => {
  const answerAsString = answer?.toString()
  const isNumeric = answerAsString?.match(/^[0-9]+.[0-9]+$/)

  const isDecimal = isNumeric && answerAsString?.match(/^[0-9]+.[0-9]+$/)

  return isNumeric
    ? numericFormatter(answerAsString, {
        thousandSeparator: " ",
        thousandsGroupStyle: "thousand",
        decimalSeparator: ",",
        inputMode: isDecimal ? "decimal" : "numeric",
      })
    : answer
}
