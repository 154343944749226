import { HelpPopover, HelpPopoverProvider } from "components/HelpPopover"
import { NavbarHorizontal } from "components/NavbarHorizontal"
import { PageContent } from "components/PageContent"
import { RatingAborted } from "components/RatingAborted"
import { RatingHeader } from "components/RatingHeader"
import { RatingLoading } from "components/RatingLoading"
import { RatingStatus } from "components/RatingStatus"
import { RatingProvider } from "lib/providers/RatingProvider"
import { useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { FoundationRoutes } from "./Routes"

export const RatingFoundation = () => {
  const { id } = useParams<{ id: string }>()
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" })
    })
  }, [location])

  return (
    <RatingProvider ratingId={id}>
      <NavbarHorizontal />
      <HelpPopoverProvider>
        <HelpPopover />
        <RatingLoading>
          <RatingAborted>
            <RatingHeader />
            <PageContent>
              <RatingStatus />
              <FoundationRoutes />
            </PageContent>
          </RatingAborted>
        </RatingLoading>
      </HelpPopoverProvider>
    </RatingProvider>
  )
}
