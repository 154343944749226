export const datapoints = {
  driftsinntekterUB: "Virksomhetens totale omsetning for perioden.\nSAF-T sum konto: 3000 til 3999",
  driftskostnaderUB: "Virksomhetens driftskostnader for perioden.\nSAF-T sum konto: 4000 til 7999",
  avskrivningerUB: "Virksomhetens avskrivninger for perioden.\nSAF-T sum konto: 6000 til 6049",
  nedskrivningerUB: "Virksomhetens nedskrivninger for perioden.\nSAF-T sum konto: 6050 til 6059",
  finansinntekterUB: "Virksomhetens finansinntekter for perioden.\nSAF-T sum konto: 8000 til 8099",
  finanskostnaderUB: "Virksomhetens finanskostnader for perioden.\nSAF-T sum konto: 8100 til 8199",
  egenkapitalUB: "Virksomhetens egenkapital for perioden.\nSAF-T sum konto: 2000 til 2099",
  eiendelerUB: "Virksomhetens eiendeler for perioden.\nSAF-T sum konto: 1000 til 1999",
  gjeldUB: "Virksomhetens gjeld for perioden.\nSAF-T sum konto: 2100 til 2999",
  skattEkstraordinærIKUB:
    "Virksomhetens skatt og ekstraordinære inntekter og kostnader for perioden.\nSAF-T sum konto: 8200 til 8799",
  utsattSkattUB: "Virksomhetens utsatte skatt for perioden.\nSAF-T sum konto: 1070",
  omløpsmidlerUB: "Virksomhetens omløpsmidler for perioden.\nSAF-T sum konto: 1400 til 1999",
  kortsiktigGjeldUB: "Virksomhetens kortsiktige gjeld for perioden.\nSAF-T sum konto: 2300 til 2999",
  varelagerUB: "Virksomhetens varelager for perioden.\nSAF-T sum konto: 1400 til 1499",
  langsiktigGjeldUB: "Virksomhetens langsiktige gjeld for perioden.\nSAF-T sum konto: 2200 til 2299",
  anleggsmidlerUB: "Virksomhetens anleggsmidler for perioden.\nSAF-T sum konto: 1000 til 1399",
  KvalitetØkonomiskData: "Score på virksomhetens grad av etterlevelse av norsk regnskapslov i perioden.",
  EtterlevFrister: "Score på virksomhetens grad av etterlevelse av frister i perioden.",
  kwhPeriodeArealEiet: "Totalt strømforbruk for perioden, tilhørende eid(e) lokale(r) / eiet areal (egen strømmåler).",
  kwhPeriodeArealLeiet: "Totalt strømforbruk for perioden, inngått i leiekontrakt eller avtale.",
  kwhPeriodeArealUtleid: "Totalt strømforbruk viderefakturert for perioden, ved utleie av areal.",
  egenProdusertKwhIkkeFornybar:
    "Total antall kWh som virksomheten selv har produsert i perioden, fra ikke-fornybare kilder.",
  egenProdusertKwhFornybar: "Total antall kWh som virksomheten selv har produsert i perioden, fra fornybare kilder",
  egenProdusertkwhVidereSolgt: "Videresalg av egenprodusert fornybar overskuddselektrisitet (kWh), i perioden.",
  arealEiet: "Totalt byggareal eiet av virksomheten i perioden.",
  arealLeiet: "Totalt leid byggareal benyttet av virksomheten i perioden.",
  arealUtleid: "Total byggareal utleid av virksomheten i perioden.",
  leidCoProdusertKwhFornybar:
    "Totalt forbruk av fornybar energi produsert av utleier eller coworking space, i løpet av perioden.",
  kwhPeriodeMaks: "Høyeste effektbelastning, for perioden.",
  kwhPeriodeGjsnitt: "Gjennomsnittlig strømforbruk, for perioden.",
  literBensin: "Antall liter bensin brukt i perioden.",
  literDiesel: "Antall liter diesel brukt i perioden.",
  literOlje: "Antall liter olje brukt i perioden.",
  m3vannPeriodeEiet: "Totalt vannforbruk for perioden, tilhørende eid(e) lokale(r) / eiet areal (egen vannmåler).",
  m3vannPeriodeLeiet: "Totalt vannforbruk for perioden, inngått i leiekontrakt eller avtale.",
  m3vannPeriodeUtleid: "Totalt vannforbruk viderefakturert for perioden, ved utleie av areal.",
  kgAvfallPeriodeEiet: "Total avfallsmengde for perioden, tilhørende eid(e) lokale(r) / eiet areal (egen vannmåler).",
  kgAvfallPeriodeLeiet: "Total avfallsmengde for perioden, inngått i leiekontrakt eller avtale.",
  kgAvfallPeriodeUtleid: "Total avfallsmengde viderefakturert for perioden, ved utleie av areal.",
  kgResAvfallPeriodeEiet: "Total andel resirkulert avfall for perioden, tilhørende eid(e) lokale(r) / eiet areal",
  kgResAvfallPeriodeLeiet: "Total andel resirkulert avfall for perioden, inngått i leiekontrakt eller avtale.",
  kgResAvfallPeriodeUtleid: "Total andel resirkulert avfall viderefakturert for perioden, ved utleie av areal.",
  kgMatAvfallPeriode: "Total mengde matavfall for perioden.",
  gjsnittÅrsverk: "Virksomhetens gjennomsnittlige årsverk for perioden.",
  innResMaterialKg: "Inngående resirkulerte materialer i alle produkter/leveranser, for perioden.",
  utResMaterialKg: "Total vekt for materialer som kan resirkuleres etter bruk av ferdig produkt/leveranse, i perioden.",
  totalMaterialKg: "Produktenes/leveransenes totale vekt, for perioden.",
  resMaterialEmballasje:
    "Total vekt på resirkulerbar eller resirkulert emballasje benyttet og/eller produsert, gjennom perioden.",
  materialEmballasje: "Total vekt på all emballasje benyttet og/eller produsert gjennom perioden.",
  co2efaktorEgenProdKwh:
    "Omregningsfaktor fra kWh til utslipp i CO₂-ekvivalenter, for virksomhetens egenproduserte elektrisitet i perioden.",
  Scope1Co2eA:
    "Totalt utslipp av CO₂-ekvivalenter i kilogram, fra anleggsmidler som virksomheten eier eller kontrollerer i perioden.",
  opExUB: "Virksomhetens OpEx for perioden.\nSAF-T sum konto: 5000 til 7999, minus sum konto 6000 til 6059",
  capExUB: "Virksomhetens CapEx for perioden.\nSAF-T sum konto: 1000 til 1299, pluss sum konto 6000 til 6099.",
  CO2Tjenestereiser:
    "Totalt utslipp av CO₂-ekvivalenter i gram, fra alle tjenestereiser virksomheten hadde i perioden.",
  kmTjenestereiser: "Totalt antall kilometer  fra alle tjenestereiser virksomheten hadde i perioden.",
  NOxTjenestereiser: "Totalt antall utslipp av NOx i gram, fra alle tjenestereiser virksomheten hadde i perioden.",
  CO2TransportDistribusjon:
    "Totalt utslipp av CO₂-ekvivalenter i gram, fra all kjøpt transport og distribusjon virksomheten hadde i perioden.",
  kmTransportDistribusjon: "Totalt antall kilometer  fra all kjøpt transport virksomheten hadde i perioden.",
  NOxTransportDistribusjon:
    "Total mengde NOx-utslipp for kjøpt transport og/eller distribusjon, gjennomført i perioden",
  "CO2-ekvivalenter": "Virksomhetens CO₂-ekvivalenter for perioden.",
  medlemMiljoGronnepartner:
    "Score for miljøsertifiseringer og/eller grønne partnerskap virksomheten har hatt i perioden.",
  miljøkostnader:
    "Driftskostnader, i perioden, som oppfyller kriteriene for miljømessig bærekraftige aktiviteter i taksonomien.",
  miljoinvesteringerPeriode:
    "Investeringsutgifter, i perioden, som oppfyller kriteriene for miljømessig bærekraftige aktiviteter i taksonomien.",
  tidligMiljoinvesteringer:
    "Investeringsutgifter, fra tidligere perioder (som fortsatt er oppført i balansen), som oppfyller kriteriene for miljømessig bærekraftige aktiviteter i taksonomien.",
  energimerkeScore: "Score på energi- eller miljømerking av bygg som virksomheten har benyttet, i perioden.",
  MiljøkravLev:
    "Score på miljøkrav som virksomheten har stilt til sine leverandører, underleverandører og samarbeidspartnere, i perioden.",
  miljoKravLeverandorDocs:
    "Dette datapunktet inneholder dokumentasjonen til kunden. Det skal ikke stå noen verdi i dette datapunktet.",
  miljoKravLeverandorMaterialer: "Miljøkrav til leverandør vedrørende materialer.",
  miljoKravLeverandorEnergi: "Miljøkrav til leverandør vedrørende energi og utslipp.",
  miljoKravLeverandorRessurser: "Miljøkrav til leverandør vedrørende ressursforbruk.",
  miljoKravLeverandorSertifisering: "Miljøkrav til leverandør vedrørende miljøsertifiseringer.",
  AnsvarligHåndtKjemikalier: "Score på virksomhetens grad av ansvarlig håndtering av kjemikalier, i perioden.",
  Miljøkontroll: "Score på virksomhetens oversikt på eget miljøavtrykk i perioden.",
  månedsverkKvinner:
    "Totalt antall månedsverk utført av kvinnelige ansatte i løpet av perioden. Arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm* telles ikke med.",
  månedsverkMenn:
    "Totalt antall månedsverk utført av mannlige ansatte i løpet av perioden. Arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm* telles ikke med.",
  medianlonnKvinner:
    "Medianlønn for kvinnelige ansatte i perioden. Alle årslønner er regnet opp til 100% stillingsprosent. Personer registrert med stillingskode for lærlinger og arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm*, telles ikke med.",
  medianlonnMenn:
    "Medianlønn for mannlige ansatte i perioden. Alle årslønner er regnet opp til 100% stillingsprosent. Personer registrert med stillingskode for lærlinger og arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm*, telles ikke med.",
  gjennomsnittslonnKvinneligeAnsatte:
    "Gjennomsnittslønn for kvinnelige ansatte, for perioden. Alle årslønner er regnet opp til 100% stillingsprosent. Personer registrert med stillingskode for lærlinger og arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm*, telles ikke med.",
  gjennomsnittslonnMannligeAnsatte:
    "Gjennomsnittslønn for mannlige ansatte, for perioden. Alle årslønner er regnet opp til 100% stillingsprosent. Personer registrert med stillingskode for lærlinger og arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm*, telles ikke med.",
  AldersbArbeidsstokk:
    "Aldersbalanse i arbeidsstokk. Datapunktets verdi tar utgangspunkt i resultatene fra datapunktene månedsverkU30, månedsverk30til50 og månedsverkO50.",
  månedsverkU30:
    "Totalt antall månedsverk utført av ansatte under 30 år, i løpet av perioden. Arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm* telles ikke med.",
  månedsverk30til50:
    "Totalt antall månedsverk utført av ansatte mellom 30 år til 50 år, i løpet av perioden. Arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm* telles ikke med.",
  månedsverkO50:
    "Totalt antall månedsverk utført av ansatte over 50 år, i løpet av perioden. Arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm* telles ikke med.",
  månedsverkKvinneligeLedere:
    "Totalt antall månedsverk utført av kvinnelige ledere i virksomheten, i løpet av perioden. Kvinner registrert med yrkeskode som starter med 1 eller 2.",
  månedsverkMannligeLedere:
    "Totalt antall månedsverk utført av mannlige ledere i virksomheten, i løpet av perioden. Menn registert med yrkeskode som starter med 1 eller 2. ",
  kvinnerStyret: "Totalt antall kvinner i virksomhetens styre, i løpet av perioden.",
  mennStyret: "Totalt antall menn i virksomhetens styre, i løpet av perioden.",
  foreldrePermisjonsOrdninger: "Score for virksomhetens foreldrepermisjonsordninger for perioden.",
  medianÅrslønner:
    "Virksomhetens medianlønn for alle ansatte i perioden. Alle årslønner er regnet opp til 100% stillingsprosent. Personer registrert med stillingskode for lærlinger og arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm*, telles ikke med.",
  sumÅrslønner:
    "Alle årslønner i virksomheten summert. Alle årslønner er regnet opp til 100% stillingsprosent. Personer registrert med stillingskode for lærlinger og arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm*, telles ikke med.",
  gjsnittAnsatteULærlinger:
    "Gjennomsnittlig ansatte ekskludert lærlinger, i løpet av perioden. Datapunktet teller unike fødselsnummer i alle A07, med unntak av ansatte registrert med stillingskode for lærlinger, og finner gjennomsnittlig ansatte for perioden. " +
    "Arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm* telles heller ikke med.",
  ansatte:
    "Antall ansatte i virksomheten i løpet av perioden. Datapunktet teller unike fødselsnummer i alle A07. Arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm* telles ikke med.",
  årslønnerLavest:
    "Virksomhetens laveste årslønn. Alle årslønner er regnet opp til 100% stillingsprosent. Personer registrert med stillingskode for lærlinger og arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm*, telles ikke med.",
  årslønnerHøyest:
    "Virksomhetens høyeste årslønn. Alle årslønner er regnet opp til 100% stillingsprosent. Personer registrert med stillingskode for lærlinger og arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm*, telles ikke med.",
  overtidsbetaling: "Score på virksomhetens praksis på overtidsbetaling i perioden.",
  timerMidlertAnsatte:
    "Totalt antall timer utført av midlertidig ansatte i perioden. Arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm* telles ikke med.",
  betalteTimerMidlertAnsatte: "Totalt antall utbetalte timer til midlertidige ansatte i perioden.",
  langtidSykTimesverk:
    "Totalt antall timer registert som langsiktig sykefravær (sammenhengende sykefravær over 15 dager) i perioden.",
  korttidSykTimesverk:
    "Totalt antall timer registert som kortsiktig sykefravær (under 15 dager sammenhengende) i perioden.",
  timesverk: "Totalt antall timer utført av alle ansatte i perioden. ",
  etterlevStandArbeidslivet:
    "Score på virksomhetens grad av etterlevelse av (internasjonale) standarder i arbeidslivet, for perioden.",
  etterlevVentInneklima: "Score på virksomhetens etterlevelse av krav for ventilasjon og inneklima, for perioden.",
  kjemikalieHaandteringsSikring:
    "Score på om virksomheten har kvalitetssikringssystem for håndtering av kjemikalier. 1 betyr ja, og 0 betyr nei.",
  kjemikalieHåndteringsBrudd:
    "Score på om virksomheten har hatt brudd  eller avvik knyttet til behandling av kjemikalier. 1 betyr ja, og 0 betyr nei.",
  kjemikalieHåndteringsTilsyn:
    "Score på om virksomheten har hatt tilsyn av aktuell tilsynsmyndighet i perioden. 1 betyr ja, og 0 betyr nei.",
  forsikringAnsatte: "Score på virksomhetens praksis for yrkesskadeforsikring av ansatte, gjennom perioden. ",
  forsikringAnsatteEkstra: "Score på virksomhetens praksis for andre forsikringer av ansatte, gjennom perioden.",
  LTI: "Totalt antall timer fravær fra arbeid, eller tilrettelagt arbeid, grunnet yrkesskade eller dødsfall i forbindelse med arbeid, i løpet av perioden.",
  fagforeningstrekk: "Score på at ansatte har blitt trukket for fagforeningskontingent",
  beløpKursAnsatte: "Virksomhetens kostnader til kurs/opplæring for ansatte for perioden. SAF-T sum konto: 6860.",
  ansatteSluttet: "Totalt antall ansatte som har sluttet i virksomheten i løpet av perioden.",
  gjsnittAnsatte:
    "Sum månedsverk for perioden / antall måneder i perioden. Arbeidsforhold med type *frilanserOppdragstakerHonorarPersonerMm* telles ikke med.",
  mattrygghetRutiner:
    "Score på om virksomheten har rutiner for ivaretakelse av mattrygghet, i perioden. 1 betyr ja, og 0 betyr nei.",
  mattrygghetBrudd:
    "Score på om virksomheten har hatt registerte brudd knyttet til lovverk for mattrygghet, i perioden. 1 betyr ja, og 0 betyr nei.",
  mattrygghetTilsyn:
    "Score på om virksomheten har hatt tilsyn av aktuell tilsynsmyndighet i perioden. 1 betyr ja, og 0 betyr nei.",
  leverandørGjeld30: "Virksomhetens utestående leverandørgjeld over 30 dager ved periodens slutt.",
  leverandørGjeldUB: "Virksomhetens utestående leverandørgjeld ved periodens slutt.\nSAF-T sum konto: 2400.",
  SosKravLeverandør: "Score på sosiale krav som virksomheten har til sine leverandører og/eller underleverandører.",
  sosialKravLeverandorDocs:
    "Dette datapunktet inneholder dokumentasjonen til kunden. Det skal ikke stå noen verdi i dette datapunktet.",
  sosialKravLeverandorVilkaar: "Sosiale krav til leverandør vedrørende lønns- og arbeidsvilkår.",
  sosialKravLeverandorArbforhold: "Sosiale krav til leverandør vedrørende regulering av arbeidsforhold.",
  sosialKravLeverandorArbSikkerhet: "Sosiale krav til leverandør vedrørende sikkerhetstiltak for arbeidere.",
  sosialKravLeverandorMenneskerettigheter:
    "Sosiale krav til leverandør vedrørende ivaretakelse av menneskerettigheter.",
  sosialKravLeverandorKorrupsjon: "Sosiale krav til leverandør vedrørende å forhindre korrupsjon.",
  gdprRutiner: "Score på om virksomheten har GDPR-rutiner. ",
  gdprBehandlingsprotokoll: "Score på om virksomheten har behandlingsprotokoll",
  databehandleravtaler: "Score på om virksomheten har databehandleravtaler",
  gdprBrudd: "Score på om virksomheten har hatt GDPR brudd",
  frivilligArbeid:
    "Totalt antall ansatte som ved eget iniativ eller gjennom virksomheten har bidratt og/eller deltatt i frivillighetsarbeid, i løpet av perioden.",
  frivilligDonasjoner:
    "Totalt beløp for donasjoner og/eller sponsoravtaler som virksomheten har gitt til frivillige organisasjoner, foreninger eller stiftelser, i løpet av perioden.",
  bransjeeksternaliteter: "Score på virksomhetens aktiviteters påvirkning på samfunnet.",
  bransjemottiltak: "Score på virksomhetes mottiltak mot negativ påvirkning på samfunnet.",
  antikorrupsjonRutiner:
    "Score på om virksomheten har rutiner knyttet til antikorrupsjon, terrorfinansiering og hvitvasking. 1 betyr ja, og 0 betyr nei.",
  antikorrupsjonBrudd:
    "Score på om virksomheten har hatt brudd eller avvik knyttet til antikorrupsjon, terrorfinansiering og hvitvasking. 1 betyr ja, og 0 betyr nei.",
  antikorrupsjonTilsyn:
    "Score på om virksomheten har hatt tilsyn av aktuell tilsynsmyndighet i perioden. 1 betyr ja, og 0 betyr nei.",
  timesverkLærlinger:
    "Totalt antall utførte timer av lærlinger i perioden. Timesverket regnes automatisk ut fra A07-filene.",
  timesverkInternsArbeidstrening:
    "Totalt antall utførte timer av utplasseringselever/-studenter, internships, traineer, arbeidstrening og/eller andre midlertidig ansatte i perioden.",
  sosialtAvtrykk: "Score på virksomhetens sosiale avtrykk i perioden.",
  tilknEksklusjonslister: "Score på om virksomheten er tilknyttet bransje i eksklusjonsliste. 1 betyr ja, 0 betyr nei.",
  tilknEksklusjonslisteMottiltak:
    "Score på om virksomheten har gjort mottiltak når de er i bransje tilknyttet eksklusjonsliste. 1 betyr ja, 0 betyr nei. ",
}
