import { Spinner } from "@chakra-ui/react"
import { useRating } from "lib/providers/RatingProvider"

export const RatingLoading: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isReady } = useRating()

  if (!isReady) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          flex: "1",
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "top",
          justifyContent: "center",
        }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
          style={{ marginTop: "10%" }}
        />
      </div>
    )
  }
  return <>{children}</>
}
