import { ChakraProvider } from "@chakra-ui/react"
import * as Sentry from "@sentry/react"
import i18n from "i18next"
import { config } from "lib/helpers/config"
import { nb } from "lib/i18n/nb"
import React, { useEffect } from "react"
import { createRoot } from "react-dom/client"
import { initReactI18next } from "react-i18next"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"
import { App } from "./App"
import "./index.css"
import { theme } from "./lib/theme"

const isProduction = config.meta.environment === "production"

Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: [config.API_URL, config.WS_API_URL],
  // Performance Monitoring
  tracesSampleRate: isProduction ? 0.5 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: isProduction ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: config.sentry.enabled,
  environment: config.meta.environment,
  release: `web@${config.meta.release}`,
})

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      nb: {
        translation: nb,
      },
    },
    lng: "nb",
    fallbackLng: "nb",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
)
