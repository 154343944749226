import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useVersion } from "lib/providers/VersionProvider"
import { useCallback, useEffect, useMemo } from "react"

interface Props {
  /** The message to display when a new version is available */
  message?: string
  /** button label. Button won't be visible if this is undefined */
  buttonText?: React.ReactNode
}

const DEFAULTS = {
  message: "En ny versjon er tilgjengelig",
  buttonText: "Oppdater versjon",
}

export const useVersionNotification = (maybeProps?: Props) => {
  const props = useMemo(() => ({ ...DEFAULTS, ...maybeProps }), [maybeProps])
  const { hasVersionChanged, isMaintenance } = useVersion()
  const toast = useToast()

  useEffect(() => {
    if (hasVersionChanged) {
      displayNotification(isMaintenance)
    }
  }, [hasVersionChanged, isMaintenance])

  const displayNotification = useCallback(
    (maintenance?: boolean) => {
      if (maintenance) {
        toast({
          id: "maintenance-notification",
          status: "warning",
          isClosable: false,
          duration: null,
          position: "top",
          render: () => (
            <Modal onClose={undefined} isOpen={true} closeOnEsc={false} closeOnOverlayClick={false}>
              <ModalOverlay />
              <ModalContent>
                <Alert status="warning" flexDirection="column" borderRadius="md">
                  <AlertIcon boxSize="40px" mr={0} />
                  <AlertTitle>Vedlikehold</AlertTitle>
                  <AlertDescription>Det blir utført vedlikehold på ESGTrackr</AlertDescription>
                  <Text>Vennligst kom tilbake senere</Text>
                </Alert>
              </ModalContent>
            </Modal>
          ),
        })
      } else {
        toast({
          id: "version-notification",
          status: "info",
          isClosable: false,
          duration: null,
          position: "top-right",
          containerStyle: {
            marginTop: "12px",
            marginRight: "100px",
          },
          render: () => (
            <Alert status="info" variant="subtle" borderRadius="md" flexWrap="wrap">
              <Text mr="4" fontSize="xl">
                🎉
              </Text>
              <AlertTitle>{props.message}</AlertTitle>
              {props.buttonText && (
                <Button variant="link" color="blue.500" onClick={() => window.location.reload()}>
                  {props.buttonText}
                </Button>
              )}
            </Alert>
          ),
        })
      }
    },
    [props],
  )
}
