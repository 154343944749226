import { ChevronDownIcon } from "@chakra-ui/icons"
import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useToast } from "@chakra-ui/react"
import * as auth from "lib/auth"
import { useAccessibleAccountsQuery } from "lib/generated/graphql"
import { useAuth } from "lib/providers/AuthProvider"
import { routes } from "lib/routes"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

export const AccountSwitcher = () => {
  const { signin, token } = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const [{ data: accountsData, fetching }] = useAccessibleAccountsQuery()

  const { accounts, currentAccount } = accountsData || { accounts: [], currentAccount: {} }

  const switchAccount = async (accountId: number) => {
    setLoading(true)
    try {
      const newToken = await auth.switchAccount({ accountId, token })
      await signin(newToken)
      navigate(routes.ratings())
    } catch (e) {
      console.error(e)
      toast({
        status: "error",
        title: "Kunne ikke bytte konto",
        description: "Prøv igjen senere",
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Flex>
      <Text fontSize="md">Du ser på: {currentAccount?.title}</Text>
      <Menu>
        <MenuButton
          as={Button}
          variant="link"
          fontWeight="400"
          textDecoration="none"
          isLoading={fetching || loading}
          color="blue.500"
          ml="16px"
          minW="none"
        >
          Bytt konto
          <ChevronDownIcon boxSize={5} />
        </MenuButton>
        <MenuList>
          {accounts.map((a) => (
            <MenuItem
              key={a.id}
              onClick={(e) => {
                e.preventDefault()
                switchAccount(a.id)
              }}
            >
              {a.title}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}
