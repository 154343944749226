import { Can } from "components/Can"
import { routes } from "lib/routes"
import { Navigate, Route, Routes } from "react-router-dom"
import { Account } from "./Account"

import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { New } from "./New"
import { Overview } from "./Overview"

export const Accounts: React.FC = () => {
  useBreadcrumb("Kontoer", "/admin/accounts")

  return (
    <Routes>
      <Route
        index
        element={
          <Can
            permission="account:update"
            entityId="*"
            noAccessComponent={<Navigate to={routes.ratings()} replace={true} />}
          >
            <Overview />
          </Can>
        }
      />
      <Route
        path="new"
        element={
          <Can
            permission="system:write"
            entityId="*"
            noAccessComponent={<Navigate to={routes.ratings()} replace={true} />}
          >
            <New />
          </Can>
        }
      />
      <Route path=":accountId" element={<Account />} />
    </Routes>
  )
}
