import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Box, Flex, Link, Text } from "@chakra-ui/react"
import { RatingFileCategory, UploadScopeEnum } from "@esgt/types"
import ContentAccordion from "components/ContentAccordion"
import { ContentAccordionLeft } from "components/ContentAccordionLeft"
import { ContentAccordionRight } from "components/ContentAccordionRight"
import { FoundationHeader } from "components/FoundationHeader"
import FoundationMainContent from "components/FoundationMainContent"
import InfoModalLink from "components/ModalLink"
import { RatingUploader } from "components/RatingUploader"
import { useRating } from "lib/providers/RatingProvider"

export const ArAr = () => {
  const { ratingState, submissionProgress } = useRating()
  const readOnly =
    ratingState?.phase !== "SUBMISSION" ||
    (ratingState?.reopenedScopes && !ratingState?.reopenedScopes?.includes("finances"))

  return (
    <FoundationMainContent>
      <FoundationHeader
        title="Aldersfordelt saldoliste"
        helpText="Aldersfordelt saldoliste viser alle ubetalte fakturaer, fordelt på antall dager etter forfall."
      />
      <ContentAccordion
        headingText="Aldersfordelt saldoliste fra periodens siste dag"
        completed={submissionProgress?.regnskap?.aldersfordeltSaldoListe}
        readOnly={readOnly}
      >
        <ContentAccordionLeft>
          <Text mb="4">
            Last opp aldersfordelt saldoliste for kunder og leverendører, fra periodens siste dag i .xls (excel) format.{" "}
          </Text>
          <RatingUploader
            uploadScope={UploadScopeEnum.AldersfordeltSaldoliste}
            ratingFileType={RatingFileCategory.generic}
            readOnly={readOnly}
          />
        </ContentAccordionLeft>
        <ContentAccordionRight>
          <Box>
            <Text mb="4">
              Aldersfordelt saldoliste benyttes av ESG Trackr til vurdering av governance, og hvordan virksomheten
              inngår i sin verdikjede som leverandør og kunde.
            </Text>

            <Text>
              Er du usikker på hvordan du skal finne frem til, og få lastet opp aldersfordelt saldoliste fra periodens
              siste dag i .xls (excel) format? Finn ditt regnskapsprogram nedenfor og følg instruksjonene, eller ta
              kontakt med din regnskapsfører.
            </Text>
          </Box>

          <Flex gap="64px">
            <Flex flexFlow="column nowrap" gap="8px" color="blue.500" mt="4">
              <InfoModalLink
                linkText="Tripletex instruksjoner"
                header="Aldersfordelt saldoliste - Tripletex"
                body={
                  <>
                    <Text mb={4}>
                      <Text as={"strong"}>Kunde:</Text>
                      <Text>
                        Regnskap {">"} Reskonto {">"} Kunder (aldersfordelt) {">"} Sett siste dag i målingsperioden som
                        forfallsdato {">"} Last ned som xls-fil (excel)
                      </Text>
                    </Text>
                    <Text>
                      <Text as={"strong"}>Leverandør:</Text>
                      <Text>
                        Regnskap {">"} Reskonto {">"} Leverandør (aldersfordelt) {">"} Sett siste dag i målingsperioden
                        som forfallsdato {">"} Last ned som xls-fil (excel)
                      </Text>
                    </Text>
                  </>
                }
              />
              <InfoModalLink
                linkText="PowerOffice GO instruksjoner"
                header="Aldersfordelt saldoliste - PowerOffice GO"
                body={
                  <>
                    <Text mb={4}>
                      <Text as={"strong"}>Kunde:</Text>
                      <Text>
                        Meny {">"} Rapporter {">"} Salg: Aldersfordelt kundefordring
                      </Text>
                    </Text>
                    <Text>
                      <Text as={"strong"}>Leverandør:</Text>
                      <Text>
                        Meny {">"} Rapporter {">"} Innkjøp: Aldersfordelt leverandørgjeld
                      </Text>
                    </Text>
                  </>
                }
              />
              <Link
                href="https://community.visma.com/t5/Brukertips-i-Visma-net-ERP/Aldersfordelt-saldoliste-antall-dager-i-aldersfordelingen/ta-p/30417"
                isExternal
              >
                Visma.net instruksjoner <ExternalLinkIcon mb="4px" mx="2px" />
              </Link>
              <Link
                href="https://support.unimicro.no/kundestotte/regnskap/rapportering/saldo/sum/saldolister"
                isExternal
              >
                Unimicro - Uni Økonomi V3 instruksjoner <ExternalLinkIcon mb="4px" mx="2px" />
              </Link>
            </Flex>
          </Flex>
        </ContentAccordionRight>
      </ContentAccordion>
    </FoundationMainContent>
  )
}
