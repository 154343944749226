import { ContentBox } from "components/ContentBox"
import { Navigate, Route, Routes, useParams } from "react-router-dom"
import styled from "styled-components"
import { Category } from "./Category"
import { Dimension } from "./Dimension"
import { Navigation } from "./Navigation"
import { RevisionRedirector } from "./RevisionRedirector"

export const Revision: React.FC = () => {
  const { ratingProfileId, revision } = useParams()

  if (!ratingProfileId || !revision) {
    return <Navigate to="/" />
  }

  return (
    <Container>
      <Navigation />
      <MainContent>
        <ContentBox>
          <Routes>
            <Route path="dimension/:dimensionId" element={<Dimension />} />
            <Route path="category/:dimensionId/:categoryId" element={<Category />} />
            <Route index element={<RevisionRedirector />} />
          </Routes>
        </ContentBox>
      </MainContent>
    </Container>
  )
}

const Container = styled.div`
	display: flex;
	flex-flow: row nowrap;
	flex: 1;
	gap: 24px;
	overflow: hidden;
	padding: 24px 0;
`
const MainContent = styled.div`
	flex: 1;
	overflow-y: scroll;
	/* overflow-x: visible; */
	padding: 0 0 24px 24px;
`
