export const faqs = {
  conductRating: {
    question: "Hvordan gjennomføre måling?",
    answer:
      'Det første man gjør er å opprette en måling. Da blir det sendt ut e-post til kontaktpersonene i virksomheten, med innlogging til kundesiden. Der skal de legge inn data og svare på spørsmål. Når kunden har levert underlag, starter kontrolleringen av data og dokumentasjon. \n\nDet første man skal gjøre som kontrollør, er å kontrollere SAF-T-filen. Dette gjøres under "Datagrunnlag" og "Avvik og omklassifiseringer". Deretter går man videre til "Indikatorer". Her finner man alle indikatorene, sortert på dimensjon og kategori. Man skal følge rekkefølgen, så man starter med kategorien Lønnsomhet, i Økonomi-dimensjonen. Det er lurt å kontrollere i indikatorene i kronologisk rekkefølge, fordi man kontrollerer også datapunktenes verdi, og noen datapunkter finner man flere steder.\n\n Når disse er kontrollert og riktige, vil det derfor være riktig verdi på datapunktet neste gang det kommer opp. Følg veiledningsteksten på hver indikator for å sikre en objektiv måling. Når kontrollen er ferdig, kan man huke av for boksen "godkjent", som man finner ved hver indikator.\n\n Når alle indikatorene er godkjent, skal man klikke seg videre til "Leveranse". Her skrives kommentaren til bærekraftrapporten inn. Følg veiledningsteksten for hva en kommentar skal inneholde.\n\n Etter man har trykket "Fullfør måling" kan man ikke gå tilbake og gjøre endringer. ',
  },
  ratingStructure: {
    question: "Målingens oppbygging",
    answer:
      "Målingen er inndelt i 3 dimensjoner; Økonomi, miljø og sosial, og følger dermed prinsippet etter ESG. \n\n Videre er dimensjonene delt inn i kategorier, og hver kategori inneholder indikatorer. Indikatorene er oppbygd av en formel og datapunktene inneholder vediene i formlene. Verdien til datapunktene kommer opp automatisk, og er basert på innlevert data og svar på spørsmål.",
  },
  ratingPeriod: {
    question: "Målingsperiode",
    answer:
      "Målingsperioden er perioden målingen gjennomføres for. Det kan enten være et regnskapsår, eller et halvt regnskapsår. Dette for å ivareta sesongjusteringer.",
  },
  ratingProfile: {
    question: "Målingsprofil",
    answer:
      "Målingsprofilene inneholder måltallene som virksomhetene måles etter. Målingsprofilene følger næringskodene i NACE Rev.2, som brukes i EU. De er like med SN2007, de norske næringskodene, ned på 4-sifret nivå. Det finnes dermed én målingsprofil per NACE-kode, og alle målingsprofilene inneholder ulike måltall. \n\n Når man oppretter en måling kommer forslag til målingsprofil automatisk opp. Kontroller at målingsprofilen stemmer med næringskoden (4-sifret nivå). Derfor er det viktig at virksomheten er registrert med korrekt næringskode i Brønnøysundregisteret. \n\n Dersom virksomheten har feil næringskode kan ikke en måling utføres, da det vil gi feil måltall til virksomhetens faktiske drift. Enkelte målingsprofiler har også skrudd av noen indikatorer. For eksempel vil næringskode 69.20 Regnskap, revisjon og skatterådgivning ikke ha behov for å svare ut indikatoren Ansvarlig håndtering av kjemikalier.",
  },
  datapointControl: {
    question: "Kontroll av datapunkter",
    answer:
      "Alle datapunkter krever kontroll. Datapunktene som er hentet fra/utregnet fra SAF-T-filen krever ikke ny kontroll etter at avvikslisten er gjennomgått og eventuell omklassifisering er gjennomført. Datapunktene som kommer fra spørsmål som kunden har svart på, krever alltid kontroll. Kontroller da mot tilhørende dokumentasjon; at tallene stemmer, og at datoen(e) til dokumentasjonen tilhører målingsperioden.",
  },
  datapointCorrection: {
    question: "Korrigering av datapunktets verdi",
    answer:
      "Dersom man oppdager i kontrollen at et datapunkt har feil verdi, skal dette korrigeres. Da skriver man inn ny verdi i feltet. Hvis kunden har skrevet noe selv i svar på spørsmål, men ikke har ordentlig dokumentasjon på det, skal datapunktet settes tomt.\n\n Husk at dersom man endrer verdien til et datapunkt, vil også verdien til datapunktet endre seg på de andre indikatorene som har dette datapunktet. For eksempel hvis man endrer verdien til datapunktet arealEiet på indikatoren Energieffektivitet areal, vil også dette påvirke resultatet til indikatorene Vanneffektivitet areal og Avfallsproduksjon areal.\n\n I loggen vises endringer som er gjort (men per dags dato vises ikke hva man endret fra, bare hva man endret til, sørg derfor for å notere deg et sted hva som var den opprinnelige verdien.)",
  },
  nullNoValue: {
    question: "Forskjellen på 0 og ingen verdi",
    answer:
      'Det er viktig å merke seg forskjellen på 0 og ingen verdi. **0 betyr at verdien er 0, mens et tomt datapunktfelt betyr ingen verdi.**\n\n Det vil si, dersom virksomheten ikke har data og dokumentasjon på noe, skal datapunktet være tomt (ingen verdi). Dersom de har data på det, og dataen er 0, skal man skrive inn 0.\n\n *Eksempel:* Indikatoren Avfallsproduksjon relatert til omsetning: Virksomheten leier lokaler, men de har ikke kontroll på hvor mye avfall de har generert. Da mangler virksomheten data og dokumentasjon til datapunktet *kgAvfallPeriodeLeiet*. Det skal derfor ikke fylles inn "0" i datapunktet *kgAvfallPeriodeLeiet*. Datapunktet skal være tomt. Dersom virksomheten faktisk ikke hadde noe avfall i perioden, og dette er dokumentert, kan man sette verdien "0". Siden virksomheten hverken eier eller leier ut areal, kan det fylles inn "0" på datapunktene *kgAvfallPeriodeEiet* og *kgAvfallPeriodeUtleid* (siden vi vet at dette er "0"). Da blir formelen korrekt utregnet.\n\n Konsekvensen av å fylle inn "0" når datapunktet ikke skal ha verdi/være tomt, er at en virksomhet får feil resultat. Fyller man inn "0" når datapunktet ikke skal ha en verdi, kan en virksomhet få "meget god" i score, i stedet for "meget svak". Dette påvirker også scoren til indikatorene Miljøkontroll og Sosialt avtrykk.  ',
  },
  criticalErrorSources: {
    question: "Kritiske feilkilder",
    answer:
      "Ved kritiske feilkilder kan ikke målingen gjennomføres. Eksempler på kritiske feilkilder er feil næringskode og for mye feil i SAF-T filen.",
  },
  otherErrorSources: {
    question: "Andre feilkilder",
    answer:
      'Andre feilkilder kan være feil dato på dokumentasjonen, feil eller mangelfull dokumentasjon. Da skal man be kunden om korrigert underlag eller stille kunden tilleggsspørsmål. Dette gjøres under "Datagrunnlag" og "Be om korrigert underlag". En annen feilkilde er feil i A07. ',
  },
  indicatorResult: {
    question: "Resultatet på indikatorene",
    answer:
      'Resultat på indikatorene er enten i form av en utregning eller en score. Utregningene henter sine tallverdier fra datapunktene og utregnes etter en formel. En score settes ut i fra svar på spørsmål, og følger forhåndsdefinerte regler. En score skal aldri settes lavere enn 0, eller høyere enn 100. Fargen følger "trafikklys-systemet", og indikerer om tall-resultatet er meget svak (rød), svak (oransje), tilfredsstillende (gul), god (grønn) eller meget god (mørke grønn).',
  },
  companyBeingRated: {
    question: "Virksomheten som måles",
    answer:
      "En bærekraftmåling og -rapport følger organisasjonsnummeret. Det vil si, dersom virksomheten er en del av et konsern vil ikke tiltak som mor- eller datterselskap ha gjort, inngå i resultatet til virksomheten som måles. Per dags dato har systemet ingen modul for konsolidert bærekraftmåling og -rapport. ",
  },
}
