/** Given an array of objects with an `id` property, returns an object mapping ids to objects. */
export function byId<I extends string, T extends { id: I }>(list: Array<T>): Record<T["id"], T> {
  return list.reduce(
    (obj, item) => {
      obj[item.id] = item
      return obj
    },
    {} as Record<T["id"], T>,
  )
}
