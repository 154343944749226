export enum UploadScopeEnum {
  A07 = "A07",
  AldersfordeltSaldoliste = "aldersfordelt-saldoliste",
  Saldobalanse = "saldobalanse",
  SAFT = "saft",
}

type QuestionUploadScope = `questions/${string}`
export type UploadScope = UploadScopeEnum | QuestionUploadScope

export enum RatingFileCategory {
  saft = "saft",
  a07 = "a07",
  generic = "generic",
}
