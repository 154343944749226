import { Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react"
import { BusinessArea } from "@esgt/types"
import { Can } from "components/Can"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { useMethodsQuery } from "lib/generated/graphql"
import { Link, Navigate, Route, Routes } from "react-router-dom"
import { MethodEditor } from "./MethodEditor"

export const Method = () => {
  useBreadcrumb("Method", "/admin/method")

  const [query] = useMethodsQuery({
    variables: { businessArea: BusinessArea.SMBS },
  })

  return (
    <Can permission="system:write" entityId="*" noAccessComponent={<Navigate to="/" replace={true} />}>
      <Menu>
        <MenuButton>Velg metode</MenuButton>
        <MenuList>
          {query.data?.methods.map((method) => (
            <MenuItem key={method.id} as={Link} to={`${method.id}`}>
              <Text>{method.version}</Text>
              <Text>{method.createdAt}</Text>
              <Text>
                {method.description.length < 20 ? method.description : `${method.description.substring(0, 17)}...`}
              </Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <Routes>
        <Route path=":id/*" element={<MethodEditor />} />
      </Routes>
    </Can>
  )
}
