export enum UnauthorizedReason {
  LOGGED_IN_NO_ACCESS = "LOGGED_IN_NO_ACCESS",
  LOGIN_TOKEN_EXPIRED = "LOGIN_TOKEN_EXPIRED",
  UNKNOWN = "UNKNOWN",
  NOT_LOGGED_IN = "NOT_LOGGED_IN",
  INSUFFICIENT_ACCESS = "INSUFFICIENT_ACCESS",
}

export const unauthorizedFeedback: Record<UnauthorizedReason, string> = {
  LOGGED_IN_NO_ACCESS: "Du har ikke tilgang. Dette kan f.eks. skyldes at din konto er deaktivert.",
  LOGIN_TOKEN_EXPIRED: "Lenken du prøvde å logge inn med har utløpt. Vennligst be om en ny.",
  UNKNOWN: "En ukjent feil oppsto.",
  NOT_LOGGED_IN: "Du må være logget inn for å se denne siden.",
  INSUFFICIENT_ACCESS: "Du har ikke tilgang til denne siden.",
}
