import * as math from "mathjs"

export function isInRange(num: number, from: number, to: number) {
  return num >= from && num <= to
}

export function hasValue(value: unknown) {
  return typeof value !== "undefined"
}

export function hasTruthyValue(value: unknown) {
  return typeof value !== "undefined" && Boolean(value)
}

export function anyHasValue(...values: unknown[]) {
  return values.some(hasValue)
}

export function zeroIfUndefined(value: unknown) {
  return typeof value !== "undefined" ? value : 0
}

export function infinityIfUndefined(value: unknown) {
  return typeof value !== "undefined" ? value : Number.POSITIVE_INFINITY
}

export function makeMathParser() {
  const parser = math.parser()

  // Custom functions
  // NOTE: Test all custom functions well, _with the parser_! MathJS is cool, but even a
  //   valid JS function won't always work, and might simply give incorrect results, with
  //   its parser due to how it represents things internally. Slight changes to _how_ the
  //   function does its job can make or break it.
  //   To avoid subtle bugs, test any additions well.
  parser.set("isInRange", isInRange)
  parser.set("hasValue", hasValue)
  parser.set("hasTruthyValue", hasTruthyValue)
  parser.set("anyHasValue", anyHasValue)
  parser.set("zeroIfUndefined", zeroIfUndefined)
  parser.set("infinityIfUndefined", infinityIfUndefined)

  return parser
}
