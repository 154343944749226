import { Flex } from "@chakra-ui/react"
import { CenteredContent } from "components/CenteredContent"
import { RatingLoading } from "components/RatingLoading"
import { RatingStatus } from "components/RatingStatus"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { useCurrentUser } from "lib/providers/CurrentUser"
import { RatingProvider, useRating } from "lib/providers/RatingProvider"
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom"
import { Data } from "./Data"
import { Delivery } from "./Delivery"
import { Header } from "./Header"
import { KPI } from "./KPI"
import { Logs } from "./Logs"
import { Navigation } from "./Navigation"
import { RatingSettings } from "./RatingSettings"

export const Rating = () => {
  const { user } = useCurrentUser()
  const { id } = useParams()

  if (!user || !id) return null

  return (
    <RatingProvider ratingId={id}>
      <RatingLoading>
        <RatingBreadcrumb ratingId={id} />
        <CenteredContent scrollable>
          <Flex position="relative" flex="1" flexDirection="column">
            <RatingStatus />
            <Header />
            <Routes>
              <Route path="settings/*" element={<RatingSettings />} />
              <Route path="logs/*" element={<Logs />} />
              <Route
                element={
                  <>
                    <Navigation />
                    <Outlet />
                  </>
                }
              >
                <Route index element={<Navigate to="data" replace={true} />} />
                <Route path="data/*" element={<Data />} />
                <Route path="indicators/*" element={<KPI />} />
                <Route path="comment/*" element={<Delivery />} />
              </Route>
            </Routes>
          </Flex>
        </CenteredContent>
      </RatingLoading>
    </RatingProvider>
  )
}

// In component because it must be below the RatingProvider and should be above the subroutes' components
const RatingBreadcrumb = ({ ratingId }: { ratingId: string }) => {
  const { ratingState } = useRating()
  useBreadcrumb(ratingState?.brreg?.navn || "", `/ratings/${ratingId}`)

  return null
}
