import { Flex } from "@chakra-ui/react"
import { CenteredContent } from "components/CenteredContent"
import styled from "styled-components"
import { AccountSwitcher } from "./AccountSwitcher"
import { Can } from "./Can"
import { HelpDrawer } from "./HelpDrawer/HelpDrawer"
import { Breadcrumbs } from "./breadcrumbs/Breadcrumbs"

const Header = styled.header`
	background: white;
	border-bottom: 1px solid var(--chakra-colors-blackAlpha-200);
`

const Wrapper = styled.div`
	height: 48px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
`

const SecondaryHeader = () => {
  return (
    <>
      <Header>
        <CenteredContent flex={false}>
          <Wrapper>
            <Breadcrumbs />
            <Flex align="center" gap="48px">
              <Can permission="system:write" entityId="*">
                <AccountSwitcher />
              </Can>
              <HelpDrawer />
            </Flex>
          </Wrapper>
        </CenteredContent>
      </Header>
    </>
  )
}

export { SecondaryHeader }
