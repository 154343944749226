import { SettingsIcon } from "@chakra-ui/icons"
import { Flex, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from "@chakra-ui/react"
import { doesUserHavePermission } from "@esgt/auth"
import RatingsIcon from "assets/icons/new/meters.svg"
import UserIcon from "assets/icons/new/profile.svg"
import ProfilesIcon from "assets/icons/new/ratingprofiles-menu-icon.svg"
import AccountsIcon from "assets/icons/new/users.svg"
import { LogoStacked } from "components/Logo"
import { useAuth } from "lib/providers/AuthProvider"
import { useCurrentUser } from "lib/providers/CurrentUser"
import { routes } from "lib/routes"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { Can } from "./Can"

const NavbarContainer = styled.div<{ isHidden?: boolean }>`
	background-color: white;
	display: flex;
	align-items: center;
	flex: 0 0 110px;
	flex-flow: column nowrap;
	border-right: 1px solid var(--chakra-colors-blackAlpha-200);
	padding: var(--chakra-space-5) 0;
`

const NavIcon = styled.span`
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
	width: 32px;
	height: 32px;
	opacity: 0.6;

	&.ratings-icon {
		background-image: url("${RatingsIcon}");
	}

	&.profiles-icon {
		background-image: url("${ProfilesIcon}");
	}

	&.accounts-icon {
		background-image: url("${AccountsIcon}");
	}

	&.user-icon {
		background-image: url("${UserIcon}");
	}
`

const Nav = styled.nav`
	align-self: center;
	display: flex;
	flex: 1;
	flex-flow: column nowrap;
	grid-gap: 32px;

	a {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding: 8px;
	}

	a:hover,
	a.active {
		background-color: rgba(0, 0, 0, 0.05);
		border-radius: 4px;

		span {
			opacity: 0.8;
		}
	}
`

const LogOut = styled.div`
	flex: 1;
	display: flex;
	justify-content: end;
	flex-direction: column;

	button {
		align-self: start;
		min-width: 0;
	}
`

export const Navbar = () => {
  const { user, account } = useCurrentUser()
  const { signout } = useAuth()

  return (
    <NavbarContainer>
      <NavLink to="/">
        <LogoStacked />
      </NavLink>
      <Nav>
        <Can permission="account:rating:create" entityId={String(account?.id)}>
          <Tooltip hasArrow label="Målinger" placement="right" fontSize="md">
            <NavLink to={routes.ratings()}>
              <NavIcon className="ratings-icon" />
            </NavLink>
          </Tooltip>
        </Can>
        <Can permission="system:write" entityId="*">
          <Tooltip hasArrow label="Målingsprofiler" placement="right" fontSize="md">
            <NavLink to={routes.admin("rating-profiles")}>
              <NavIcon className="profiles-icon" />
            </NavLink>
          </Tooltip>
        </Can>
        <Can permission="system:write" entityId="*">
          <Tooltip hasArrow label="Kontoer" placement="right" fontSize="md">
            <NavLink to={routes.admin("accounts")}>
              <NavIcon className="accounts-icon" />
            </NavLink>
          </Tooltip>
        </Can>
        {!doesUserHavePermission(user, "system:write", "*") && account && (
          <Can permission="account:update" entityId={String(account.id)}>
            <Tooltip hasArrow label="Brukeradministrasjon" placement="right" fontSize="md">
              <NavLink to={routes.admin(`accounts/${account.id}`)}>
                <NavIcon className="accounts-icon" />
              </NavLink>
            </Tooltip>
          </Can>
        )}

        <Can permission="system:write" entityId="*">
          <Tooltip hasArrow label="Administrer metode" placement="right" fontSize="md">
            <NavLink to={routes.admin("method")}>
              <NavIcon>
                <SettingsIcon width="32px" height="32px" />
              </NavIcon>
            </NavLink>
          </Tooltip>
        </Can>
      </Nav>
      <LogOut>
        <Menu placement="right">
          <MenuButton>
            <NavIcon className="user-icon" />
          </MenuButton>
          <MenuList>
            <MenuItem as={Flex} gap={2} pointerEvents="none">
              <Text color="gray.500">Logget inn som</Text>
              <Text>{user.email}</Text>
            </MenuItem>
            <MenuItem onClick={signout}>Logg ut</MenuItem>
          </MenuList>
        </Menu>
      </LogOut>
    </NavbarContainer>
  )
}
