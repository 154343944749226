import { Box, Button, Flex, Heading, Image, ListItem, OrderedList, Text } from "@chakra-ui/react"
import Onboarding0 from "assets/images/onboarding-0.svg"
import Onboarding1 from "assets/images/onboarding-1.png"
import Onboarding2 from "assets/images/onboarding-2.png"
import Onboarding3 from "assets/images/onboarding-3.png"
import { routes } from "lib/routes"
import { useState } from "react"
import { Link } from "react-router-dom"

type Slides = {
  image: string
  heading: string
  content: JSX.Element
}[]

const slides: Slides = [
  {
    image: Onboarding0,
    heading: "Velkommen til ESG Trackr",
    content: (
      <>
        <Text size="lg">
          Her beskriver vi hvordan du bruker plattformen for å lage en bærekraftsrapport i 3 enkle steg.
        </Text>
        <Text size="lg" mt="4">
          God rapportering!
        </Text>
      </>
    ),
  },
  {
    image: Onboarding1,
    heading: "1. Opprett måling",
    content: (
      <>
        <Text mb="4" size="lg">
          Først oppretter man en bærekraftsmåling ved å fylle ut:
        </Text>
        <OrderedList spacing="2" fontSize="lg">
          <ListItem>Virksomhet som skal bærekraftmåles</ListItem>
          <ListItem>Kontaktperson</ListItem>
          <ListItem>Periode</ListItem>
          <ListItem>Målingsprofil (denne blir satt automatisk)</ListItem>
        </OrderedList>
      </>
    ),
  },
  {
    image: Onboarding2,
    heading: "2. Legge inn data",
    content: (
      <>
        <Text mb="4" size="lg">
          Fra ‘Mine målinger’ klikk på ‘Gå til måling’ for å fylle ut:
        </Text>
        <OrderedList spacing="2" fontSize="lg">
          <ListItem>Økonomisk data</ListItem>
          <ListItem>Lønnsinformasjon</ListItem>
          <ListItem>Spørsmål om sosiale og miljømessige forhold</ListItem>
        </OrderedList>
      </>
    ),
  },
  {
    image: Onboarding3,
    heading: "3. Last ned rapport",
    content: (
      <>
        <Text mb="4" size="lg">
          Når all data er lagt inn, blir rapport tilgjengelig for nedlasting.
        </Text>
        <OrderedList spacing="2" fontSize="lg">
          <ListItem>Klikk på ‘Rapport’ fane</ListItem>
          <ListItem>Velg knappen ‘Last ned rapport’</ListItem>
          <ListItem>Om du ønsker en verifisert bærekraftsrapport, velg knappen ‘Be om tredjepartskontroll’.</ListItem>
        </OrderedList>
      </>
    ),
  },
]

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const nextSlide = () => {
    setCurrentSlide((prev) => {
      return prev + 1
    })
  }

  const prevSlide = () => {
    setCurrentSlide((prev) => prev - 1)
  }

  return (
    <Box
      position="relative"
      maxW="1200px"
      m="72px auto"
      bg="white"
      borderRadius="8px"
      border="1px solid var(--chakra-colors-neutral-100);"
      p="72px 64px"
    >
      <Flex gap="16" w="100%">
        <Image src={slides[currentSlide].image} boxSize="450px" objectFit="contain" flex="1" />
        <Flex direction="column" flex="1">
          <Heading size="lg" mb="4">
            {slides[currentSlide].heading}
          </Heading>
          <Box flex="1">{slides[currentSlide].content}</Box>
          <Flex mt="4" gap="4">
            {currentSlide === 0 ? (
              <Link to={routes.myRatings("onboarding")}>
                <Button variant="outline">Hopp over</Button>
              </Link>
            ) : (
              <Button variant="outline" onClick={prevSlide}>
                Tilbake
              </Button>
            )}

            {currentSlide === slides.length - 1 ? (
              <Link to={routes.myRatings("onboarding")}>
                <Button colorScheme="blue">Ferdig</Button>
              </Link>
            ) : (
              <Button colorScheme="blue" onClick={nextSlide}>
                Neste
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex w="100%" justifyContent="center" pos="absolute" bottom="-40px" left="0" mt="4">
        {slides.map((_, index) => (
          <Box
            key={index}
            onClick={() => setCurrentSlide(index)}
            backgroundColor={index === currentSlide ? "neutral.500" : "neutral.200"}
            borderRadius="full"
            h="4"
            w="4"
            mx="1"
            cursor="pointer"
          />
        ))}
      </Flex>
    </Box>
  )
}

export default Carousel
