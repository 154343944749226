import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react"
import { useHelpPopover } from "components/HelpPopover"
import { HelpPopoverIcon } from "components/Icons/HelpPopoverIcon"
import { useAuth } from "lib/providers/AuthProvider"
import { useRating } from "lib/providers/RatingProvider"
import { sendMsg } from "lib/sendMsg"
import { useEffect } from "react"
import { useForm } from "react-hook-form"

type UpgradeFormValues = {
  msg: string
}

type GeneralFormValues = {
  msg: string
}

export const HelpPopover = () => {
  const { token } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { helpPopoverState, setHelpPopoverState } = useHelpPopover()
  const { ratingId } = useRating()

  const {
    handleSubmit: handleSubmitUpgradeForm,
    register: registerUpgradeForm,
    reset: resetUpgradeForm,
  } = useForm<UpgradeFormValues>({
    defaultValues: {},
  })

  const {
    handleSubmit: handleSubmitGeneralForm,
    register: registerGeneralForm,
    reset: resetGeneralForm,
  } = useForm<GeneralFormValues>({
    defaultValues: {},
  })

  // Handlers
  const handleOpenUpgradeForm = () => setHelpPopoverState("upgradeForm")
  const handleOpenGeneralForm = () => setHelpPopoverState("generalForm")

  const sendUpgradeMsg = async ({ msg }: UpgradeFormValues) => {
    await sendMsg({ token, ratingId, msg: msg, subject: "ønsker verifisert rapport" })
    resetUpgradeForm()
    setHelpPopoverState("success")
  }

  const sendGeneralMsg = async ({ msg }: GeneralFormValues) => {
    await sendMsg({ token, ratingId, msg: msg, subject: "ønsker veiledning" })
    resetGeneralForm()
    setHelpPopoverState("success")
  }

  const handleClose = () => {
    onClose()
    setHelpPopoverState("initial")
  }

  useEffect(() => {
    if (["generalForm", "upgradeForm"].includes(helpPopoverState)) {
      onOpen()
    }
  }, [helpPopoverState, onOpen])

  return (
    <Box pos="fixed" bottom="4" right="4" zIndex="9999">
      <Popover isOpen={isOpen} onClose={handleClose} closeOnBlur={false}>
        <PopoverTrigger>
          <Box display="inline-block" onClick={onOpen} cursor="pointer">
            <HelpPopoverIcon />
          </Box>
        </PopoverTrigger>
        <PopoverContent w="420px" right="8">
          <PopoverCloseButton top="2" />

          <PopoverBody p="8" boxShadow="lg">
            {helpPopoverState === "initial" && (
              <Stack spacing={0}>
                <Heading size="md" mb="2">
                  Trenger du verifisert rapport?
                </Heading>
                <Text fontSize="md">Ønsker du en fullstendig, kontrollert og verifisert bærekraftsrapport?</Text>
                <Button colorScheme="blue" mt="4" onClick={handleOpenUpgradeForm}>
                  Kjøp full rapport
                </Button>
                <Divider mb="4" mt="6" />
                <Heading size="md" mb="2">
                  Trenger du veiledning?
                </Heading>
                <Text fontSize="md">En av våre konsulenter kan ta kontakt med deg</Text>
                <Button variant="outline" mt="4" onClick={handleOpenGeneralForm}>
                  Ta kontakt med oss
                </Button>
              </Stack>
            )}
            {helpPopoverState === "upgradeForm" && (
              <>
                <Heading size="md" mb="2">
                  Trenger du verifisert rapport?
                </Heading>
                <Text fontSize="md" mb="4">
                  Skriv et par setninger om deres behov, så tar en av våre kontrollører kontakt med deg.
                </Text>

                <Stack spacing={4} as="form" onSubmit={handleSubmitUpgradeForm(sendUpgradeMsg)}>
                  <FormControl>
                    <Textarea id="messageUpgrade" {...registerUpgradeForm("msg", { required: true })} />
                    <FormLabel htmlFor="messageUpgrade">Melding til oss</FormLabel>
                  </FormControl>
                  <Button type="submit" colorScheme="blue">
                    Send hendvendelse
                  </Button>
                </Stack>
              </>
            )}
            {helpPopoverState === "generalForm" && (
              <>
                <Heading size="md" mb="2">
                  Trenger du veiledning?
                </Heading>
                <Text fontSize="md" mb="4">
                  Skriv et par setninger om deres behov, så tar en av våre kontrollører kontakt med deg.
                </Text>

                <Stack spacing={4} as="form" onSubmit={handleSubmitGeneralForm(sendGeneralMsg)}>
                  <FormControl>
                    <Textarea id="messageGeneral" {...registerGeneralForm("msg", { required: true })} />
                    <FormLabel htmlFor="messageGeneral">Melding til oss</FormLabel>
                  </FormControl>
                  <Button type="submit" colorScheme="blue">
                    Send hendvendelse
                  </Button>
                </Stack>
              </>
            )}
            {helpPopoverState === "success" && (
              <Stack spacing={0}>
                <Heading size="md" mb="2">
                  Takk for din hendvendelse!
                </Heading>
                <Text fontSize="md" mb="4">
                  En rådgiver fra vårt team vil ta kontakt med deg innen 48 timer. Det er verdt å merke seg at
                  responstiden dessverre kan være noe lenger i helgene.
                </Text>
                <Alert
                  status="success"
                  bg="none"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <AlertIcon boxSize="40px" mr={0} />
                  <AlertTitle mt={4} mb={1} fontSize="lg">
                    Din melding er sendt!
                  </AlertTitle>
                </Alert>
                <Button variant="outline" mt="4" onClick={handleClose}>
                  Lukk
                </Button>
              </Stack>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
