import { Box, Button, Center, Heading, Text } from "@chakra-ui/react"
import { CenteredContent } from "components/CenteredContent"
import { ContentBox } from "components/ContentBox"
import { Logo } from "components/Logo"
import { config } from "lib/helpers/config"
import { routes } from "lib/routes"
import { useEffect } from "react"
import ReactGA from "react-ga4"
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"

export interface SignupDetails {
  name: string
  email: string
}

export const SignupWelcome: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const signupDetails = location.state as SignupDetails

  useEffect(() => {
    if (!signupDetails) {
      navigate("/", { replace: true })
    }
    if (config.GOOGLE_ANALYTICS_SELF_SERVICE_ENABLED && config.GOOGLE_ANALYTICS_SELF_SERVICE_ID !== "") {
      ReactGA.initialize(config.GOOGLE_ANALYTICS_SELF_SERVICE_ID)
    }
  }, [signupDetails, config])

  if (!signupDetails) {
    return null
  }

  return (
    <>
      <CenteredContent maxWidth="712px" alignCenter>
        <Center>
          <Logo />
        </Center>
        <ContentBox padding="64px 72px 42px">
          <Heading size="h2" mb={6}>
            Velkommen til ESG Trackr!
          </Heading>

          <Box mb="24px">
            <Text mb="16px">Kjære {signupDetails.name}</Text>
            <Text mb="16px">Vi har sendt en e-post til deg.</Text>
            <Text mb="16px">
              Vi setter stor pris på at du har vist interesse for bærekraft og opprettet en konto hos oss.
            </Text>
            <Text mb="16px">
              For å logge inn trykker du på knappen nederst, og logger inn med din e-postadresse{" "}
              <strong>{signupDetails.email}</strong>.
            </Text>
          </Box>

          <Button as={RouterLink} to={routes.login()} colorScheme="green" w="200px" alignSelf="center">
            Logg inn
          </Button>
        </ContentBox>
      </CenteredContent>
    </>
  )
}
