import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import styled from "styled-components"

const FillScreen = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const Authenticate: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const nextUrl = searchParams.get("nextUrl") || "/"

    navigate(nextUrl)
  }, [])

  return <FillScreen>Logger deg inn, vennligst vent...</FillScreen>
}
