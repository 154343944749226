interface DocumentIconProps {
  size: number
}

export const DocumentIcon = ({ size }: DocumentIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Document</title>
      <path
        d="M19.8937 7.85631L14.6437 2.60631C14.5937 2.55208 14.5327 2.50903 14.4649 2.47995C14.397 2.45087 14.3238 2.43643 14.25 2.43756H5.25C4.9019 2.43756 4.56806 2.57584 4.32192 2.82198C4.07578 3.06813 3.9375 3.40197 3.9375 3.75006V20.2501C3.9375 20.5982 4.07578 20.932 4.32192 21.1781C4.56806 21.4243 4.9019 21.5626 5.25 21.5626H18.75C19.0981 21.5626 19.4319 21.4243 19.6781 21.1781C19.9242 20.932 20.0625 20.5982 20.0625 20.2501V8.25006C20.0636 8.17626 20.0492 8.10304 20.0201 8.03519C19.991 7.96734 19.948 7.90639 19.8937 7.85631ZM14.8125 4.35944L18.1406 7.68756H14.8125V4.35944ZM18.75 20.4376H5.25C5.20027 20.4376 5.15258 20.4178 5.11742 20.3826C5.08225 20.3475 5.0625 20.2998 5.0625 20.2501V3.75006C5.0625 3.70033 5.08225 3.65264 5.11742 3.61748C5.15258 3.58232 5.20027 3.56256 5.25 3.56256H13.6875V8.25006C13.6875 8.39925 13.7468 8.54232 13.8523 8.64781C13.9577 8.7533 14.1008 8.81256 14.25 8.81256H18.9375V20.2501C18.9375 20.2998 18.9177 20.3475 18.8826 20.3826C18.8474 20.4178 18.7997 20.4376 18.75 20.4376ZM15.5625 12.7501C15.5625 12.8992 15.5032 13.0423 15.3977 13.1478C15.2923 13.2533 15.1492 13.3126 15 13.3126H9C8.85082 13.3126 8.70774 13.2533 8.60225 13.1478C8.49676 13.0423 8.4375 12.8992 8.4375 12.7501C8.4375 12.6009 8.49676 12.4578 8.60225 12.3523C8.70774 12.2468 8.85082 12.1876 9 12.1876H15C15.1492 12.1876 15.2923 12.2468 15.3977 12.3523C15.5032 12.4578 15.5625 12.6009 15.5625 12.7501ZM15.5625 15.7501C15.5625 15.8992 15.5032 16.0423 15.3977 16.1478C15.2923 16.2533 15.1492 16.3126 15 16.3126H9C8.85082 16.3126 8.70774 16.2533 8.60225 16.1478C8.49676 16.0423 8.4375 15.8992 8.4375 15.7501C8.4375 15.6009 8.49676 15.4578 8.60225 15.3523C8.70774 15.2468 8.85082 15.1876 9 15.1876H15C15.1492 15.1876 15.2923 15.2468 15.3977 15.3523C15.5032 15.4578 15.5625 15.6009 15.5625 15.7501Z"
        fill="currentColor"
      />
    </svg>
  )
}
