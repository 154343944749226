import { Box, Button, FormControl, Textarea } from "@chakra-ui/react"
import { InfoHeadingPanel } from "components/InfoHeadingPanel"
import { useEditRatingCommentMutation } from "lib/generated/graphql"
import { isRatingEditable } from "lib/helpers/rating"
import { useRating } from "lib/providers/RatingProvider"
import { useForm } from "react-hook-form"
import styled from "styled-components"

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	overflow: hidden;
`

export const EditComment: React.FC = () => {
  const { ratingState, ratingId } = useRating()
  const [editStatus, editComment] = useEditRatingCommentMutation()

  if (!ratingState) return null

  const defaultValues = {
    comment: ratingState.ratingComment,
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { register, handleSubmit } = useForm({
    defaultValues,
  })

  const save = (values: any) => {
    const { comment } = values
    editComment({ ratingId, comment })
  }

  return (
    <Container>
      <Box mb={4}>
        <InfoHeadingPanel size="md" helpText="helpTexts.comment">
          Kommentar
        </InfoHeadingPanel>
      </Box>

      <Box w="50%">
        <form onSubmit={handleSubmit(save)}>
          <FormControl mb={4}>
            <Textarea
              size={"lg"}
              rows={10}
              required
              id="name"
              placeholder="Kommentar"
              isDisabled={ratingState.phase === "COMPLETED"}
              {...register("comment")}
            />
          </FormControl>
          {isRatingEditable(ratingState) && (
            <Button variant="outline" size="lg" mr={3} type="submit" isLoading={editStatus.fetching}>
              Lagre
            </Button>
          )}
        </form>
      </Box>
    </Container>
  )
}
