import { Button, Center, Flex, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { isRatingEditable } from "lib/helpers/rating"
import { useRating } from "lib/providers/RatingProvider"
import { MdCheckBox, MdCheckBoxOutlineBlank, MdDelete } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

const Container = styled.div`
	flex: 1;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	gap: 32px;
`
export const AdditionalQuestions: React.FC = () => {
  const navigate = useNavigate()
  const { ratingState } = useRating()

  return (
    <Container>
      {isRatingEditable(ratingState) && (
        <Flex justifyContent={"flex-end"}>
          <Button isDisabled={ratingState?.phase === "ABORTED"} onClick={() => navigate("nytt-spørsmål")}>
            Nytt tilleggsspørsmål
          </Button>
        </Flex>
      )}
      {ratingState?.additionalQuestions.length ? (
        <Table>
          <Thead>
            <Tr>
              <Th minWidth="300px">Spørsmål</Th>
              <Th>Tekstsvar</Th>
              <Th>Filopplasting</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {ratingState?.additionalQuestions.map((q) => (
              <Tr key={q.id}>
                <Td>
                  <VStack alignItems={"flex-start"}>
                    <Text>{q.text}</Text>
                    <Text>{q.helpText}</Text>
                  </VStack>
                </Td>
                <Td>
                  <Icon as={q.textInput ? MdCheckBox : MdCheckBoxOutlineBlank} color="gray.400" boxSize="21px" />
                </Td>
                <Td>
                  <Icon as={q.uploads ? MdCheckBox : MdCheckBoxOutlineBlank} color="gray.400" boxSize="21px" />
                </Td>
                <Td>
                  <IconButton
                    size="sm"
                    icon={<Icon as={MdDelete} color="red.400" boxSize="21px" cursor={"pointer"} />}
                    aria-label="Slett"
                    onClick={() => navigate(`slett-spørsmål/${q.id}`)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Center bg="#fafafa" padding={24} paddingY={24} color="gray.600" fontWeight={500}>
          Ingen tilleggsspørsmål registrert
        </Center>
      )}
    </Container>
  )
}
