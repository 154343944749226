export type DataPointSource = "SAF-T" | "A07" | "Aldersfordelt saldoliste"

export const DataPointSources: Record<string, DataPointSource> = {
  driftsinntekterUB: "SAF-T",
  driftskostnaderUB: "SAF-T",
  avskrivningerUB: "SAF-T",
  nedskrivningerUB: "SAF-T",
  finansinntekterUB: "SAF-T",
  finanskostnaderUB: "SAF-T",
  egenkapitalUB: "SAF-T",
  eiendelerUB: "SAF-T",
  utsattSkattUB: "SAF-T",
  skattEkstraordinærIKUB: "SAF-T",
  omløpsmidlerUB: "SAF-T",
  kortsiktigGjeldUB: "SAF-T",
  varelagerUB: "SAF-T",
  gjeldUB: "SAF-T",
  anleggsmidlerUB: "SAF-T",
  langsiktigGjeldUB: "SAF-T",
  gjsnittÅrsverk: "A07",
  opExUB: "SAF-T",
  capExUB: "SAF-T",
  månedsverkKvinner: "A07",
  månedsverkMenn: "A07",
  medianlonnKvinner: "A07",
  medianlonnMenn: "A07",
  gjennomsnittslonnKvinneligeAnsatte: "A07",
  gjennomsnittslonnMannligeAnsatte: "A07",
  ansatte: "A07",
  månedsverkU30: "A07",
  månedsverk30til50: "A07",
  månedsverkO50: "A07",
  månedsverkKvinneligeLedere: "A07",
  månedsverkMannligeLedere: "A07",
  medianÅrslønner: "A07",
  sumÅrslønner: "A07",
  årslønnerLavest: "A07",
  årslønnerHøyest: "A07",
  timesverk: "A07",
  timesverkLærlinger: "A07",
  fagforeningstrekk: "A07",
  beløpKursAnsatte: "SAF-T",
  gjsnittAnsatte: "A07",
  gjsnittAnsatteULærlinger: "A07",
  ansatteSluttet: "A07",
  leverandørGjeldUB: "SAF-T",
  leverandørGjeld30: "Aldersfordelt saldoliste",
}
