import { Link } from "@chakra-ui/react"
import ReactMarkdown from "react-markdown"

export const MarkdownText: React.FC<{ markdown?: string }> = ({ markdown }) => {
  return (
    <ReactMarkdown
      linkTarget="_blank"
      components={{
        a: ({ node: _, ...props }) => (
          <>
            <Link color="blue.500" isExternal {...props} />
          </>
        ),
        p: ({ node: _, ...props }) => {
          // Adds support for sup, e.g.: ^10^ and links it to references, used in report pdf generation
          // Not the most beautiful piece of code but.. ¯\_(ツ)_/¯
          const referencePattern = new RegExp(/\^(.*?)\^/)

          if (typeof props.children[0] === "string" && referencePattern.test(props.children[0])) {
            const substrings = props.children[0].split(referencePattern)

            const joined = substrings.map((substring, index) => {
              // odd-numbered indexes are the split target strings: "a^1^, b c^2^" -> ["a", "1", ", b c", "2"], etc
              if (index % 2 === 1) {
                // handle multiple references from the same place: ^1,2,3^
                const firstReference = substring.split(",")[0].toString().trim()

                return (
                  <sup key={index}>
                    <a href={`#reference-${firstReference}`}>{substring}</a>
                  </sup>
                )
              }
              return <span key={index}>{substring}</span>
            })

            return <p>{joined}</p>
          }

          return <p {...props} />
        },
      }}
    >
      {markdown ? markdown : ""}
    </ReactMarkdown>
  )
}
