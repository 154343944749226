import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { useApproveAnomalyMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"

export const ApproveAnomalyModal = () => {
  const { anomalyId } = useParams()
  const { ratingId } = useRating()
  const navigate = useNavigate()

  const {
    formState: { isValid },
    watch,
    handleSubmit,
    register,
  } = useForm({
    mode: "onChange",
  })

  const [mutationStatus, mutate] = useApproveAnomalyMutation()

  const onSubmit = (values: any) => {
    mutate({ ...values, ratingId, anomalyId }).then(close)
  }

  const close = () => navigate("..")

  const allowSubmit = useMemo(() => {
    return isValid
  }, [watch(), isValid])

  return (
    <Modal onClose={close} isOpen={true} size="lg">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Godkjenn avvik</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={3}>Kommentar</Text>
          <Textarea
            {...register("comment", { required: true })}
            rows={3}
            placeholder="Kommentar til hvorfor avviket godkjennes"
            autoFocus={true}
          />
        </ModalBody>
        <ModalFooter gap="4">
          {!mutationStatus.fetching && (
            <Button variant="outline" onClick={close} isLoading={mutationStatus.fetching}>
              Avbryt
            </Button>
          )}
          <Button colorScheme="green" type="submit" isDisabled={!allowSubmit} isLoading={mutationStatus.fetching}>
            Godkjenn avvik
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
