import { SearchIcon } from "@chakra-ui/icons"
import { Button, Heading, Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import { CenteredContent } from "components/CenteredContent"
import { ContentBox } from "components/ContentBox"
import { SiteTitle } from "components/SiteTitle"
import { useAccountsQuery } from "lib/generated/graphql"
import { BiPlus } from "react-icons/bi"
import { Link, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { useDebounce } from "usehooks-ts"
import { OverviewTable } from "./OverviewTable"

const Header = styled.div`
	margin-bottom: 32px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-end;
`
const Container = styled.div`
	margin: 70px 0;
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
`

const SearchAndFilters = styled.div`
	display: flex;
	flex-flow: row nowrap;
	gap: 24px;
`

export function Overview() {
  const [searchParams, setSearchParams] = useSearchParams({})
  const delayedQueryString = useDebounce<string | null>(searchParams.get("q"), 50)
  const [queryResult] = useAccountsQuery({
    variables: { q: delayedQueryString },
  })

  const updateSearchParam = (key: string, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  return (
    <CenteredContent>
      <SiteTitle title={"Kontoer"} />
      <Container>
        <Header>
          <div>
            <Heading as="h1" style={{ fontSize: "24px", marginBottom: "24px" }}>
              Kontoer
            </Heading>
            <SearchAndFilters>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="search"
                  placeholder="Søk i kontoer"
                  width={400}
                  value={searchParams.get("q") || ""}
                  onChange={({ target: { value } }) => updateSearchParam("q", value)}
                  spellCheck={false}
                  bg="white"
                />
              </InputGroup>
            </SearchAndFilters>
          </div>
          <Link to="new">
            <Button colorScheme="green" size="lg" leftIcon={<BiPlus />}>
              Opprett konto
            </Button>
          </Link>
        </Header>
        <ContentBox height="100%">{queryResult.data && <OverviewTable data={queryResult?.data} />}</ContentBox>
      </Container>
    </CenteredContent>
  )
}
