import { Center, Heading, Link, Text } from "@chakra-ui/react"
import { CenteredContent } from "components/CenteredContent"
import { ContentBox } from "components/ContentBox"
import { ErrorMessage } from "components/ErrorMessage"
import { Logo } from "components/Logo"
import { unauthorizedFeedback } from "lib/helpers/unauthorizedFeedback"
import { routes } from "lib/routes"
import { useEffect, useMemo, useState } from "react"
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { EnterOtp } from "./EnterOtp"
import { RequestOtp } from "./RequestOtp"

const Wrapper = styled.div`
	max-width: 500px;
	margin: 2rem auto;
`

enum FeedbackType {
  NONE = "none",
  ERROR = "error",
}

interface Feedback {
  type: FeedbackType
  message: string
}

const feedbackNone = { type: FeedbackType.NONE, message: "" }

export const Login = () => {
  const [loginToken, setLoginToken] = useState<string>()
  const navigate = useNavigate()

  const [feedBack, setFeedback] = useState<Feedback>(feedbackNone)

  const [params] = useSearchParams()
  const reason = useMemo(() => params.get("reason"), [params])
  const nextUrl = params.get("nextUrl") || "/"

  useEffect(() => {
    const reasonFeedback = unauthorizedFeedback[reason]

    if (reasonFeedback) {
      setFeedback({
        type: FeedbackType.ERROR,
        message: reasonFeedback,
      })
    }
  }, [reason])

  const onLogin = () => {
    navigate(nextUrl)
  }

  const onOtpRequested = (token: string) => {
    setLoginToken(token)
    setFeedback(feedbackNone)
  }

  return (
    <CenteredContent maxWidth="560px" alignCenter>
      <Wrapper>
        <Center>
          <Logo />
        </Center>
        <ContentBox>
          <Heading size="md" mb={6}>
            Logg inn
          </Heading>
          {feedBack.type === FeedbackType.ERROR && <ErrorMessage error={feedBack.message} />}
          {loginToken ? (
            <EnterOtp token={loginToken} onLogin={onLogin} onResend={() => setLoginToken(undefined)} />
          ) : (
            <RequestOtp onSubmit={onOtpRequested} nextUrl={nextUrl} />
          )}
        </ContentBox>
        {!loginToken && (
          <Center mt="16px">
            <Text fontSize="md">
              Har du ikke konto?{" "}
              <Link as={RouterLink} to={routes.signup()} variant="underlined">
                Opprett konto her
              </Link>
            </Text>
          </Center>
        )}
      </Wrapper>
    </CenteredContent>
  )
}
