import { ContentBox } from "components/ContentBox"
import { SiteTitle } from "components/SiteTitle"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { useRating } from "lib/providers/RatingProvider"
import styled from "styled-components"
import { EventsTable } from "./EventsTable"

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	margin: 0 0 36px 0;
`
export const Logs = () => {
  const { ratingId, ratingState } = useRating()
  useBreadcrumb("Logg", `/ratings/${ratingId}/log`)

  return (
    <Container>
      <SiteTitle title={`Logg - ${ratingState?.brreg?.navn}`} />
      <ContentBox height={"100%"}>
        <EventsTable />
      </ContentBox>
    </Container>
  )
}
