import { Box, Flex, Heading, Icon } from "@chakra-ui/react"
import { MdCheck } from "react-icons/md"
import { NavLink } from "react-router-dom"

export const SideNav: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => <Box>{children}</Box>

export const SideNavHeading: React.FC<{
  readOnly: boolean
  completed: boolean
  children?: React.ReactNode
}> = ({ children }) => (
  <Flex px="4" mt="6" mb="4" alignItems="center">
    <Heading as="h3" fontSize="14px" textTransform="uppercase" flex="1">
      {children}
    </Heading>
  </Flex>
)

export const SideNavItem: React.FC<{
  to: string
  label: React.ReactNode
  completed?: boolean
  readOnly?: boolean
}> = ({ to, label, completed = false }) => {
  return (
    <Flex
      as={NavLink}
      to={to}
      px="4"
      py="2"
      mb="3"
      borderRadius="4"
      alignItems="center"
      _activeLink={{ backgroundColor: "primary.100" }}
      _hover={{ backgroundColor: "primary.100" }}
    >
      <Box flex={1}>{label}</Box>

      {completed && <Icon boxSize="24px" as={MdCheck} color="green.500" mr={2} />}
    </Flex>
  )
}
