import { HelpPopoverProvider } from "components/HelpPopover"
import { Route, Routes } from "react-router-dom"
import { NewRating } from "./NewRating"
import { Onboarding } from "./Onboarding"
import { Overview } from "./Overview"

export const MyRatings: React.FC = () => {
  return (
    <HelpPopoverProvider>
      <Routes>
        <Route index element={<Overview />} />
        <Route path="new" element={<NewRating />} />
        <Route path="onboarding" element={<Onboarding />} />
      </Routes>
    </HelpPopoverProvider>
  )
}
