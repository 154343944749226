import { Flex, Link, Text } from "@chakra-ui/react"
import { useRating } from "lib/providers/RatingProvider"
import { Feedback } from "./Feedback"

export const RatingAborted: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { ratingState } = useRating()

  if (ratingState && ratingState.phase === "ABORTED") {
    return (
      <Flex direction="column" m="20vh auto">
        <Feedback type="genericError" heading="Deres måling av bærekraft er blitt kansellert." />
        <Text textAlign="center" mt={4}>
          Gi oss beskjed hvis du har spørsmål angående kanselleringen på{" "}
          <Link variant="blue" href="mailto:post@esgtrackr.no">
            post@esgtrackr.no
          </Link>
        </Text>
      </Flex>
    )
  }
  return <>{children}</>
}
