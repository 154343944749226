// "Energimerke" [1] (A-G) + BREEAM [2]
// [1] https://lovdata.no/dokument/SF/forskrift/2009-12-18-1665 (Norwegian)
// [2] https://bregroup.com/products/breeam/
const ENERGY_RATING_TYPES = ["A", "B", "C", "D", "E", "F", "G", "BREEAM"] as const
const ENERGY_RATING_SET = new Set(ENERGY_RATING_TYPES)
type EnergyRating = (typeof ENERGY_RATING_TYPES)[number]

/**
 * @param input: a comma-separated list of energy ratings ("energimerke" or BREEAM), for example "A, BREEAM, C"
 * @returns The average energy rating "score" (0-100) of the provided list
 */
export function processEnergyRatingList(input: string) {
  if (!input) {
    return undefined
  }

  const list = input
    .split(",")
    .map((energyRating) => energyRating.trim().toUpperCase())
    .filter((energyRating) => energyRating.length > 0) as EnergyRating[]

  if (list.some((energyRating) => !isEnergiRating(energyRating))) {
    return undefined
  }

  return averageEnergyRating(list)
}

export function averageEnergyRating(list: EnergyRating[]) {
  const sumOfScores = list.reduce((sum, energyRating) => sum + energyRatingToScore(energyRating), 0)

  return sumOfScores / list.length
}

function isEnergiRating(str: string): str is EnergyRating {
  return ENERGY_RATING_SET.has(str as EnergyRating)
}

function energyRatingToScore(energyRating: EnergyRating) {
  switch (energyRating) {
    case "BREEAM":
      return 100
    case "A":
      return 100
    case "B":
      return 80
    case "C":
      return 60
    case "D":
      return 40
    case "E":
      return 20
    default:
      return 0
  }
}
