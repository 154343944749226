import { CenteredContent } from "components/CenteredContent"
import { Route, Routes } from "react-router-dom"
import { RevisionEditingProvider } from "./RatingEditingContext"
import { RatingProfileHeader } from "./RatingProfileHeader"
import { Revision } from "./Revision"

export const RatingProfile = () => {
  return (
    <RevisionEditingProvider>
      <CenteredContent>
        <RatingProfileHeader />
        <Routes>
          <Route path=":revision/*" element={<Revision />} />
        </Routes>
      </CenteredContent>
    </RevisionEditingProvider>
  )
}
