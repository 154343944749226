import { Flex } from "@chakra-ui/react"
import * as Sentry from "@sentry/react"
import { BaseLayout } from "components/BaseLayout"
import { ErrorBoundary } from "components/ErrorBoundary"
import { Feedback } from "components/Feedback"
import { Navbar } from "components/Navbar"
import { NavbarHorizontal } from "components/NavbarHorizontal"
import { SecondaryHeader } from "components/SecondaryHeader"
import { UrqlProvider } from "lib/providers"
import { RequireAuth } from "lib/providers/AuthProvider"
import { WsClientProvider } from "lib/providers/WsClientProvider"
import { routes } from "lib/routes"
import { Accounts, Login, Method, MyRatings, RatingFoundation, Ratings, Redirect, Signup, SignupWelcome } from "pages"
import { RatingProfiles } from "pages/RatingProfiles"
import { Suspense, lazy } from "react"
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from "react-router-dom"
import { Authenticate } from "./Authenticate"

const Report = lazy(async () => {
  return {
    default: (await import("./pages/Report/Report")).Report,
  }
})

export const App = () => {
  const createRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  const router = createRouter([
    {
      path: "/",
      ErrorBoundary: ErrorBoundary,
      element: <Outlet />,
      children: [
        {
          path: "/report/:id/pdf",
          element: (
            <WsClientProvider>
              <UrqlProvider.UrqlProvider>
                <Suspense fallback={<div>Laster inn...</div>}>
                  <Report />
                </Suspense>
              </UrqlProvider.UrqlProvider>
            </WsClientProvider>
          ),
        },
        {
          path: routes.authenticate(),
          element: <Authenticate />,
        },
        {
          path: routes.loggedOut(),
          element: <div>logget ut. bruk mottatt lenke for å logge inn igjen</div>,
        },
        {
          path: routes.login(),
          element: <Login />,
        },
        {
          path: routes.signup(),
          element: <Signup />,
        },
        {
          path: routes.signupWelcome(),
          element: <SignupWelcome />,
        },
        {
          path: "/",
          element: <BaseLayout />,
          children: [
            {
              path: "/",
              element: <Redirect />,
            },
            {
              path: routes.rating(":id", "*"),
              element: (
                <RequireAuth>
                  <RatingFoundation />
                </RequireAuth>
              ),
            },
            {
              path: "/myratings/*",
              element: (
                <RequireAuth>
                  <NavbarHorizontal />
                  <Flex direction="column" width="100%">
                    <MyRatings />
                  </Flex>
                </RequireAuth>
              ),
            },
            {
              path: "*",
              element: (
                <RequireAuth admin>
                  <Flex height="100vh">
                    <Navbar />
                    <Flex direction="column" width="100%">
                      <SecondaryHeader />
                      <Outlet />
                    </Flex>
                  </Flex>
                </RequireAuth>
              ),
              children: [
                {
                  path: "ratings/*",
                  element: <Ratings />,
                },
                {
                  path: "admin/*",
                  children: [
                    {
                      index: true,
                      element: <Navigate to={routes.admin("ratings")} replace />,
                    },
                    {
                      path: "rating-profiles/*",
                      element: <RatingProfiles />,
                    },
                    {
                      path: "accounts/*",
                      element: <Accounts />,
                    },
                    {
                      path: "method/*",
                      element: <Method />,
                    },
                  ],
                },
                {
                  path: "*",
                  element: <Feedback type="notFound" heading="Siden finnes ikke" linkBack />,
                },
              ],
            },
          ],
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}
