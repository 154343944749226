import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Flex, IconButton, Text, Tooltip } from "@chakra-ui/react"

export interface PaginationProps {
  pages: number
  currentPage: number
  onPageChange: (page: number) => void
}

export const Pagination: React.FC<PaginationProps> = ({ pages, currentPage, onPageChange }) => {
  return (
    <Flex w="full" justifyContent="center" alignItems="center" gap={2}>
      <Tooltip label="Forrige side">
        <IconButton
          minWidth="10"
          aria-label="Forrige side"
          icon={<ChevronLeftIcon />}
          onClick={() => onPageChange(currentPage - 1)}
          isDisabled={currentPage === 0}
        />
      </Tooltip>
      <Text>
        Side{" "}
        <Text fontWeight="bold" as="span">
          {currentPage + 1}
        </Text>{" "}
        av{" "}
        <Text fontWeight="bold" as="span">
          {pages}
        </Text>
      </Text>
      <Tooltip label="Neste side">
        <IconButton
          minWidth="10"
          aria-label="Neste side"
          icon={<ChevronRightIcon />}
          onClick={() => onPageChange(currentPage + 1)}
          isDisabled={currentPage + 1 === pages}
        />
      </Tooltip>
    </Flex>
  )
}
