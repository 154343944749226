export const helpTexts = {
  ratingSettings: {
    orderPeriod: "Målingsperioden er perioden målingen gjennomføres for.",
    orderProfile:
      "Målingsprofilen er basert på næringskoden til virksomheten, på 4-sifret nivå. Målingsprofilen inneholder måltallene som virksomheten måles opp mot.",
    orderRaters: "Kontrollørene gjennomfører bærekraftmålingen for kunden(e).",
    orderContacts: "Kontaktpersonen(e) i virksomheten som bærekraftmåles.",
  },
  newRating: {
    createRating:
      "Ved opprettelse av en bærekraftsmåling på vegne av en virksomhet er det viktig at målingen registeres på riktig organisasjonsnummer. I søkefeltet kan man søke i Brønnøysundregistrene.\n\nDet er viktig at virksomheten er registrert med riktig næringskode i Brønnøysundregistrene. Dersom dette skulle være feil, må virksomheten sørge for å endre til korrekt næringskode før målingen kan gjennomføres.\n\nKontaktpersonen som registreres må ha rettigheter til å dele informasjon og inngå avtaler på vegne av virksomheten.",
  },
  comment:
    "Kommentar fra gjennomførende part er en kommentar til resultatet av bærekraftrapporten. Kommentaren skal være en objektiv oppsummering av de vesentlige punktene i målingen.\n\n" +
    "Skriv gjerne en til to setninger for hver dimensjon, med oppsummering på kategorinivå. \n\n" +
    "F.eks. Kundenavn AS har god kvalitet på økonomisk data, god lønnsomhet, god likviditet og meget god soliditet. Virksomheten mangler informasjon om vannforbruk, avfallsproduksjon og utslipp ved tjenestereiser, noe som gir en svak miljødimensjon. Den sosiale dimensjonen viser god virksomhetsstyring, gode arbeidsforhold og meget godt verdikjedeansvar. Kategori for balansert lønn er meget god og virksomheten viser sosialt ansvar i form av frivillighetsarbeid og donasjoner.\n\n" +
    "###### Dette skal alltid stå:\n\n" +
    "Kundenavn AS (skriv inn virksomhetens navn) har målingsprofil XX.XX Målingsprofil-navn (skriv inn målingsprofil med navn). Bærekraftmålingen er basert på data innlevert til Regnskapskontor AS (selskapet som gjennomfører målingen) for perioden DD.MM.YY til DD.MM.YY. (fyll ut periode). Dataunderlaget er gitt en kontroll før sammenstilling av resultat. \n\n" +
    "Indikatorsettet utvikles i takt med samfunnets utvikling, det samme gjelder måltall og vekting av indikatorer.\n\n",
  documentation: `Oversikt over innsendt dokumentasjon; hva, filnavn, samt tidspunkt for opplastning av underlaget. For å få lastet ned dokumentasjonen må man klikke på knappen "Last ned alt datagrunnlag".`,
  anomalies:
    "###### Avvik \n\n" +
    "Basert på opplastet SAF-T fil gjør systemet en kontroll på regnskapet. Ved forhold som kan oppfattes som feil i balansen/klassifiseringen og ved forhold som trenger ekstra kontroll, genereres det en avviksliste. Ved uvesentlige beløp kan avviket godkjennes, forholdet skal alltid kommenteres. Ved større avvik skal man be om korrigert underlag (se nedenfor)." +
    "\n\n" +
    "###### Omklassifisering \n\n" +
    "Det er mulighet for å omklassifisere postene som har havnet på feil kontoklasse. Gjennomføring av omklassifisering følger de samme prinsippene som omklassifisering i årsoppgjøret. Det er viktig å omklassifisere riktig, ettersom det påvirker resultatet på indikatorene i den økonomiske dimensjonen. Husk å kontrollere mot saldobalansen." +
    "\n\n" +
    "###### Be om tilleggsinformasjon - Korrigert underlag \n\n" +
    "Ved større feil og/eller mangler ved regnskapet, spørsmål eller datagrunnlag skal man be virksomheten om korrigert underlag for å få gjennomført en korrekt bærekraftsmåling.\n\n" +
    "Her kan man åpne opp for spesifikke seksjoner av systemet for at kunden skal kunne gjøre nødvendige endringer og sende inn nytt, korrekt underlag. Etterspørselen kommer til kunden i form av e-post.",
  questions:
    "###### Svar og grunnlag \n\n" +
    "Her vises en oversikt over virksomhetens svar på spørsmål, inkludert tilhørende dokumentasjon." +
    "\n\n" +
    "###### Be om tilleggsinformasjon - Tilleggsspørsmål \n\n" +
    "Her kan man etterspørre tilleggsinformasjon, utdypning av svar eller bedre dokumentasjon. \n\n" +
    "Ved opprettelse av tilleggspørsmål kan man sette opp spørsmålet ved å kreve tekstsvar og/eller filopplastning. Ved etablering av tilleggsspørsmål åpnes spørsmålsseksjonen opp for brukeren igjen, ved at de kan svare ut tilleggspørsmålet. Øvrige spørsmål vil forbli låst, og ikke mulig å endre på.\n\n" +
    "Ved behov for endring av svar eller underlag på eksisterende spørsmål skal man be om korrigert underlag.",
}
