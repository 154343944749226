import { Modal, ModalOverlay } from "@chakra-ui/react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { AdditionalQuestionsForm } from "./AdditionalQuestionForm"
import { ChooseAdditionalRequestType } from "./ChooseAdditionalRequestType"
import { RequestAmendments } from "./RequestAmendments"

export const RequestAdditionalInfo = () => {
  const navigate = useNavigate()

  return (
    <Modal size="lg" isOpen onClose={() => navigate("..", { relative: "path" })}>
      <ModalOverlay />
      <Routes>
        <Route index element={<ChooseAdditionalRequestType />} />
        <Route path="amendments" element={<RequestAmendments />} />
        <Route path="questions" element={<AdditionalQuestionsForm />} />
      </Routes>
    </Modal>
  )
}
