import { ContentBox } from "components/ContentBox"
import { SiteTitle } from "components/SiteTitle"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { useRating } from "lib/providers/RatingProvider"
import { Navigate, Route, Routes } from "react-router-dom"
import styled from "styled-components"
import { AdditionalQuestions } from "./AdditionalQuestions"
import { Anomalies } from "./Anomalies"
import { Answers } from "./Answers"
import { Documentation } from "./Documentation"
import { Navigation } from "./Navigation"
import { RequestAdditionalInfo } from "./RequestAdditionalInfo"

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-flow: row nowrap;
	margin: 0 0 36px 0;
	gap: 36px;
	overflow: hidden;
`

export const Data = () => {
  const { ratingId, ratingState } = useRating()
  useBreadcrumb("Datagrunnlag", `/ratings/${ratingId}/data`)

  if (!ratingState) return null
  const requestAdditionalInfoDisabled = !["RATING_IN_PROGRESS", "SUBMISSION"].includes(ratingState?.phase)

  return (
    <Container>
      <Navigation requestAdditionalInfoDisabled={requestAdditionalInfoDisabled} />
      <SiteTitle title={`Datagrunnlag - ${ratingState.brreg.navn}`} />
      <ContentBox w="100%" overflowX="scroll">
        <Routes>
          <Route index element={<Navigate to="documentation" replace={true} />} />
          <Route path="documentation/*" element={<Documentation />} />
          <Route path="anomalies/*" element={<Anomalies />} />
          <Route path="questions/*" element={<Answers />} />
          <Route path="tilleggsspørsmål/*" element={<AdditionalQuestions />} />
        </Routes>

        {!requestAdditionalInfoDisabled && (
          <Routes>
            <Route path=":page/request-additional-info/*" element={<RequestAdditionalInfo />} />
          </Routes>
        )}
      </ContentBox>
    </Container>
  )
}
