export enum ROOTS {
  SIGNUP = "signup",
  SIGNUP_WELCOME = "welcome",
  MY_RATINGS = "myratings",
  RATING = "rating",
  RATINGS = "ratings",
  ADMIN = "admin",
  LOGIN = "login",
  LOGGED_OUT = "logget-ut",
  AUTHENTICATE = "authenticate",
  REPORT = "report",
}

export const routes = {
  root: (path: ROOTS, subpath?: string) => ["", path, subpath].join("/").replace(/\/$/g, ""),

  signup: () => routes.root(ROOTS.SIGNUP),
  signupWelcome: () => routes.root(ROOTS.SIGNUP_WELCOME),

  rating: (id: string, subpath?: string) => routes.root(ROOTS.RATING, `${id}/${subpath || ""}`),
  myRatings: (subpath?: string) => routes.root(ROOTS.MY_RATINGS, subpath),

  ratings: (subpath?: string) => routes.root(ROOTS.RATINGS, subpath),
  admin: (subpath?: string) => routes.root(ROOTS.ADMIN, subpath),

  login: () => routes.root(ROOTS.LOGIN),
  loggedOut: () => routes.root(ROOTS.LOGGED_OUT),

  authenticate: () => routes.root(ROOTS.AUTHENTICATE),

  report: (id: string) => routes.root(ROOTS.REPORT, `${id}/pdf`),
}
