import { useRef } from "react"
import styled from "styled-components"
import { ContentScrollProvider } from "./useContentScroll"

const Container = styled.div<{ $scrollable?: boolean; $flex?: boolean; $alignCenter?: boolean }>`
	display: flex;
	flex-flow: row wrap;
	justify-content: ${(props) => (props.$alignCenter ? "center" : "start")};
	overflow: ${(p) => (p.$scrollable ? "auto" : "hidden")};
	${(p) => (p.$flex ? "flex: 1;" : "")}
`

const Content = styled.div<{ $maxWidth?: string; $scrollable?: boolean }>`
	flex: 1;
	max-width: ${(p) => p.$maxWidth || "1378px"};
	padding: 0 64px;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	${(p) => p.$scrollable && "overflow: visible;"}

	@media (max-width: 80em) {
		padding: 0 24px;
	}
`

export const CenteredContent: React.FC<{
  maxWidth?: string
  scrollable?: boolean
  flex?: boolean
  alignCenter?: boolean
  children?: React.ReactNode
}> = ({ children, maxWidth, alignCenter = false, scrollable = false, flex = true }) => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <Container ref={ref} {...{ $scrollable: scrollable, $flex: flex, $alignCenter: alignCenter }}>
      <Content {...{ $scrollable: scrollable, $maxWidth: maxWidth }}>
        <ContentScrollProvider scrollableElement={ref}>{children}</ContentScrollProvider>
      </Content>
    </Container>
  )
}
