import styled from "styled-components"
import { PreviewWatermark } from "./PreviewWatermark"

const Container = styled.div`
	margin: 0 16mm;
	break-after: page;
	line-height: 18pt;
	font-size: 12pt;
	font-weight: 300;
	position: relative;
`

export const Page = ({ children, ...rest }) => {
  return (
    <Container {...rest}>
      <PreviewWatermark />
      {children}
    </Container>
  )
}
