import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { useAbortRatingMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"

interface AbortRatingModalProps {
  onCancel: () => void
  onOk: () => void
  isOpen: boolean
}

export const AbortRatingModal: React.FC<AbortRatingModalProps> = ({ isOpen, onOk, onCancel }) => {
  const { ratingId, ratingState, isReady } = useRating()
  const [abortRatingStatus, abortRating] = useAbortRatingMutation()

  const save = async () => {
    await abortRating({ ratingId })

    onOk()
  }

  if (isReady && !ratingState) return null

  return (
    <Modal onClose={onCancel} isOpen={isOpen} size={isReady ? undefined : "full"}>
      <ModalOverlay />
      {isReady ? (
        <ModalContent>
          <ModalHeader>Kanseller måling</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={3} fontSize={16}>
              Er du sikker på at du vil kansellere denne målingen?
            </Text>
          </ModalBody>
          <ModalFooter gap="4">
            {!abortRatingStatus.fetching && (
              <Button variant="outline" onClick={onCancel} isLoading={abortRatingStatus.fetching}>
                Avbryt
              </Button>
            )}
            <Button colorScheme="green" onClick={save} isLoading={abortRatingStatus.fetching}>
              Ja, kanseller
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent bg="transparent" display="flex" justifyContent="center" alignItems="center">
          <Spinner size="xl" color="green.500" />
        </ModalContent>
      )}
    </Modal>
  )
}
