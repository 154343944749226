import { Flex } from "@chakra-ui/react"
import { SideNav, SideNavHeading, SideNavItem } from "components/SideNav"
import { useFoundationNav } from "lib/hooks/useFoundationNav"

export const Navigation: React.FC = () => {
  const nav = useFoundationNav()
  return (
    <Flex direction="column" w="300px">
      {nav.map((section, idx) => (
        <SideNav key={idx}>
          <SideNavHeading readOnly={section.readOnly} completed={section.completed}>
            {section.title}
          </SideNavHeading>
          {section.items.map((item, idx2) => (
            <SideNavItem
              to={item.to}
              label={item.label}
              completed={item.completed}
              readOnly={item.readOnly}
              key={idx2}
            />
          ))}
        </SideNav>
      ))}
    </Flex>
  )
}
