import styled from "styled-components"

export const ContentNavBar = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
`

export const ContentNavBarLinks = styled.div`
	display: flex;
	flex-flow: row nowrap;
	gap: 32px;

	a {
		display: block;
		padding: 8px 0;
		font-weight: var(--chakra-fontWeights-heading);
		font-family: var(--chakra-fonts-heading);
		color: var(--chakra-colors-gray-700);
		font-size: 17px;
		margin-bottom: 24px;
	}

	a.active {
		color: var(--chakra-colors-blue-400);
		border-bottom: 2px solid var(--chakra-colors-blue-400);
	}
`
