import { defineStyleConfig } from "@chakra-ui/react"

export const Heading = defineStyleConfig({
  baseStyle: {
    fontStyle: "normal",
    fontWeight: 700,
    fontFamily: "Satoshi Variable, sans-serif",
  },
  sizes: {
    h1: {
      fontSize: "2.5rem",
      lineHeight: "3rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2rem",
      lineHeight: "2.25rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.5625rem",
      lineHeight: "2rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
      fontWeight: 500,
    },
  },
})
