import { useRating } from "lib/providers/RatingProvider"

export const FreeVersionWatermark = ({ size }: { size?: string }) => {
  const { isFree } = useRating()
  return isFree ? (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 999,
      }}
    >
      <span
        style={{
          fontSize: size || "40pt",
          fontWeight: "bold",
          color: "grey",
          userSelect: "none",
          mixBlendMode: "darken",
        }}
      >
        UFULLSTENDIG
      </span>
    </div>
  ) : null
}
