import { ProcessingStatus } from "@esgt/types"
import { ErrorBanner } from "components/ErrorBanner"
import { useRating } from "lib/providers/RatingProvider"

export const RatingStatus = () => {
  const { ratingState, isFree } = useRating()

  if (
    !isFree &&
    [ratingState.dataFilesProcessingStatus?.saft?.status, ratingState.dataFilesProcessingStatus?.a07?.status].includes(
      ProcessingStatus.Failed,
    )
  ) {
    return (
      <ErrorBanner
        msg={`Feil ved prosessering av underlag (${
          ratingState.dataFilesProcessingStatus?.saft?.status === ProcessingStatus.Failed ? "SAF-T" : "A07"
        }). Vennligst prøv på nytt, kontakt oss dersom det vedvarer`}
      />
    )
  }

  return null
}
