import { Box, Heading, VStack } from "@chakra-ui/react"
import { EmptyMessage } from "components/EmptyMessage"
import { InfoHeadingPanel } from "components/InfoHeadingPanel"
import { useRating } from "lib/providers/RatingProvider"
import { Route, Routes } from "react-router-dom"
import { AnomaliesTable } from "./AnomaliesTable"
import { ApproveAnomalyModal } from "./ApproveAnomalyModal"
import { OmposteringsModal } from "./OmposteringsModal"

export const Anomalies: React.FC = () => {
  const { ratingState } = useRating()
  if (!ratingState) return null

  return (
    <Box>
      <InfoHeadingPanel as="h2" size="md" helpText="helpTexts.anomalies">
        Avvik og omklassifisering
      </InfoHeadingPanel>

      {ratingState.phase === "SUBMISSION" ? (
        <EmptyMessage>Avvik kommer når underlaget sendes inn</EmptyMessage>
      ) : (
        <AnomaliesTable anomalies={ratingState?.anomalies} />
      )}

      {ratingState && ratingState.earlierAnomalies.length > 0 && (
        <>
          <Heading as="h3" size="sm" mt={16}>
            Tidligere avvikslister
          </Heading>
          <VStack gap="32px" mt={6}>
            {ratingState.earlierAnomalies.map((anomalies, i) => (
              <AnomaliesTable key={i} anomalies={anomalies} readOnly={true} />
            ))}
          </VStack>
        </>
      )}

      <Routes>
        <Route path="approve-anomaly/:anomalyId" element={<ApproveAnomalyModal />} />
        <Route path="ompostering/:anomalyId" element={<OmposteringsModal />} />
      </Routes>
    </Box>
  )
}
