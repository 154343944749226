import { createContext, useContext, useState } from "react"

type HelpPopoverState = "initial" | "upgradeForm" | "generalForm" | "success"

type HelpPopoverContextType = {
  helpPopoverState: HelpPopoverState
  setHelpPopoverState: (state: HelpPopoverState) => void
}

const HelpPopoverContext = createContext<HelpPopoverContextType>({
  helpPopoverState: "initial",
  setHelpPopoverState: () => {},
})

export const HelpPopoverProvider = ({ children }) => {
  const [helpPopoverState, setHelpPopoverState] = useState<HelpPopoverState>("initial")

  return (
    <HelpPopoverContext.Provider value={{ helpPopoverState, setHelpPopoverState }}>
      {children}
    </HelpPopoverContext.Provider>
  )
}

export function useHelpPopover() {
  const context = useContext(HelpPopoverContext)
  if (!context) {
    throw new Error("useHelpPopover must be used within a HelpPopoverProvider")
  }
  return context
}
