import { Stack } from "@chakra-ui/react"
import { Feedback } from "components/Feedback"
import { InfoHeadingPanel } from "components/InfoHeadingPanel"
import { useRating } from "lib/providers/RatingProvider"
import { UploadsTable } from "./UploadsTable"

export const Documentation = () => {
  const { ratingState } = useRating()

  const altinnScopes = ["A07"]
  const regnskapScopes = ["saft", "saldobalanse", "aldersfordelt-saldoliste"]

  const regnskapFiles = ratingState?.uploads.filter(
    (upload) =>
      ["saft"].includes(upload.ratingFileType) ||
      (upload.ratingFileType === "generic" && regnskapScopes.includes(upload.scope)),
  )
  const altinnFiles = ratingState?.uploads.filter(
    (upload) =>
      ["a07"].includes(upload.ratingFileType) ||
      (upload.ratingFileType === "generic" && altinnScopes.includes(upload.scope)),
  )

  const questionsFiles = ratingState?.uploads.filter(
    (upload) => upload.ratingFileType === "generic" && upload.scope.startsWith("question"),
  )

  const noFiles = !regnskapFiles.length && !altinnFiles.length && !questionsFiles.length

  return (
    <Stack gap={8}>
      <InfoHeadingPanel size="md" as="h2" helpText="helpTexts.documentation">
        Dokumentasjon
      </InfoHeadingPanel>
      {noFiles && <Feedback heading="Ingen filer lastet opp" type="genericError" />}
      {regnskapFiles.length && <UploadsTable title="Regnskap" uploads={regnskapFiles} />}
      {altinnFiles.length && <UploadsTable title="Fra Altinn" uploads={altinnFiles} />}
      {questionsFiles.length && <UploadsTable title="Fra spørsmål" uploads={questionsFiles} />}
    </Stack>
  )
}
