import { useToast } from "@chakra-ui/react"
import { useRatingReportUrlQuery } from "lib/generated/graphql"
import { useEffect } from "react"

export const useReportDownload = (ratingId: string) => {
  const [ratingVersionQuery, executeQuery] = useRatingReportUrlQuery({
    variables: { key: ratingId },
    pause: true,
  })
  const toast = useToast()

  useEffect(() => {
    if (ratingVersionQuery.data) {
      if (ratingVersionQuery.data.ratingReportUrl) {
        window.location.href = ratingVersionQuery.data.ratingReportUrl
      } else {
        toast({
          title: "Feil ved nedlasting",
          description: "Det skjedde en feil ved nedlastingningen",
          status: "error",
          position: "top",
          isClosable: true,
        })
      }
    }
  }, [ratingVersionQuery])

  return executeQuery
}
