import { Button, Flex, Text } from "@chakra-ui/react"
import { Can } from "components/Can"
import { HelpPopover, useHelpPopover } from "components/HelpPopover"
import { IconSearchLgBlue } from "components/Icons"
import { routes } from "lib/routes"
import { Link } from "react-router-dom"

export const Onboarding: React.FC = () => {
  const { setHelpPopoverState } = useHelpPopover()
  return (
    <>
      <HelpPopover />
      <Flex height="full" alignItems="center" justifyContent="center">
        <Flex direction="column" align="center" justify="center" flex="1" gap="4" h="90vh">
          <Can permission="account:rating:create_free" entityId="1">
            <IconSearchLgBlue />
            <Text mb="4">Du har ingen målinger</Text>
            <Link to={routes.myRatings("new")}>
              <Button size="lg" colorScheme="blue">
                Opprett gratis bærekraftsmåling
              </Button>
            </Link>
            <Button
              onClick={() => {
                setHelpPopoverState("upgradeForm")
              }}
              size="lg"
              variant="outline"
            >
              Bestill verifisert bærekraftsrapport
            </Button>
            <Link to={routes.myRatings()}>
              <Text fontSize="md" textDecor="underline" color="blue.500">
                Vis onboarding intro
              </Text>
            </Link>
          </Can>
        </Flex>
      </Flex>
    </>
  )
}
