import { useCallback, useState } from "react"

export function useSet<T>(initialValue?: Set<T>): [Set<T>, (val: T) => void, (val: T) => void] {
  const [set, setSet] = useState<Set<T>>(initialValue || new Set())

  const add = useCallback((value: T) => {
    setSet((current) => {
      const copy = new Set(current)
      copy.add(value)
      return copy
    })
  }, [])

  const remove = useCallback((value: T) => {
    setSet((current) => {
      const copy = new Set(current)
      copy.delete(value)
      return copy
    })
  }, [])

  return [set, add, remove]
}
