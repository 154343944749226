import { Box, Flex, Image, Link, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react"
import Logo from "assets/images/esg-logo.svg"
import { IconUser } from "components/Icons"
import { useAuth } from "lib/providers/AuthProvider"
import { useCurrentUser } from "lib/providers/CurrentUser"
import { NavLink } from "react-router-dom"

export const NavbarHorizontal = () => {
  const { user } = useCurrentUser()
  const { signout } = useAuth()

  return (
    <Box backgroundColor="neutral.900">
      <Flex direction="row" px="8" py="4" alignItems="center">
        <Link flex="1" color="white" as={NavLink} to="/">
          <Image src={Logo} alt="ESG Trackr Logo" />
        </Link>

        <Menu placement="bottom">
          <MenuButton zIndex="9999" color="white">
            <IconUser />
          </MenuButton>
          <MenuList zIndex="9999" borderRadius="4px" shadow="lg" border="0" marginRight="4">
            <MenuItem as={Flex} gap={2} pointerEvents="none">
              <Text color="gray.500">Logget inn som</Text>
              <Text>{user.email}</Text>
            </MenuItem>
            <MenuItem onClick={signout}>Logg ut</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  )
}
