import { Flex } from "@chakra-ui/react"

const FoundationMainContent = ({ children }) => {
  return (
    <Flex gap="36px" overflow="auto" flexFlow={"column nowrap"} flex={1} maxW="1174px">
      {children}
    </Flex>
  )
}

export default FoundationMainContent
