import {
  Box,
  Button,
  CircularProgress,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { useCompleteFreeRatingMutation } from "lib/generated/graphql"
import { useReportDownload } from "lib/hooks/useReportDownload/useReportDownload"
import { useRating } from "lib/providers/RatingProvider"
import { useForm } from "react-hook-form"

interface CompleteFreeRatingModalProps {
  onClose: () => void
  isOpen: boolean
}

export const CompleteFreeRatingModal: React.FC<CompleteFreeRatingModalProps> = ({ isOpen, onClose }) => {
  const { ratingId, ratingState } = useRating()
  const [editStatus, completeFreeRating] = useCompleteFreeRatingMutation()
  const downloadReport = useReportDownload(ratingId)

  const defaultValues = {}

  const { handleSubmit } = useForm({
    defaultValues,
  })

  if (!ratingState) return null

  const save = () => {
    completeFreeRating({ ratingId })
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      {ratingState.phase === "COMPLETED" ? (
        <ModalContent>
          <ModalHeader>Måling fullført</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="8px">Din bærekraftsmåling er fullført.</Text>
          </ModalBody>

          <ModalFooter justifyContent="left" mb="8px">
            <Button colorScheme="green" onClick={downloadReport}>
              Last ned bærekraftsrapport
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent as="form" onSubmit={handleSubmit(save)}>
          <ModalHeader>Fullfør måling</ModalHeader>
          <ModalCloseButton />
          {!editStatus.fetching && (
            <ModalBody>
              <p>Etter at målingen er fullført vil du ikke kunne gjøre endringer.</p>
              <p>Er du sikker på at du vil fullføre?</p>
            </ModalBody>
          )}
          {editStatus.fetching && (
            <ModalBody>
              <Box width={"100%"} display="flex" flexDirection={"column"} alignItems={"center"} justifyContent="center">
                <CircularProgress size={"50px"} isIndeterminate color="green.400" />
                <Box mb={10} mt={5}>
                  Genererer rapport, vennligst vent...
                </Box>
              </Box>
            </ModalBody>
          )}
          {!editStatus.fetching && (
            <ModalFooter gap="4">
              {!editStatus.fetching && (
                <Button variant="outline" onClick={onClose} isLoading={editStatus.fetching}>
                  Avbryt
                </Button>
              )}
              <Button colorScheme="green" type="submit" isLoading={editStatus.fetching}>
                Ja, jeg er sikker
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      )}
    </Modal>
  )
}
