import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Icon,
} from "@chakra-ui/react"
import { MdCheck, MdLock } from "react-icons/md"

interface ContentAccordionProps {
  headingText: string | JSX.Element
  children?: React.ReactNode
  completed: boolean
  readOnly: boolean
}

const ContentAccordion: React.FC<ContentAccordionProps> = ({
  headingText,
  children,
  completed,
  readOnly,
}: ContentAccordionProps) => {
  return (
    <Accordion defaultIndex={[0]} allowMultiple border="1px solid" borderColor="blackAlpha.200" borderRadius="8">
      <AccordionItem border="0">
        <Heading as="h3" size="h4" bg="neutral.100" borderTopRadius="8">
          <AccordionButton>
            <Flex position="relative" flex="1">
              {readOnly ? (
                <Icon boxSize="18px" as={MdLock} color="neutral.300" mt={4} pos="absolute" left="4px" top="3px" />
              ) : (
                completed && <Icon boxSize="24px" as={MdCheck} color="green.500" mt={4} pos="absolute" left="0" />
              )}
              <Box textAlign="left" px="8" py="4" fontWeight="600">
                {headingText}
              </Box>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel bg="white" p="0" borderBottomRadius="8">
          <Flex gap="16">{children}</Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default ContentAccordion
