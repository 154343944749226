import { Heading } from "@chakra-ui/react"
import { MethodI18nFragment } from "components/I18nText"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { useRevisionEditing } from "../RatingEditingContext"
import { ConfigLine } from "./ConfigLine"

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
	gap: 36px;
	padding-bottom: 150px;
`

export const Category: React.FC = () => {
  const { dimensionId, categoryId } = useParams()
  const { revisionConfig, ratingProfile, method } = useRevisionEditing()

  if (!categoryId || !dimensionId || !ratingProfile || !method) return null

  const dimension = method.config.kpiDimensions.find((dim) => dim.slug === dimensionId)
  const category = dimension.categories.find((cat) => cat.id === categoryId)

  const disabled = revisionConfig?.categories?.[category.id]?.enabled === false

  return (
    <Container>
      <Heading size="md" as="h2">
        <MethodI18nFragment text={dimension.name} />: <MethodI18nFragment text={category.name} />
      </Heading>

      <ConfigLine
        itemType="categories"
        itemId={category.id}
        title={
          <Heading size="sm" as="h3">
            Kategori
          </Heading>
        }
      />
      <Heading size="sm" as="h3">
        KPIer
      </Heading>
      {category.kpiIds
        .map((id) => method.kpisById[id])
        .map((kpi) => (
          <ConfigLine
            key={kpi.id}
            disabled={disabled}
            itemType="kpis"
            itemId={kpi.id}
            title={
              <span>
                {kpi.kpiNumber} - <MethodI18nFragment text={kpi.name} />
              </span>
            }
          />
        ))}
    </Container>
  )
}
