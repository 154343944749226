import { Box } from "@chakra-ui/react"
import { config } from "lib/helpers/config"

export const StagingIndicator = () => {
  if (config.meta.environment !== "staging") return null

  return (
    <Box
      justifyContent={"center"}
      alignContent={"center"}
      bg="critical.600"
      w="100%"
      p={1}
      color="white"
      textAlign={"center"}
      fontWeight={600}
      fontSize="sm"
    >
      <span>OBS! Du er nå i staging.</span>
    </Box>
  )
}
