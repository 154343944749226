import { Flex, Heading, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { prettyFormatAmount } from "lib/helpers/misc"
import { useRating } from "lib/providers/RatingProvider"
import { useMemo } from "react"
import styled from "styled-components"

const DescriptionCol = styled(Td)`
  width: 200px;
`

const NumericCol = styled(Td)`
  text-align: right!important;
`

type RelevantDatapoints =
  | "eiendelerUB"
  | "anleggsmidlerUB"
  | "anleggsmidlerUtenFinAUB"
  | "omløpsmidlerUB"
  | "kortsiktigGjeldUB"
  | "langsiktigGjeldUB"
  | "egenkapitalUB"
  | "egenkapitalOgGjeldUB"

type BalanceSheetChanges = {
  datapoint: RelevantDatapoints
  amount: math.BigNumber
}

export const BalanceSheetWithEditing = ({ changes }: { changes?: BalanceSheetChanges[] }) => {
  const {
    ratingState: { datapoints },
  } = useRating()

  const flattenedChanges: { [_K in RelevantDatapoints]?: math.BigNumber } = useMemo(() => {
    if (changes && changes.length > 0) {
      return Array.from(changes).reduce((acc, curr) => {
        acc[curr.datapoint] = curr.amount
        return acc
      }, {})
    }
  }, [changes])

  return (
    <Flex direction={"column"} width={"100%"}>
      <Heading aria-level={2} size="h3" mt="40px">
        Balanse
      </Heading>
      <Table mt="16px">
        <Tbody>
          <Tr>
            <DescriptionCol>Anleggsmidler</DescriptionCol>
            {flattenedChanges?.anleggsmidlerUB ? (
              <>
                <NumericCol>
                  <span style={{ textDecoration: "line-through" }}>
                    {prettyFormatAmount(datapoints.anleggsmidlerUB.toNumber())}
                  </span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(flattenedChanges.anleggsmidlerUB.toNumber())}</span>
                </NumericCol>
              </>
            ) : (
              <>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.anleggsmidlerUB.toNumber())}</span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.anleggsmidlerUB.toNumber())}</span>
                </NumericCol>
              </>
            )}
          </Tr>
          <Tr sx={{ borderColor: "neutral.600" }}>
            <DescriptionCol>Omløpsmidler</DescriptionCol>
            {flattenedChanges?.omløpsmidlerUB ? (
              <>
                <NumericCol>
                  <span style={{ textDecoration: "line-through" }}>
                    {prettyFormatAmount(datapoints.omløpsmidlerUB.toNumber())}
                  </span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(flattenedChanges.omløpsmidlerUB.toNumber())}</span>
                </NumericCol>
              </>
            ) : (
              <>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.omløpsmidlerUB.toNumber())}</span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.omløpsmidlerUB.toNumber())}</span>
                </NumericCol>
              </>
            )}
          </Tr>
          <Tr sx={{ borderColor: "neutral.600" }}>
            <DescriptionCol>
              <strong>Eiendeler</strong>
            </DescriptionCol>
            {flattenedChanges?.eiendelerUB ? (
              <>
                <NumericCol>
                  <span style={{ textDecoration: "line-through" }}>
                    {prettyFormatAmount(datapoints.eiendelerUB.toNumber())}
                  </span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(flattenedChanges.eiendelerUB.toNumber())}</span>
                </NumericCol>
              </>
            ) : (
              <>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.eiendelerUB.toNumber())}</span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.eiendelerUB.toNumber())}</span>
                </NumericCol>
              </>
            )}
          </Tr>
        </Tbody>
      </Table>
      <Table mt="32px">
        <Tbody>
          <Tr>
            <DescriptionCol>Egenkapital</DescriptionCol>
            {flattenedChanges?.egenkapitalUB ? (
              <>
                <NumericCol>
                  <span style={{ textDecoration: "line-through" }}>
                    {prettyFormatAmount(datapoints.egenkapitalUB.toNumber())}
                  </span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(flattenedChanges.egenkapitalUB.toNumber())}</span>
                </NumericCol>
              </>
            ) : (
              <>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.egenkapitalUB.toNumber())}</span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.egenkapitalUB.toNumber())}</span>
                </NumericCol>
              </>
            )}
          </Tr>
          <Tr>
            <DescriptionCol>Langsiktig gjeld</DescriptionCol>
            {flattenedChanges?.langsiktigGjeldUB ? (
              <>
                <NumericCol>
                  <span style={{ textDecoration: "line-through" }}>
                    {prettyFormatAmount(datapoints.langsiktigGjeldUB.toNumber())}
                  </span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(flattenedChanges.langsiktigGjeldUB.toNumber())}</span>
                </NumericCol>
              </>
            ) : (
              <>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.langsiktigGjeldUB.toNumber())}</span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.langsiktigGjeldUB.toNumber())}</span>
                </NumericCol>
              </>
            )}
          </Tr>
          <Tr sx={{ borderColor: "neutral.600" }}>
            <DescriptionCol>Kortsiktig gjeld</DescriptionCol>
            {flattenedChanges?.kortsiktigGjeldUB ? (
              <>
                <NumericCol>
                  <span style={{ textDecoration: "line-through" }}>
                    {prettyFormatAmount(datapoints.kortsiktigGjeldUB.toNumber())}
                  </span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(flattenedChanges.kortsiktigGjeldUB.toNumber())}</span>
                </NumericCol>
              </>
            ) : (
              <>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.kortsiktigGjeldUB.toNumber())}</span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.kortsiktigGjeldUB.toNumber())}</span>
                </NumericCol>
              </>
            )}
          </Tr>
          <Tr sx={{ borderColor: "neutral.600" }}>
            <DescriptionCol>
              <strong>Egenkapital og gjeld</strong>
            </DescriptionCol>
            {flattenedChanges?.egenkapitalOgGjeldUB ? (
              <>
                <NumericCol>
                  <span style={{ textDecoration: "line-through" }}>
                    {prettyFormatAmount(datapoints.egenkapitalOgGjeldUB.toNumber())}
                  </span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(flattenedChanges.egenkapitalOgGjeldUB.toNumber())}</span>
                </NumericCol>
              </>
            ) : (
              <>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.egenkapitalOgGjeldUB.toNumber())}</span>
                </NumericCol>
                <NumericCol>
                  <span>{prettyFormatAmount(datapoints.egenkapitalOgGjeldUB.toNumber())}</span>
                </NumericCol>
              </>
            )}
          </Tr>
        </Tbody>
      </Table>
    </Flex>
  )
}
