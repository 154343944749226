import { Box, Heading, Text } from "@chakra-ui/react"
import frontpageImg from "assets/report-frontpage.jpg"
import { Logo } from "components/Logo"
import { FreeVersionWatermark, Page } from "./components"

export const FreeOutro: React.FC = () => {
  return (
    <Page style={{ breakAfter: "avoid" }}>
      <Heading mb={"8mm"} fontWeight={300} fontSize={"15pt"} textAlign={"center"}>
        GRATIS -{" "}
        <Text as="span" fontWeight={600}>
          IKKE VERTIFISERT OG UFULLSTENDIG BÆREKRAFTSRAPPORT
        </Text>
      </Heading>
      <Box position={"relative"}>
        <FreeVersionWatermark />
        <img alt="" width={"100%"} src={frontpageImg} />
        <div style={{ position: "absolute", top: "10mm", left: "10mm" }}>
          <Logo />
        </div>
      </Box>
      <Box textAlign={"center"} fontSize="13pt" mt={"12mm"} mb="8mm" fontWeight="400">
        Tusen takk for at du tested Gratis versjonen av ESG Trackr AS sin bærekraftsrapport.
      </Box>
      <Box textAlign={"center"} fontSize="13pt" mb="8mm" fontWeight="400">
        Ved ønsker om yttligere informasjon, oppgradering av rapport eller bistand ta kontakt med ESG Trackr AS på{" "}
        <a href="mailto:post@esgtrackr.no">post@esgtrackr.no</a>
      </Box>
    </Page>
  )
}
