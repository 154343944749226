import { Flex } from "@chakra-ui/react"
import { makeAdditionalQuestionsCompatible } from "@esgt/helpers"
import { FoundationHeader } from "components/FoundationHeader"
import FoundationMainContent from "components/FoundationMainContent"
import { MethodI18nFragment } from "components/I18nText"
import { useRating } from "lib/providers/RatingProvider"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { CategoryQuestion } from "./Question"

export const Category = () => {
  const { ratingState, ratingProfile, method } = useRating()
  const { questionCategorySlug } = useParams()

  const isAdditionalQuestions = questionCategorySlug === "additional-questions"

  const { questions, categoryName } = useMemo(() => {
    if (isAdditionalQuestions) {
      const questions = makeAdditionalQuestionsCompatible(ratingState.additionalQuestions)
      return { questions, categoryName: { en: "Tilleggsspørsmål", nb: "Tilleggsspørsmål" } }
    }

    const category = method?.config.questionCategories.find((cat) => cat.slug === questionCategorySlug)

    const questions = method?.config.questions.filter((q) => category?.questionIds.includes(q.id))

    return { questions, categoryName: category?.name }
  }, [ratingState?.additionalQuestions, method, questionCategorySlug])

  if (!ratingState) return null
  if (!ratingProfile) return null
  if (!categoryName) return null

  const isReadonly =
    ratingState?.phase !== "SUBMISSION" ||
    (ratingState?.reopenedScopes &&
      (isAdditionalQuestions
        ? !ratingState?.reopenedScopes?.includes("additionalQuestions")
        : !ratingState?.reopenedScopes?.includes("questions")))

  return (
    <FoundationMainContent>
      <FoundationHeader title={<MethodI18nFragment text={categoryName} />} />

      <Flex flexFlow="column nowrap" gap="36px" mb="10">
        {questions.map((question) => (
          <CategoryQuestion key={question.id} question={question} isReadOnly={isReadonly} />
        ))}
      </Flex>
    </FoundationMainContent>
  )
}
