import { Box, Button, Flex, Heading } from "@chakra-ui/react"
import { UserRatingRole } from "@esgt/types"
import { Can } from "components/Can"
import Carousel from "components/Carousel"
import { Feedback } from "components/Feedback"
import { SiteTitle } from "components/SiteTitle"
import { useUserRatingsQuery } from "lib/generated/graphql"
import { useCurrentUser } from "lib/providers/CurrentUser"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { OverviewTable } from "./OverviewTable"

const Header = styled.div`
	margin-bottom: 32px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
  align-items: center;
`
const Container = styled.div`
	margin: 32px 0;
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
`
export function Overview() {
  const [queryResult] = useUserRatingsQuery({
    variables: {},
    requestPolicy: "cache-and-network",
  })
  const { isSelfServiceUser } = useCurrentUser()
  const ownRatings = queryResult?.data?.userRatings?.filter((rating) =>
    [UserRatingRole.ContactPerson, UserRatingRole.Contributor].includes(rating.userRole),
  )
  return (
    <>
      {ownRatings &&
        (ownRatings.length > 0 ? (
          <Box px="8">
            <SiteTitle title={"Mine målinger"} />
            <Container>
              <Header>
                <Heading as="h1" size="lg">
                  Alle målinger
                </Heading>
                <Can permission="account:rating:create_free" entityId="1">
                  <Link to="new">
                    <Button colorScheme="blue" size="lg">
                      Opprett måling
                    </Button>
                  </Link>
                </Can>
              </Header>
              {queryResult?.data && <OverviewTable ratings={ownRatings} />}
            </Container>
          </Box>
        ) : isSelfServiceUser ? (
          <Box>
            <Carousel />
          </Box>
        ) : (
          <Flex height="full" alignItems="center" justifyContent="center">
            <Flex flex="1" justifyContent="center" alignItems="center" gap={6}>
              <Feedback type="search" text="Du har ingen målinger" />
            </Flex>
          </Flex>
        ))}
    </>
  )
}
