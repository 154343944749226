import { Can } from "components/Can"
import { routes } from "lib/routes"
import { Navigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { AccountEditing } from "./AccountEditing"
import { AccountEditingProvider } from "./AccountEditingProvider"

export const Account: React.FC = () => {
  const { accountId } = useParams()

  if (!accountId) {
    return <Navigate to={routes.admin("accounts")} />
  }

  return (
    <Can
      permission="account:update"
      entityId={accountId}
      noAccessComponent={<Navigate to={routes.ratings()} replace={true} />}
    >
      <AccountEditingProvider {...{ accountId }}>
        <Container>
          <AccountEditing />
        </Container>
      </AccountEditingProvider>
    </Can>
  )
}

const Container = styled.div`
	display: flex;
	flex-flow: row nowrap;
	flex: 1;
	gap: 24px;
	overflow: hidden;
`
