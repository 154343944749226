import { Route, Routes } from "react-router-dom"
import { Category } from "./Category"

export const QuestionsRoutes = () => {
  return (
    <Routes>
      <Route path=":questionCategorySlug" element={<Category />} />
    </Routes>
  )
}
