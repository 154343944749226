import { config } from "lib/helpers/config"
import { useVersionNotification } from "lib/hooks"

export const VersionNotification = () => {
  if (config.meta.environment === "dev") return null

  useVersionNotification()

  return null
}
