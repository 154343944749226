import { Tooltip } from "@chakra-ui/react"
import styled from "styled-components"

const Styles = styled.span`
	color: var(--chakra-colors-red-600);
	font-weight: bold;
`

export const RequiredInputAsterisk = () => {
  return (
    <Tooltip label="Required">
      <Styles>*</Styles>
    </Tooltip>
  )
}
