import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Link,
  Progress,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { CompanyInfoModal } from "components/CompanyInfoModal"
import { FormattedRatingPeriod } from "components/FormattedRatingPeriod"
import { IconArrowRight, IconCalendarLine, IconHomeSecure, IconList, IconPlus } from "components/Icons"
import { useRating } from "lib/providers/RatingProvider"
import { routes } from "lib/routes"
import { NavLink, useMatch } from "react-router-dom"
import styled from "styled-components"

const KeyInfoText = styled(Text).attrs({
  color: "gray.700",
  display: "flex",
  alignItems: "center",
  fontWeight: "normal",
  fontSize: "initial",
})``

const KeyInfoButton = styled(KeyInfoText).attrs({ as: Button })``

export const Header: React.FC = () => {
  const { ratingState, ratingProfile, ratingId } = useRating()
  const companyInfoModal = useDisclosure()
  const match = useMatch("/ratings/:ratingId/:tab")

  const showBackButton = ["settings", "logs"].includes(match?.params.tab || "")

  return (
    <Box pt={10} pb={8}>
      {showBackButton && (
        <Box mb={4}>
          <Link as={NavLink} to="data" color="blue.500">
            <IconArrowRight transform="rotate(180deg)" /> Til kontroll
          </Link>
        </Box>
      )}
      <Flex width="full" justifyContent="space-between" mb={4}>
        <Heading as="h1" style={{ fontSize: "32px", marginBottom: "2px" }}>
          {ratingState?.brreg?.navn || " "}
          {ratingState.phase === "ABORTED" && (
            <Badge variant="subtle" ml={3} colorScheme="red">
              Kansellert
            </Badge>
          )}
        </Heading>
        <HStack gap={4} mb={4} align="end">
          <NavLink to={routes.rating(ratingId)}>
            <Text fontSize="md" fontWeight="600">
              Til grunnlagsdata
            </Text>
          </NavLink>
          <Divider orientation="vertical" borderColor="gray.700" height="16px" />
          <NavLink to="settings">
            {({ isActive }) => (
              <Text fontSize="md" fontWeight="600" color={isActive && "gray.600"}>
                Målingsinnstillinger
              </Text>
            )}
          </NavLink>
          <Divider orientation="vertical" borderColor="gray.700" height="16px" />
          <NavLink to="logs">
            {({ isActive }) => (
              <Text fontSize="md" fontWeight="600" color={isActive && "gray.600"}>
                Logg
              </Text>
            )}
          </NavLink>
        </HStack>
      </Flex>
      <Flex width="full" justifyContent="space-between">
        <HStack alignItems="center" gap={6}>
          <KeyInfoText>
            <IconHomeSecure mr={2} /> {ratingState?.orgNr}
          </KeyInfoText>
          <KeyInfoText>
            <IconCalendarLine mr={2} />{" "}
            <FormattedRatingPeriod
              period={
                ratingState?.period
                  ? { start: ratingState.period.start.toDate(), end: ratingState.period.end.toDate() }
                  : undefined
              }
            />
          </KeyInfoText>
          <KeyInfoText>
            <IconList mr={2} /> {ratingProfile?.name} {ratingProfile?.methodVersion}
          </KeyInfoText>

          <KeyInfoButton
            minW={0}
            py={0}
            variant="link"
            leftIcon={<IconPlus boxSize="5" mr="-5px" />}
            onClick={companyInfoModal.onOpen}
          >
            Mer info
          </KeyInfoButton>
        </HStack>
      </Flex>
      {ratingState.phase === "PROCESSING_DOCUMENTATION" && (
        <div>
          <Progress value={100} size="xs" colorScheme={"blue"} mt="0" mb="2" hasStripe={true} isAnimated={true} />
          <Text fontSize="12px" color="black" overflow="hidden" whiteSpace={"nowrap"} textOverflow="ellipsis" mb="10">
            Underlaget er sendt inn og blir nå prosessert. Vennligst vent.
          </Text>
        </div>
      )}

      {ratingState && (
        <CompanyInfoModal
          isOpen={companyInfoModal.isOpen}
          ratingState={ratingState}
          onClose={companyInfoModal.onClose}
        />
      )}
    </Box>
  )
}
