import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { Route, Routes } from "react-router-dom"
import { NewRating } from "./NewRating"
import { Overview } from "./Overview"
import { Rating } from "./Rating"

export const Ratings: React.FC = () => {
  useBreadcrumb("Målinger", "/ratings")
  return (
    <Routes>
      <Route index element={<Overview />} />
      <Route path="new/*" element={<NewRating />} />
      <Route path=":id/*" element={<Rating />} />
    </Routes>
  )
}
