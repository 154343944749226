import { UnauthorizedReason } from "lib/helpers/unauthorizedFeedback"
import { useCurrentUser } from "lib/providers/CurrentUser/CurrentUser"
import { routes } from "lib/routes"
import { Dashboard } from "pages"
import { Navigate, useSearchParams } from "react-router-dom"

export const Redirect = () => {
  const [searchParams] = useSearchParams()
  const { user, error, fetching, isSelfServiceUser, account } = useCurrentUser()

  if (fetching) {
    return null
  }

  if (error) {
    throw new Error(error.message)
  }

  if (!user) {
    const params = new URLSearchParams()

    params.append("reason", UnauthorizedReason.NOT_LOGGED_IN)

    return <Navigate to={`${routes.login()}?${params.toString()}`} replace />
  }

  const nextUrl = searchParams.get("nextUrl")

  if (nextUrl) {
    return <Navigate to={nextUrl} replace />
  }

  if (isSelfServiceUser) {
    return <Navigate to={routes.myRatings()} replace />
  }

  if (user.access?.length === 1 && user.rating?.key) {
    return <Navigate to={routes.rating(user.rating.key)} replace />
  }

  if (!account) {
    return <Navigate to={routes.myRatings()} replace />
  }

  if (account && !user.rating?.key) {
    return <Navigate to={routes.ratings()} replace />
  }

  return <Dashboard />
}
