import { createContext, useContext } from "react"

const ContentScrollContext = createContext<React.MutableRefObject<HTMLDivElement>>(null)

interface ContentScrollProviderProps {
  children?: React.ReactNode
  scrollableElement: React.MutableRefObject<HTMLDivElement>
}

export const ContentScrollProvider: React.FC<ContentScrollProviderProps> = ({ children, scrollableElement }) => {
  return <ContentScrollContext.Provider value={scrollableElement}>{children}</ContentScrollContext.Provider>
}

export const useContentScroll = () => {
  return useContext(ContentScrollContext)
}
