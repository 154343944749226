import { formatDate } from "lib/helpers/formatDate"

interface FormattedRatingPeriodProps {
  period?: { start?: Date; end?: Date }
}

export const FormattedRatingPeriod: React.FC<FormattedRatingPeriodProps> = ({ period }) => {
  return (
    <>
      {period?.start ? formatDate(new Date(period?.start), "MMM yyyy") : "-"} -{" "}
      {period?.end ? formatDate(new Date(period?.end), "MMM yyyy") : "-"}
    </>
  )
}
