import { SearchIcon } from "@chakra-ui/icons"
import { Button, Heading, Input, InputGroup, InputLeftElement, Select } from "@chakra-ui/react"
import { BusinessArea, BusinessAreaNames } from "@esgt/types"
import { CenteredContent } from "components/CenteredContent"
import { ContentBox } from "components/ContentBox"
import { SiteTitle } from "components/SiteTitle"
import { useRatingProfilesQuery } from "lib/generated/graphql"
import { BiPlus } from "react-icons/bi"
import { Link, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { useDebounce } from "usehooks-ts"
import { OverviewTable } from "./OverviewTable"

const Header = styled.div`
	margin-bottom: 32px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-end;
`
const Container = styled.div`
	margin: 70px 0;
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
`

const SearchAndFilters = styled.div`
	display: flex;
	flex-flow: row nowrap;
	gap: 24px;
`

export function Overview() {
  const [searchParams, setSearchParams] = useSearchParams({
    scope: "ALL",
    businessArea: BusinessArea.SMBS,
    methodVersion: "v4",
  })
  const delayedQueryString = useDebounce<string | null>(searchParams.get("q"), 50)
  const [queryResult] = useRatingProfilesQuery({
    variables: {
      q: delayedQueryString,
      businessArea: searchParams.get("businessArea"),
      methodVersion: searchParams.get("methodVersion"),
    },
    requestPolicy: "cache-and-network",
  })

  const updateSearchParam = (key: string, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  return (
    <CenteredContent>
      <SiteTitle title={"Målingsprofiler"} />
      <Container>
        <Header>
          <div>
            <Heading as="h1" style={{ fontSize: "24px", marginBottom: "24px" }}>
              Målingsprofiler
            </Heading>
            <SearchAndFilters>
              <InputGroup>
                <Select
                  onChange={(event) => updateSearchParam("businessArea", event.target.value)}
                  size="md"
                  width="auto"
                  bg="white"
                  value={searchParams.get("businessArea")}
                >
                  <option value={BusinessArea.SMBS as string}>{BusinessAreaNames[BusinessArea.SMBS]}</option>
                  <option value={BusinessArea.BUILDINGS as string}>{BusinessAreaNames[BusinessArea.BUILDINGS]}</option>
                  <option value={BusinessArea.CITIES as string}>{BusinessAreaNames[BusinessArea.CITIES]}</option>
                </Select>
              </InputGroup>
              <InputGroup>
                <Select
                  onChange={(event) => updateSearchParam("methodVersion", event.target.value)}
                  size="md"
                  width="auto"
                  bg="white"
                  value={searchParams.get("methodVersion")}
                >
                  <option value={"v3"}>Versjon 3</option>
                  <option value={"v4"}>Versjon 4</option>
                </Select>
              </InputGroup>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="search"
                  placeholder="Søk i målingsprofiler"
                  width={400}
                  value={searchParams.get("q") || ""}
                  onChange={({ target: { value } }) => updateSearchParam("q", value)}
                  spellCheck={false}
                  bg="white"
                />
              </InputGroup>
            </SearchAndFilters>
          </div>
          <Link to="new">
            <Button colorScheme="green" leftIcon={<BiPlus />}>
              Opprett målingsprofil
            </Button>
          </Link>
        </Header>
        <ContentBox height="100%">{queryResult.data && <OverviewTable data={queryResult?.data} />}</ContentBox>
      </Container>
    </CenteredContent>
  )
}
