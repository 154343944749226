import {
  Box,
  Button,
  CircularProgress,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { useCompleteRatingMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

export const CompleteRatingModal = () => {
  const navigate = useNavigate()
  const { ratingId, ratingState } = useRating()
  const [editStatus, completeRating] = useCompleteRatingMutation()

  const defaultValues = {}

  const { handleSubmit } = useForm({
    defaultValues,
  })

  if (!ratingState) return null

  const close = () => navigate("..")
  const save = () => {
    completeRating({ ratingId })
  }

  return (
    <Modal size={"xl"} onClose={close} isOpen={true}>
      <ModalOverlay />
      {ratingState.phase === "COMPLETED" ? (
        <ModalContent>
          <ModalHeader>Måling fullført</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Gratulerer!</p>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={close} isLoading={editStatus.fetching}>
              Lukk
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent as="form" onSubmit={handleSubmit(save)}>
          <ModalHeader>Fullfør måling</ModalHeader>
          <ModalCloseButton />
          {!editStatus.fetching && (
            <ModalBody>
              <p>Etter at ratingen er fullført vil du ikke kunne gjøre endringer.</p>
              <p>Er du sikker på at du vil fullføre?</p>
            </ModalBody>
          )}
          {editStatus.fetching && (
            <ModalBody>
              <Box width={"100%"} display="flex" flexDirection={"column"} alignItems={"center"} justifyContent="center">
                <CircularProgress size={"50px"} isIndeterminate color="green.400" />
                <Box mb={10} mt={5}>
                  Genererer rapport, vennligst vent...
                </Box>
              </Box>
            </ModalBody>
          )}
          {!editStatus.fetching && (
            <ModalFooter gap="4">
              {!editStatus.fetching && (
                <Button variant="outline" onClick={close} isLoading={editStatus.fetching}>
                  Avbryt
                </Button>
              )}
              <Button colorScheme="green" type="submit" isLoading={editStatus.fetching}>
                Ja, jeg er sikker
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      )}
    </Modal>
  )
}
