import { useToast } from "@chakra-ui/react"
import { useAuth } from "lib/providers/AuthProvider"

export const useHandleErrors = () => {
  const auth = useAuth()
  const { signout } = auth || {}
  const toast = useToast()

  return (error: unknown): boolean => {
    const reason = error instanceof CloseEvent ? error?.reason : error instanceof Error ? error?.message : ""

    // If this error happened due to a missing or expired token or the user does not exist
    // We should log the user out as this should only happen when a user/account is deactivated
    if (
      (error instanceof CloseEvent || error instanceof Error) &&
      ["Forbidden", "Invalid token", "Expired token", "User not found", "Account not found"].includes(reason)
    ) {
      switch (reason) {
        case "Expired token":
        case "Invalid token": {
          toast({
            title: "Din innlogging har utløpt. Vennligst logg inn på nytt.",
            status: "error",
            position: "top",
            isClosable: true,
            duration: 10000,
          })
          break
        }
        default: {
          toast({
            title: "Noe gikk galt, vennligst logg inn på nytt.",
            status: "info",
            position: "top",
            isClosable: true,
            duration: 10000,
          })
        }
      }

      signout()
      return true
    }
  }
}
