const envs: Record<string, string> = (window as any)._ENV

export const config = {
  API_URL: envs.VITE_APP_API_URL || "http://localhost:3000",
  WS_API_URL: envs.VITE_APP_API_WS_URL || "ws://localhost:3001",
  GOOGLE_ANALYTICS_SELF_SERVICE_ENABLED: envs.VITE_APP_GOOGLE_ANALYTICS_SELF_SERVICE_ENABLED === "true",
  GOOGLE_ANALYTICS_SELF_SERVICE_ID: envs.VITE_APP_GOOGLE_ANALYTICS_SELF_SERVICE_ID || "",
  sentry: {
    enabled: envs.VITE_APP_SENTRY_ENABLED === "true",
    dsn: envs.VITE_APP_SENTRY_DSN || "",
  },
  meta: {
    release: envs.VITE_APP_RELEASE || "0.0.0",
    environment: envs.VITE_APP_ENVIRONMENT || "dev",
  },
}
