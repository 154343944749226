import { ContentBox } from "components/ContentBox"
import { SiteTitle } from "components/SiteTitle"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { useRating } from "lib/providers/RatingProvider"
import { Route, Routes } from "react-router-dom"
import styled from "styled-components"
import { CompleteRatingModal } from "./CompleteRatingModal"
import { EditComment } from "./EditComment"

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	margin: 0 0 36px 0;
	/* display: grid;
	grid-template-columns: 1fr 1fr;
	margin: 36px 0;
	gap: 100px;
	a {
		color: var(--chakra-colors-orange-600);
	}
	th {
		max-width: 160px;
	}
	td {
		font-size: 14px;
	}
	td:nth-child(3) {
		width: 50px;
	} */
`

export const Delivery = () => {
  const { ratingId, ratingState } = useRating()
  useBreadcrumb("Kommentar", `/ratings/${ratingId}/comment`)

  return (
    <Container>
      <SiteTitle title={`Kommentar - ${ratingState.brreg.navn}`} />
      <ContentBox>
        <EditComment />
        <Routes>
          <Route path="deliver" element={<CompleteRatingModal />} />
        </Routes>
      </ContentBox>
    </Container>
  )
}
