import { Navigate, Route, Routes } from "react-router-dom"
import { ArAr } from "./ArAr"
import { BalanceSheet } from "./BalanceSheet"
import { SAFT } from "./SAFT"

export const FinancesRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="saft" replace={true} />} />
      <Route path="saft" element={<SAFT />} />
      <Route path="saldobalanse" element={<BalanceSheet />} />
      <Route path="aldersfordelt-saldoliste" element={<ArAr />} />
    </Routes>
  )
}
