import { ChevronRightIcon } from "@chakra-ui/icons"
import { Breadcrumb, BreadcrumbItem } from "@chakra-ui/react"
import HomeIcon from "assets/icons/new/home-breadcrumbs-icon.svg"
import { useCurrentUser } from "lib/providers/CurrentUser/CurrentUser"
import { routes } from "lib/routes"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { useBreadcrumbs } from "./BreadcrumbProvider"

export const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs()
  const { isSelfServiceUser } = useCurrentUser()

  return (
    <BreadcrumbStyles spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
      <BreadcrumbItem>
        {isSelfServiceUser ? (
          <NavLink to={routes.myRatings()}>Målinger</NavLink>
        ) : (
          <NavLink to="/">
            <Home />
          </NavLink>
        )}
      </BreadcrumbItem>

      {breadcrumbs.map(({ path, label }) => (
        <BreadcrumbItem key={path}>
          <NavLink to={path}>{label}</NavLink>
        </BreadcrumbItem>
      ))}
    </BreadcrumbStyles>
  )
}

const Home = styled.span`
	background-image: url("${HomeIcon}");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	display: block;
	width: 18px;
	height: 18px;
`

const BreadcrumbStyles = styled(Breadcrumb)`
	ol {
		display: flex;
		align-items: center;

    li:last-child {
      font-weight: 500;
    }
	}
`
