import { Flex, Heading } from "@chakra-ui/react"
import { UserRatingRole } from "@esgt/types"
import { NavbarHorizontal } from "components/NavbarHorizontal"
import { PageContent } from "components/PageContent"
import { SiteTitle } from "components/SiteTitle"
import { useUserAccountsQuery, useUserRatingsQuery } from "lib/generated/graphql"
import { RequireAuth } from "lib/providers/AuthProvider"
import { DashboardTable } from "./DashboardTable"

export function Dashboard() {
  const [userRatingsResult] = useUserRatingsQuery({
    requestPolicy: "cache-and-network",
  })
  const [userAccountsResult] = useUserAccountsQuery({
    requestPolicy: "cache-and-network",
  })
  const ownRatings = userRatingsResult?.data?.userRatings?.filter((rating) =>
    [UserRatingRole.ContactPerson, UserRatingRole.Contributor].includes(rating.userRole),
  )

  const userAccounts = userAccountsResult?.data?.userAccounts
  return (
    ownRatings &&
    userAccounts && (
      <>
        <RequireAuth>
          <NavbarHorizontal />
          <Flex direction="column" width="100%">
            <PageContent>
              <Flex flex="1" flexFlow="column nowrap">
                <SiteTitle title={"Mine kontoer"} />
                <Heading as="h1" size="lg" mb="32px">
                  Mine kontoer
                </Heading>
                {<DashboardTable ratings={ownRatings} accounts={userAccounts} />}
              </Flex>
            </PageContent>
          </Flex>
        </RequireAuth>
      </>
    )
  )
}
