import { createContext } from "react"

interface VersionContext {
  hasVersionChanged: boolean
  isMaintenance?: boolean
}

export const Context = createContext<VersionContext>({
  hasVersionChanged: false,
})
