import { CSSReset } from "@chakra-ui/react"
import { CurrentUser, VersionProvider } from "lib/providers"
import { AuthProvider } from "lib/providers/AuthProvider"
import { UrqlProvider } from "lib/providers/UrqlProvider"
import { WsClientProvider } from "lib/providers/WsClientProvider"
import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { Fonts } from "./Fonts"
import { StagingIndicator } from "./StagingIndicator"
import { VersionNotification } from "./VersionNotification"
import { BreadcrumbProvider } from "./breadcrumbs/BreadcrumbProvider"

const Container = styled.div`
	display: flex;
	flex-direction: column;
  position: relative;
`

export const BaseLayout = () => (
  <VersionProvider.Provider>
    <AuthProvider>
      <WsClientProvider>
        <UrqlProvider>
          <BreadcrumbProvider>
            <Fonts />
            <CSSReset />
            <CurrentUser.Provider>
              <StagingIndicator />
              <VersionNotification />
              <Container>
                <Outlet />
              </Container>
            </CurrentUser.Provider>
          </BreadcrumbProvider>
        </UrqlProvider>
      </WsClientProvider>
    </AuthProvider>
  </VersionProvider.Provider>
)
