import { Button, Divider, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Stack, Text } from "@chakra-ui/react"
import { Link } from "react-router-dom"

interface RequestTypeProps {
  title: string
  description: React.ReactNode
  buttonText: string
  link: string
}

const RequestType: React.FC<RequestTypeProps> = ({ title, description, link, buttonText }) => {
  return (
    <Stack alignItems="flex-start" gap={1} py={4}>
      <Text fontWeight="bold">{title}</Text>
      {description}
      <Button mt={6} size="sm" as={Link} variant="outline" bg="white" to={link}>
        {buttonText}
      </Button>
    </Stack>
  )
}

export const ChooseAdditionalRequestType = () => {
  return (
    <ModalContent>
      <ModalHeader>Tilleggsinformasjon</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <RequestType
          title="Korriger underlag"
          description={
            <>
              <Text>
                Ved større feil og/eller mangler ved regnskapet, spørsmål eller datagrunnlag må man be virksomheten om
                korrigert underlag for å få gjennomført en korrekt bærekraftsmåling.
              </Text>
              <Text>
                Her kan man åpne opp for spesifikke seksjoner av systemet for at kunden skal kunne gjøre nødvendige
                endringer og sende inn nytt korrekt underlag. Etterspørselen kommer til kunden i form av epost.
              </Text>
            </>
          }
          buttonText="Be om korrigert underlag"
          link="amendments"
        />
        <Divider />
        <RequestType
          title="Opprett tilleggsspørsmål"
          description={
            <>
              <Text>
                Her kan man etterspørre f.eks. tilleggsinformasjon, utdypning av svar eller bedre dokumentasjon. Ved
                behov for endring av svar eller underlag på eksisterende spørsmål kan man be om korrigert underlag.
              </Text>
              <Text>
                Ved opprettelse av tilleggspørsmål kan man sette opp spørsmålet ved å kreve tekstsvar og/eller
                filopplastning. Ved etablering av tilleggsspørsmål åpnes spørsmålsseksjonen opp for brukeren igjen, ved
                at de kan svare ut tilleggspørsmålet. Øvrige spørsmål vil forbli låst, og ikke mulig å endre på.
              </Text>
            </>
          }
          buttonText="Opprett tilleggsspørsmål"
          link="questions"
        />
      </ModalBody>
    </ModalContent>
  )
}
