import { Box } from "@chakra-ui/react"
import { Feedback } from "components/Feedback"

export const ErrorBoundary: React.FC = () => {
  return (
    <Box height="100vh">
      <Feedback type="genericError" heading="Oops, noe gikk galt!" linkBack reload />
    </Box>
  )
}
