import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react"
import { useRemoveAdditionalQuestionMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { useRef } from "react"

interface DeleteQuestionModalProps {
  isOpen: boolean
  onClose: () => void
  questionId: string
}

export const DeleteQuestionModal: React.FC<DeleteQuestionModalProps> = ({ isOpen, questionId, onClose }) => {
  const cancelRef = useRef(null)
  const { ratingId } = useRating()
  const [{ fetching: isRemoving }, remove] = useRemoveAdditionalQuestionMutation()
  const toast = useToast()

  if (!questionId && isOpen) {
    onClose()
    return null
  }

  const onRemove = async () => {
    const res = await remove({ ratingId, id: questionId })
    if (res.error) {
      toast({
        status: "error",
        title: "Noe gikk galt ved fjerning av tilleggsspørsmål",
        description: res.error.message,
      })
    } else {
      onClose()
    }
  }

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Slett tilleggsspørsmål
          </AlertDialogHeader>

          <AlertDialogBody>Er du sikker på at du vil slette tilleggsspørsmålet?</AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="outline" ref={cancelRef} onClick={onClose}>
              Avbryt
            </Button>
            <Button colorScheme="red" onClick={() => onRemove()} isLoading={isRemoving} ml={3}>
              Slett
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
