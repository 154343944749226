import { useMemo, useRef, useState } from "react"
import { useInterval } from "usehooks-ts"
import { Context } from "./Context"

export interface VersionNotificationProps {
  /** Endpoint to use for fetching version */
  endpoint?: string
  /** How often to check for new version (ms). Defaults to 30sec */
  interval?: number
}

const DEFAULTS = {
  interval: 30000,
  endpoint: "/_version",
} as const

type Child = { children: React.ReactNode }

export const Provider: React.FC<VersionNotificationProps & Child> = ({ children, ...maybeProps }) => {
  const props: VersionNotificationProps = useMemo(
    () => ({
      ...DEFAULTS,
      ...maybeProps,
    }),
    [maybeProps],
  )

  /** Use a ref instead of setState to keep values as setting state makes upstream component to re-render */
  const clientVersion = useRef<null | string>(null)
  const [versionChanged, setVersionChanged] = useState<boolean>(false)
  const [isMaintenance, setIsMaintenance] = useState<boolean>(false)

  const fetchVersion = async () => {
    if (!props) throw new Error("No props given")

    const res = await fetch(props.endpoint)
    const serverVersion = await res.text()
    return serverVersion
  }

  useInterval(async () => {
    if (versionChanged) return

    try {
      const serverVersion = await fetchVersion()

      if (clientVersion.current === null) {
        clientVersion.current = serverVersion
      } else if (clientVersion.current !== serverVersion) {
        if (serverVersion.trim() === "MAINTENANCE") {
          setIsMaintenance(true)
        }
        setVersionChanged(true)
      }
    } catch (error) {
      console.warn("Unable to retrieve version information")
      console.error(error)
    }
  }, props?.interval)

  return (
    <Context.Provider
      value={{
        hasVersionChanged: versionChanged,
        isMaintenance: isMaintenance,
      }}
    >
      {children}
    </Context.Provider>
  )
}
