import { config } from "lib/helpers/config"

interface SendMsgProps {
  token: string
  ratingId: string
  subject: string
  msg: string
}

interface SendMsgResponse {
  emailSent: boolean
}

export const sendMsg = async ({ token, ratingId, subject, msg }: SendMsgProps) => {
  const res = await fetch(`${config.API_URL}/send-msg`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token, ratingId, subject, msg }),
  })

  if (!res.ok) {
    throw new Error(await res.text())
  }

  const response: SendMsgResponse = await res.json()

  return response
}
