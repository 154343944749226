import { Box, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Text } from "@chakra-ui/react"
import { HeadingTooltipIcon } from "./Icons"

interface HeadingTooltipProps {
  helpText: string
}

const HeadingTooltip: React.FC<HeadingTooltipProps> = ({ helpText }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Box cursor="pointer">
          <HeadingTooltipIcon />
        </Box>
      </PopoverTrigger>
      <PopoverContent bg="gray.900" color="white" p="4">
        <PopoverCloseButton />
        <PopoverBody>
          <Text fontSize="md">{helpText}</Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default HeadingTooltip
