export const datapointPrecisions = (datapoint: string) => {
  switch (datapoint) {
    case "co2efaktorEgenProdKwh": {
      return 3
    }
    default: {
      return 2
    }
  }
}
