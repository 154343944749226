import { Link } from "@chakra-ui/react"
import { ContentBox } from "components/ContentBox"
import { MethodI18nFragment } from "components/I18nText"
import React from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { useRevisionEditing } from "../RatingEditingContext"

const Container = styled.div`
	width: 280px;
	overflow: auto;
`

const NavItem = styled(Link).attrs({ as: NavLink })<{ level: number }>`
	padding: 10px 16px 10px;
	display: block;
	border-radius: var(--chakra-radii-base);
	font-size: 16px;
	font-weight: var(--chakra-fontWeights-medium);
	font-family: var(--chakra-fonts-medium);

	&.level-0 {
		font-weight: var(--chakra-fontWeights-heading);
		font-family: var(--chakra-fonts-heading);
		text-transform: uppercase;
		font-size: 14px;
		color: var(--chakra-colors-blackAlpha-500);
		margin-top: 16px;
	}

	&.active {
		background-color: var(--chakra-colors-blackAlpha-600);
		color: white;
	}
`

export const Navigation: React.FC = () => {
  const { ratingProfile, method } = useRevisionEditing()

  if (!ratingProfile || !method) return null

  return (
    <Container>
      <ContentBox p="16px">
        {method.config.kpiDimensions.map((dimension) => (
          <React.Fragment key={dimension.id}>
            <NavItem className="level-0" level={0} to={`dimension/${dimension.slug}`}>
              <MethodI18nFragment text={dimension.name} />
            </NavItem>
            {dimension.categories.map((category) => (
              <NavItem key={category.id} level={1} to={`category/${dimension.slug}/${category.id}`}>
                <MethodI18nFragment text={category.name} />
              </NavItem>
            ))}
          </React.Fragment>
        ))}
      </ContentBox>
    </Container>
  )
}
