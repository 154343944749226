import { Button, Checkbox, Flex, FormControl, FormHelperText, FormLabel, Input } from "@chakra-ui/react"
import { ErrorMessage } from "components/ErrorMessage"
import * as auth from "lib/auth"
import { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"

const Form = styled.form`
	overflow: visible;
	display: flex;
	flex-flow: column nowrap;
	gap: 16px;
`

interface FormValues {
  email: string
  rememberMe?: boolean
}

interface RequestOtpProps {
  nextUrl?: string
  onSubmit: (token: string) => void
}

export const RequestOtp: React.FC<RequestOtpProps> = ({ nextUrl, onSubmit }) => {
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      rememberMe: false,
    },
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const requestOtp = async ({ email, rememberMe }: FormValues) => {
    setLoading(true)

    try {
      const token = await auth.requestOtp({ email: email.toLowerCase().trim(), nextUrl, rememberMe })

      onSubmit(token)
    } catch (e) {
      console.error(e)
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {error && <ErrorMessage error={error} />}
      <Form onSubmit={handleSubmit(requestOtp)}>
        <FormControl>
          <FormLabel>Din e-post adresse</FormLabel>
          <Input placeholder="navn@firma.com" autoFocus type="email" {...register("email", { required: true })} />
          <FormHelperText mt={2} fontSize={14}>
            Du vil så motta en e-post med en engangskode du kan bruke for å logge inn.
          </FormHelperText>
        </FormControl>
        <FormControl as={Flex} gap={2} alignItems="center">
          <Checkbox {...register("rememberMe")}>Husk meg på denne maskinen</Checkbox>
        </FormControl>
        <Button
          type="submit"
          colorScheme="green"
          size="lg"
          color="white"
          variant="solid"
          mb={2}
          mt={4}
          isLoading={loading}
        >
          Send engangskode
        </Button>
      </Form>
    </>
  )
}
